import React from 'react';
import {HeaderPage} from '../HeaderPage';
import {PageWrapper} from './styles';

export const Configrations: React.FC = ({children}) => {
  return (
    <PageWrapper>
      <HeaderPage title={'Configurations'} />
      {children}
    </PageWrapper>
  );
};
