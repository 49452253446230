import {gql} from '@apollo/client';
import {TaskFragment} from './fragments/task';

export const CreateTaskQuery = gql`
  mutation createTask($fields: CreateTaskFieldsInput) {
    createTask(input: {fields: $fields}) {
      task {
        ...TaskFragment
      }
    }
  }
  ${TaskFragment}
`;

export const getTaskQuery = gql`
  query getTask($id: ID!) {
    task(id: $id) {
      ...TaskFragment
    }
  }
  ${TaskFragment}
`;

export interface CreateTaskResponseType {
  createTask: {
    task: any;
  };
}
