import React from 'react';
import {ButtonClose, ModalOverlay, ModalWrapper} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

type PropsT = {
  dark?: boolean;
  active: boolean;
  setActive: (v: boolean) => void;
  isForEmoji?: boolean;
};

export const Modal: React.FC<PropsT> = ({children, setActive, active}) => {
  const handleClose = () => setActive(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();

  if (!active) return <></>;
  return (
    <>
      <ModalOverlay onClick={handleClose}>
        <ModalWrapper onClick={stop}>
          <ButtonClose onClick={handleClose}>
            <IconSvg type="close" width="28" height="28" />
          </ButtonClose>
          {children}
        </ModalWrapper>
      </ModalOverlay>
    </>
  );
};
