import React from 'react';
import {HeaderPage} from '../HeaderPage';
import {PageWrapper} from './styles';

export const Listings: React.FC = ({children}) => {
  return (
    <PageWrapper>
      <HeaderPage title="Listings" />
      {children}
    </PageWrapper>
  );
};
