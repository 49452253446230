import React from 'react';
import {BackButton, HeaderWrapper, PageWrapper, TextContainer} from './styles';
import {Text} from '../../../ui-kit/Typography/Text';
import {useHistory, useParams} from 'react-router-dom';
import {ViewCommunityType} from '../../../types/communities';

interface CustomPlanHeaderProps {
  community?: ViewCommunityType;
}

export const CustomPlanHeader: React.FC<CustomPlanHeaderProps> = ({community, children}) => {
  const {id} = useParams<{id?: string}>();
  const {push} = useHistory();

  const handleBack = () => {
    push(`/community/${id}`);
  };

  return (
    <PageWrapper>
      <HeaderWrapper>
        <BackButton onClick={handleBack}>Back</BackButton>
        <TextContainer>
          <Text variant={'primary'} weight={'500'} color={'quaternary'} size={26} as={'h2'}>
            {community?.name}
          </Text>
          <Text variant={'primary'} color={'octonary'} size={18} as={'h2'}>
            {community?.Location?.name}, {community?.Location?.Country?.name}
          </Text>
        </TextContainer>
      </HeaderWrapper>
      {children}
    </PageWrapper>
  );
};
