import React from 'react';
import {UpdatedFields} from '../hooks';
import {Fees} from '../../../../types/payments';
import {ContentColumn, GroupeColumns, StyledText} from '../styles';
import {breakdown, FeeRow} from '.';
import {Input} from '../Input';

const breakdownName: Record<breakdown, string> = {
  hFee: 'Hangeh Fee',
  cFee: 'Community Fee',
  Pp: 'Payment Processing',
};

const ListerPrc: Record<breakdown, string> = {
  hFee: 'hfListerPrc',
  cFee: 'cfListerPrc',
  Pp: 'ppListerPrc',
};
const ListerAbs: Record<breakdown, string> = {
  hFee: 'hfListerAbs',
  cFee: 'cfListerAbs',
  Pp: 'ppListerAbs',
};

const RequesterPrc: Record<breakdown, string> = {
  hFee: 'hfRequesterPrc',
  cFee: 'cfRequesterPrc',
  Pp: 'ppRequesterPrc',
};

const RequesterAbs: Record<breakdown, string> = {
  hFee: 'hfRequesterAbs',
  cFee: 'cfRequesterAbs',
  Pp: 'ppRequesterAbs',
};

export function getColumns(
  changeValue: (id: string, value: Fees) => void,
  fields: UpdatedFields,
  isEditMode: boolean,
  defaultValues: Record<string, Fees>,
  currSign: string,
) {
  const getValue = (feeRow: FeeRow, name: string) => {
    const currentUpdateItem = fields[feeRow.objectId];
    const value = (currentUpdateItem?.[feeRow.typeFees]?.[feeRow.breakdown] as any)?.[name] ?? feeRow.item[name];
    return value && !isEditMode ? value.toFixed(2) : value;
  };
  const onChange = (feeRow: FeeRow, name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isEditMode) return;
    changeValue(feeRow.objectId, {
      ...defaultValues[feeRow.objectId],
      ...fields[feeRow.objectId]?.[feeRow.typeFees],
      [feeRow.breakdown]: {
        ...defaultValues[feeRow.objectId]?.[feeRow.breakdown],
        ...fields[feeRow.objectId]?.[feeRow.typeFees]?.[feeRow.breakdown],
        [name]: +value,
      },
    });
  };

  const Breakdown = (feeRow: FeeRow) => {
    return <StyledText paddingLeft={26}>{breakdownName[feeRow.breakdown]}</StyledText>;
  };
  const Lister = (feeRow: FeeRow) => {
    return (
      <GroupeColumns>
        <ContentColumn>
          <Input
            onChange={onChange(feeRow, ListerAbs[feeRow.breakdown])}
            disabled={!isEditMode}
            value={`${getValue(feeRow, ListerAbs[feeRow.breakdown])}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
        <ContentColumn>
          <Input
            onChange={onChange(feeRow, ListerPrc[feeRow.breakdown])}
            disabled={!isEditMode}
            value={`${getValue(feeRow, ListerPrc[feeRow.breakdown])}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
      </GroupeColumns>
    );
  };
  const Requester = (feeRow: FeeRow) => {
    return (
      <GroupeColumns>
        <ContentColumn>
          <Input
            onChange={onChange(feeRow, RequesterAbs[feeRow.breakdown])}
            disabled={!isEditMode}
            value={`${getValue(feeRow, RequesterAbs[feeRow.breakdown])}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
        <ContentColumn>
          <Input
            onChange={onChange(feeRow, RequesterPrc[feeRow.breakdown])}
            disabled={!isEditMode}
            value={`${getValue(feeRow, RequesterPrc[feeRow.breakdown])}`}
            isEditMode={isEditMode}
            currency={'prc'}
          />
        </ContentColumn>
      </GroupeColumns>
    );
  };
  const Net = (feeRow: FeeRow) => {
    return (
      <GroupeColumns>
        <ContentColumn>
          <StyledText>
            {currSign}
            {(
              +getValue(feeRow, ListerAbs[feeRow.breakdown]) + +getValue(feeRow, RequesterAbs[feeRow.breakdown])
            ).toFixed(2)}
          </StyledText>
        </ContentColumn>
        <ContentColumn>
          <StyledText>
            {(
              +getValue(feeRow, ListerPrc[feeRow.breakdown]) + +getValue(feeRow, RequesterPrc[feeRow.breakdown])
            ).toFixed(2)}
            %
          </StyledText>
        </ContentColumn>
      </GroupeColumns>
    );
  };
  return {Lister, Requester, Breakdown, Net};
}
