import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {InnerContent, InnerSection, SectionWrapper} from '../../../ui-kit/Chat/PersonInfo/styles';
import {SkeletonProfileCard} from './Card/ProfileCard';
import {SkeletonInfoCard, SkeletonShortInfoCard} from './Card/InfoCard';

export const PersonInfoLoader = () => {
  return (
    <InnerContent>
      <Section>
        <SkeletonProfileCard />
        <SkeletonInfoCard />
      </Section>

      <Section>
        {new Array(10).fill('').map((_, idx) => (
          <SkeletonShortInfoCard key={idx} />
        ))}
      </Section>

      <Section>
        {new Array(2).fill('').map((_, idx) => (
          <SkeletonProfileCard key={idx} />
        ))}
      </Section>
    </InnerContent>
  );
};

const Section: React.FC = ({children}) => {
  return (
    <SectionWrapper>
      <Skeleton width={'40%'} />
      <InnerSection>{children}</InnerSection>
    </SectionWrapper>
  );
};
