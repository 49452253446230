import React from 'react';
import {MessagesChat} from './ChatArea';
import {ContactsList} from './ContactsList';
import {MessagesWrapper} from './styles';
import {IDataHookMessage, TContact, TMessage} from '../../types/mesages';
import {ContactInfo} from './ContactInfo';
import {TCommunityItem, TInsightItem, TPersonData} from '../../ui-kit/Chat/PersonInfo/types';
import {EFilterOptions} from '../../hooks/messages';

export interface IMessages {
  msgs?: TMessage[] | null;
  contactsList?: TContact[] | null;
  activeContact?: TContact | null;
  setActiveContact: (user: TContact | null) => void;
  dataMessage: IDataHookMessage;
  contactsLoading: boolean;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  countUnReadMsg?: number;
  personInfoData?: {
    insights?: Array<TInsightItem>;
    communities?: Array<TCommunityItem>;
    user?: TPersonData;
  };
  dialogData: {
    isOpenDialog: boolean;
    isArchivedDialog: boolean;
    closeDialog: () => void;
    openDialog: () => void;
    archiveDialog: () => void;
    unarchiveDialog: () => void;
  };
  filterContacts: (type: EFilterOptions) => void;
  currentFilter: EFilterOptions;
  hasMore: boolean;
  fetchMore: (v?: number) => void;
}

export const Messages: React.FC<IMessages> = ({
  contactsList,
  contactsLoading,
  activeContact,
  setActiveContact,
  msgs,
  dataMessage,
  countUnReadMsg,
  viewerId,
  viewerData,
  personInfoData,
  dialogData,
  filterContacts,
  currentFilter,
  hasMore,
  fetchMore,
}) => {
  return (
    <MessagesWrapper>
      <ContactsList
        contactsList={contactsList}
        contactsLoading={contactsLoading}
        setActiveContact={setActiveContact}
        count={countUnReadMsg}
        activeContact={activeContact}
        filterContacts={filterContacts}
        currentFilter={currentFilter}
        hasMore={hasMore}
        fetchMore={fetchMore}
      />
      <MessagesChat
        msgs={msgs}
        dataHookMessage={dataMessage}
        activeContact={activeContact}
        viewerId={viewerId}
        viewerData={viewerData}
        {...dialogData}
      />
      {activeContact && <ContactInfo personInfoData={personInfoData} />}
    </MessagesWrapper>
  );
};
