import React, {ReactNode} from 'react';
import {Label} from '../../ui-kit/Labels';
import {Text} from '../../ui-kit/Typography/Text';
import {Community as CommunityType, SubscrType} from '../../types/communities';

import {BoldText, CommunityLabelSpam, CommunityLabelVerified, WrapperContent} from './styles';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu as MenuTable} from '../../ui-kit/Menu';
import {updateCommunity} from './table';
import {convertCentToDollar} from '../../helpers/calculate';

export const CommunityLabel: React.FC<{isVerified?: boolean}> = ({isVerified}) => {
  return isVerified ? <CommunityLabelVerified /> : <CommunityLabelSpam />;
};

export const Community = (item: CommunityType) => {
  return (
    <WrapperContent>
      <BoldText>
        <CommunityLabel isVerified={item.isVerified} /> {item.name}
      </BoldText>
      {item.alias && (
        <Text variant="quaternary" size={14}>
          @{item.alias}
        </Text>
      )}
    </WrapperContent>
  );
};

const SubscrLabels: Record<SubscrType | string, ReactNode> = {
  Pro: (
    <Label variant="quaternary" width="58px" point>
      Pro
    </Label>
  ),
  Basic: (
    <Label width="58px" point>
      Basic
    </Label>
  ),
  Starter: (
    <Label width="58px" point>
      Starter
    </Label>
  ),
  Free: (
    <Label width="58px" point>
      Free
    </Label>
  ),
  Custom: (
    <Label width="58px" point variant={'tertiary'}>
      Custom
    </Label>
  ),
};
const renderSubscrPrices = (item: CommunityType) => {
  const sign = item.Subscr?.PaymentPlan?.Currency?.sign || '';

  if (item.Subscr.paymentPeriod === 'month')
    return (
      <Text variant="quaternary" weight="medium">
        {sign}
        {item.Subscr.PaymentPlan?.priceMonth || 0} per month
      </Text>
    );
  if (item.Subscr.paymentPeriod === 'year')
    return (
      <Text variant="quaternary" weight="medium">
        {sign}
        {item.Subscr.PaymentPlan?.priceYear || 0} per year
      </Text>
    );
};
export const Subscription = (item: CommunityType) => {
  const PaymentLabel =
    item.Subscr?.PaymentPlan?.name && SubscrLabels[item.Subscr.PaymentPlan.name.split(' ')[0] as SubscrType];
  return (
    <WrapperContent>
      {PaymentLabel ? PaymentLabel : SubscrLabels['Custom']}
      {item?.Subscr?.paymentPeriod && renderSubscrPrices(item)}
    </WrapperContent>
  );
};
export const Payment = (item: CommunityType) => {
  return (
    <WrapperContent>
      {item.Subscr?.expireAt && new Date(item.Subscr.expireAt) > new Date() ? (
        <Label variant="tertiary" point>
          Paid
        </Label>
      ) : (
        <Label variant="primary" point>
          Due
        </Label>
      )}
      <Text variant="quaternary" weight="medium">
        {item.infoCard?.brand} {item.infoCard?.last4}
      </Text>
    </WrapperContent>
  );
};
export const Traction = (item: CommunityType) => {
  return (
    <WrapperContent>
      <Text variant="quaternary">Members: {item.countMembers ?? 0}</Text>
      <Text variant="quaternary">Listings: {item.countItems ?? 0}</Text>
    </WrapperContent>
  );
};
export const NetPaid = (item: CommunityType) => {
  const currency = item.Subscr?.PaymentPlan?.Currency;

  return (
    <WrapperContent alignRight>
      <BoldText>
        {currency?.sign}
        {convertCentToDollar(item.Subscr?.netPaid) ?? 0}
      </BoldText>
      <Text variant="quaternary">{currency?.code}</Text>
    </WrapperContent>
  );
};

export const getMenu = (
  verifyCommunity: updateCommunity,
  disableCommunity: updateCommunity,
  activeCommunity: updateCommunity,
) => {
  const Menu = (item: CommunityType) => {
    const TableOptions: MenuItemType[] = [
      {
        title: 'View',
        to: (id) => `/community/${id}`,
      },
      // {
      //   title: 'Access',
      // },
      {
        title: 'Mark Verified',
        onClick: () => verifyCommunity(item),
        isHidden: item.isVerified,
      },
      {
        render: 'line',
      },
      {
        title: 'Disable',
        render: 'danger',
        onClick: () => disableCommunity(item),
        isHidden: item.status === 'disabled',
      },
      {
        title: 'Active',
        render: 'successful',
        onClick: () => activeCommunity(item),
        isHidden: item.status === 'active',
      },
    ];
    return <MenuButton item={item} menu={MenuTable} options={TableOptions} />;
  };
  return Menu;
};
