import React from 'react';
import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';

interface Align {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
}

export const InnerToolTip = styled.span<Align>`
  ${({top}) => top && `top: ${top};`}
  ${({bottom}) => bottom && `bottom: ${bottom};`}
  ${({right}) => right && `right: ${right};`}
  ${({left}) => left && `left: ${left};`}
  --bg-color: ${({theme}) => theme.palette.background.quaternary};
  position: absolute;
  width: max-content;
  cursor: pointer;
  &:hover {
    & .lock-tool-tip {
      display: inline-block;
      width: 200px;
    }
  }
  ${Media.down.xs} {
    display: none;
  }
`;

export const ToolTipText = styled.span.attrs({className: 'lock-tool-tip'})`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  position: absolute;
  font-size: 12px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.primary};
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  z-index: 1;
  right: calc(100% + 7px);
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  background-color: ${({theme}) => theme.palette.background.quaternary};
  display: none;
  width: 0;
  overflow: hidden;
`;

interface Props {
  text?: string;
}

export const ToolTips: React.FC<Props & Align> = ({text, children, ...align}) => {
  return (
    <InnerToolTip {...align}>
      <ToolTipText>{text}</ToolTipText>
      {children}
    </InnerToolTip>
  );
};
