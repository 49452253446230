import styled, {css} from 'styled-components';
import {Text} from '../../Typography/Text';
import {Link} from 'react-router-dom';

export const InnerContent = styled.aside`
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 12px;
`;

export const InnerSection = styled.div`
  --gap-size: 4px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-size);
`;

export const SectionLabel = styled(Text).attrs({
  color: 'octonary',
  size: 14,
})``;

export const innerCardCss = css`
  box-sizing: border-box;

  border-radius: 12px;
  border-width: 1px;
  border-color: ${({theme}) => theme.palette.text.hexadecimal};
  border-style: solid;

  height: 60px;
`;

export const WrapperProfileCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  padding-left: 10px;

  ${innerCardCss}
`;

export const InnerProfileCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 14px;
  gap: 2px 8px;
  height: 36px;
`;

export const InnerInfoCard = styled.div<{$short?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 2px;

  padding-top: 5px;
  padding-left: 20px;

  width: ${({$short}) => ($short ? 'calc(50% - var(--gap-size) / 2)' : '100%')};

  & > span {
    line-height: 150%;
  }

  ${innerCardCss}
`;

export const CardTitle = styled(Text).attrs({
  color: 'octonary',
  weight: 'medium',
  size: 14,
})`
  width: 100%;
`;

export const InnerLabel = styled.span`
  display: flex;
  gap: 5px;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.septenary};
`;

export const NeutralLink = styled(Link)`
  width: 100%;
  color: initial;
  text-decoration: none;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 12px;
`;
