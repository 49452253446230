import React from 'react';
import {TableItem, TableWrapper, TableHeaderRow, TitleWrapper, Date} from './styles';
import {Columns, Column, ItemType, TableProps, renderFunctionsType} from './types';
import {color as menuColor} from '../Icon/Svg/types';
import {getFormatDate} from '../utils/Date';

const renderFunctions: renderFunctionsType = {
  date: getFormatDate,
};

const getRender = <T,>(item: ItemType<T>, options: Column<ItemType<T>>) => {
  const {render, dataIndex} = options;
  if (!render && dataIndex) return item[dataIndex];
  if (typeof render === 'function') return render(item);
  if (render === 'date' && dataIndex && typeof item[dataIndex] === 'string') {
    return <Date>{renderFunctions[render](item[dataIndex] as unknown as string)}</Date>;
  }
  return;
};

interface ItemProps<T> {
  item: ItemType<T>;
  columns: Columns<ItemType<T>>;
  height?: string;
  colorBtn?: menuColor;
  isInactiveItem?: (item: T) => boolean | undefined;
}

const Item = <T,>({item, height, columns, isInactiveItem}: ItemProps<T>) => {
  return (
    <TableItem height={height} $isInactiveItem={isInactiveItem && isInactiveItem(item)}>
      {columns.map((el, i) => (
        <td key={item.objectId + i}>{getRender<T>(item, el)}</td>
      ))}
    </TableItem>
  );
};

export const PrimaryTable = <T,>({items, columns, height, headerColor, ...props}: TableProps<ItemType<T>>) => {
  return (
    <TableWrapper>
      <TableHeaderRow color={headerColor}>
        {columns.map((el) => (
          <TitleWrapper {...el} key={el.title}>
            {el.title}
          </TitleWrapper>
        ))}
      </TableHeaderRow>
      {items?.map((i) => (
        <Item item={i} key={i.objectId} height={height} columns={columns} {...props} />
      ))}
    </TableWrapper>
  );
};
