import React from 'react';
import {CardWrapper, Title, ContentWrapper, LinkWithArrow, Notification, Value} from './styles';

export interface CardProps {
  title: string;
  value?: string | number;
  isCustom?: boolean;
  link?: {
    url: string;
    title: string;
    count?: number;
  };
}

export const Card: React.FC<CardProps & {marginBottom?: number}> = ({
  title,
  value = '-',
  link,
  marginBottom,
  isCustom,
}) => {
  return (
    <CardWrapper marginBottom={marginBottom}>
      <ContentWrapper $haveLink={link && !isCustom}>
        <div>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </div>
      </ContentWrapper>
      {link && (
        <LinkWithArrow color="black" width="126px" size="sm" to={link.url}>
          {link.title}
          <Notification value={link.count} />
        </LinkWithArrow>
      )}
    </CardWrapper>
  );
};
