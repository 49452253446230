import React from 'react';
import {IconSvg} from '../../Icon/Svg';
import {TransparentButton} from '../styles';
import {mode, variant} from '../types';
import {color} from '../../Icon/Svg/types';

const strokeColorLight: Record<variant, color> = {
  admin: 'lightGray',
  web: 'gray',
};

const strokeColorDark: Partial<Record<variant, color>> = {
  web: 'lightGray',
};

const strokeColor = (variant: variant, mode?: mode) => {
  switch (mode) {
    case 'light':
      return strokeColorLight[variant];
    case 'dark':
      return strokeColorDark[variant];
    default:
      return strokeColorLight[variant];
  }
};

export const ArrowButton: React.FC<{onClick: () => void; $isOpenNav?: boolean; variant: variant; mode?: mode}> = ({
  onClick,
  $isOpenNav,
  variant,
  mode,
}) => {
  return (
    <TransparentButton onClick={onClick}>
      <IconSvg
        type={$isOpenNav ? 'chevrone-left' : 'chevrone-right'}
        width="20px"
        height="20px"
        stroke={strokeColor(variant, mode)}
      />
    </TransparentButton>
  );
};
