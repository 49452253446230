import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import Parse from 'parse';
import App from './App';
import ApolloProvider from './ApolloProvider';
import {API_KEY, APPLICATION_ID, SERVER_URI, LQ_SERVER_URL} from './constants/env';
import 'react-loading-skeleton/dist/skeleton.css';

Parse.initialize(APPLICATION_ID || 'hangeh', API_KEY);
Parse.serverURL = SERVER_URI || 'https://server.hangeh.dev';
Parse.liveQueryServerURL = LQ_SERVER_URL || 'wss://server.hangeh.dev/ws/';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
