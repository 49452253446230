import React, {useState} from 'react';
import {MouseEventHandler, useRef} from 'react';
import {IconSvg} from '../Icon/Svg';
import {TransparentButton} from './styles';
import {MenuButtonType} from './types';

export const MenuButton: React.FC<MenuButtonType<any>> = ({
  item,
  menu: Menu,
  options,
  prevent,
  right = true,
  toggleCallback,
  iconData = {},
  customIcon,
  alwaysShowBtn = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const openMore: MouseEventHandler = (event) => {
    prevent && event.preventDefault();
    const closeMore = (e: MouseEvent) => {
      prevent && e.preventDefault();
      if (menuRef.current !== e.target && event.target !== e.target) {
        setShowMenu(false);
        toggleCallback?.(false);
        document.body.removeEventListener('click', closeMore);
      }
    };
    toggleCallback?.(true);
    setShowMenu(true);
    document.body.addEventListener('click', closeMore);
  };
  return (
    <>
      {!alwaysShowBtn ? (
        <>
          {showMenu ? (
            <Menu id={item.objectId} menuRef={menuRef} options={options} prevent={prevent} right={right} />
          ) : (
            <TransparentButton onClick={openMore}>
              {customIcon ? (
                customIcon
              ) : (
                <IconSvg
                  type="more"
                  width="6px"
                  height="21px"
                  viewBox="0 0 6 21"
                  stroke="none"
                  fill={'grayLight'}
                  {...iconData}
                />
              )}
            </TransparentButton>
          )}
        </>
      ) : (
        <>
          {showMenu && <Menu id={item.objectId} menuRef={menuRef} options={options} prevent={prevent} right={right} />}
          <TransparentButton onClick={openMore}>
            {customIcon ? (
              customIcon
            ) : (
              <IconSvg
                type="more"
                width="6px"
                height="21px"
                viewBox="0 0 6 21"
                stroke="none"
                fill={'grayLight'}
                {...iconData}
              />
            )}
          </TransparentButton>
        </>
      )}
    </>
  );
};
