import styled, {css, StyledProps} from 'styled-components';
import Cleave from 'cleave.js/react';
import {StyledInputProps, variantDropdown} from './types';
import ReactDatePicker from 'react-datepicker';
import {Media, theme} from '../../theme';
import NumberFormat from 'react-number-format';
import {Text} from '../../Typography/Text';

const colorError = ({$isError, theme}: StyledProps<StyledInputProps>) => {
  if ($isError) return theme.palette.common.red;
};

const colorLabel = ({$isError, theme, disabled}: StyledProps<StyledInputProps>) => {
  if ($isError) return theme.palette.common.red;
  if (disabled) return theme.palette.text.hexadecimal;
  return theme.palette.text.heptadecimal;
};

const styles = css`
  ${theme.font.tertiary.regular};
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.text.quinary};
  outline: none;
  background-color: transparent;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  flex-grow: 1;
  padding: 0;
  ${Media.down.s} {
    font-size: 16px;
  }
`;

export const CleaveInput = styled(Cleave)<StyledInputProps>`
  ${styles};
`;

export const NumberFormatInput = styled(NumberFormat)<StyledInputProps>`
  ${styles};
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${styles};
`;

export const DatePicker = styled(ReactDatePicker)<StyledInputProps>`
  ${styles};
`;

export const Fieldset = styled.div<{width?: string; height?: string; $isError?: boolean; $isActive?: boolean}>`
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  background: ${theme.palette.grayscale.white};
  border: ${({$isError, $isActive, theme}) =>
    $isError
      ? `2px solid ${theme.palette.common.red}`
      : $isActive
      ? `1px solid ${theme.palette.grayscale._13}`
      : `1px solid ${theme.palette.common.gray}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || 'auto'};
  & svg > * {
    stroke: ${colorError};
  }
  transition: border-bottom-color, border-left-color, border-right-color, border-top-color 0.2s;
`;

export const Label = styled.label.attrs({className: 'fieldset-box__label'})<{
  $isActive?: boolean;
  $isError?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  background-color: ${theme.palette.grayscale.white};
  ${theme.font.tertiary.regular};
  color: ${colorLabel};
  font-size: ${({$isActive}) => ($isActive ? '12px' : '16px')};
  line-height: 16px;
  white-space: nowrap;
  padding: 3px 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: max-content;
  pointer-events: none;
  left: ${({$isActive}) => ($isActive ? '7px' : '10px')};
  top: ${({$isActive}) => ($isActive ? '-12px' : '50%')};
  width: ${({$isActive}) => ($isActive ? 'auto' : 'calc(100% - 10px)')};
  transform: ${({$isActive}) => ($isActive ? 'none' : 'translateY(-50%)')};
  transition: 0.2s ease all;
  ${Media.down.s} {
    font-size: ${({$isActive}) => ($isActive ? '14px' : '16px')};
  }
`;

export const InnerInput = styled.div.attrs({className: 'fieldset-box__inner-input'})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PrefixInput = styled.span`
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.undecimal};
  ${Media.down.s} {
    font-size: 16px;
  }
`;

export const InnerSubElem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  & > *:last-child {
    margin-left: 7px;
  }
`;

export const InnerDataPiker = styled.div`
  & .react-datepicker {
    ${({theme}) => theme.mixins.font.secondary.semiBold};
    border-radius: 10px;
    box-shadow: ${({theme}) => theme.shadow.popUp};
    box-sizing: border-box;
    border: none;
    background-color: ${({theme}) => theme.palette.background.primary};
    font-size: 0.9rem;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
  & .react-datepicker__navigation {
    outline: none;
    box-sizing: border-box;

    & .react-datepicker__navigation-icon {
      font-size: 15px;
    }

    & .react-datepicker__navigation-icon::before {
      border-color: ${({theme}) => theme.palette.background.primary};
    }
  }

  & .react-datepicker__header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    background: ${({theme}) => theme.palette.background.quaternary};
    border: none;
    padding: 15px 10px 8px 10px;

    & .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
      margin-top: 10px;
      margin-bottom: 12px;

      & .react-datepicker__month-select,
      .react-datepicker__year-select {
        box-sizing: border-box;
        background: ${({theme}) => theme.palette.background.quaternary};
        box-shadow: 0 0 0 1px ${({theme}) => theme.palette.background.primary};
        border-radius: 5px;
        border: none;
        padding: 5px;
        color: ${({theme}) => theme.palette.text.primary};
        ${({theme}) => theme.mixins.font.primary.semiBold};
        font-size: 12px;

        & > option {
          color: ${({theme}) => theme.palette.text.octonary};
          background-color: ${({theme}) => theme.palette.background.primary};
        }
      }

      & .react-datepicker__year-select {
        box-sizing: border-box;
        margin-left: 10px;
        padding: 5px 15px 5px 10px;
      }
    }
  }

  & .react-datepicker__day-names {
    & .react-datepicker__day-name {
      box-sizing: border-box;
      color: ${({theme}) => theme.palette.text.primary};
      cursor: default;
    }
  }

  & .react-datepicker__month {
    padding: 3px 2px 2px;
  }

  & .react-datepicker__current-month {
    ${({theme}) => theme.mixins.font.primary.semiBold};
    color: ${({theme}) => theme.palette.text.primary};
    cursor: default;
    margin-bottom: 18px;
  }

  & .react-datepicker__month {
    margin: 0;
    box-sizing: border-box;
    border: none;
    background-color: ${({theme}) => theme.palette.background.primary};
    border-radius: 5px;
    padding: 10px 7px;
  }

  & .react-datepicker__day {
    ${({theme}) => theme.mixins.font.primary.semiBold};
    box-sizing: border-box;
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none;

    &:hover {
      color: ${({theme}) => theme.palette.text.octonary};
      background-color: ${({theme}) => theme.helpers.hexToRGB(theme.palette.grayscale._10, 0.1)};
    }

    &:focus {
      box-shadow: 0 0 0 0.5px ${({theme}) => theme.palette.background.tertiary};
    }

    &--keyboard-selected,
    &--selected {
      color: ${({theme}) => theme.palette.text.primary};
      background-color: ${({theme}) => theme.palette.background.quaternary};

      &:hover {
        background-color: ${({theme}) => theme.palette.background.tertiary};
      }
    }

    &--outside-month,
    &--disabled {
      background-color: ${({theme}) => theme.palette.text.primary};
      color: ${({theme}) => theme.palette.text.undecimal};

      &:hover {
        color: ${({theme}) => theme.palette.text.primary};
      }
    }
  }

  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    margin: 1px;
    width: 2.2rem;
    padding: 5px;
    box-sizing: border-box;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 10px 14px;
  background-color: ${theme.palette.grayscale._3};
  & svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  & input {
    font-size: 16px;
  }
`;

export const PlaceHolder = styled(Text).attrs({
  color: 'duodecimal',
  size: 16,
})`
  margin-right: 5px;
`;

export const ClearWrapper = styled.div`
  cursor: pointer;
  height: 18px;
`;

export const InputWithDropdownWrapper = styled.div<{variant: variantDropdown}>`
  width: auto;
  min-width: ${({variant}) => (variant === 'primary' ? '94px' : '150px')};
  max-width: 300px;
  height: ${({variant}) => (variant === 'primary' ? '24px' : '36px')};
  padding: ${({variant}) => (variant === 'primary' ? '3px 12.5px' : '6px 14px')};
  border-radius: 24px;
  border: ${({variant}) => (variant === 'primary' ? '1px' : '1.5px')} solid #4f4f4f;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  & svg {
    width: auto;
    height: auto;
    max-width: ${({variant}) => (variant === 'primary' ? '16px' : '24px')};
    max-height: ${({variant}) => (variant === 'primary' ? '16px' : '24px')};
  }
  &:focus {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const InputWithDropdownChosen = styled.div<{variant: variantDropdown}>`
  flex-grow: 1;
  text-align: center;
  font-size: ${({variant}) => (variant === 'primary' ? '12px' : '14px')};
  color: ${({theme}) => theme.palette.text.octonary};
  ${({theme, variant}) => (variant === 'primary' ? theme.font.primary.regular : theme.font.primary.semiBold)}
`;

export const InputStyledDropdownWrapper = styled.div`
  padding: 20px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 350px;
  box-sizing: border-box;
  ${Media.down.s} {
    min-width: 200px;
    max-width: 230px;
  }
`;

export const DropdownStyledOption = styled.div`
  padding: 8px 0;
  text-align: center;
  width: 330px;
  ${theme.font.primary.semiBold};
  color: #828282;
  font-size: 14px;
  ${Media.down.s} {
    width: 180px;
    font-size: 13px;
    height: 300px;
  }
  border-bottom: 1px solid #f2f2f2;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    color: #333;
  }
`;

export const MinimalInputWrapper = styled.div`
  position: relative;
  width: 100%;

  & > div {
    border-radius: 50px;
  }

  & > div > label {
    left: 15px;
  }

  & > div > label {
    display: none;
  }
`;

export const SearchButtonWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  aspect-ratio: 1 / 1;
  background: ${({theme}) => theme.palette.background.octonary};
  transition: background 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.palette.background.quinary};
  }

  & > svg {
    margin-bottom: -2px;
    margin-right: -1px;
  }
`;
