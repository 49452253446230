import React from 'react';
import {Tab} from '../../ui-kit/Tabs/Tab';
import {Tabs} from '../../ui-kit/Tabs/Tabs';
import {TabAll} from './Tabs/TabAll';
import {Listings} from '../../components/Listings';
import {TabRent} from './Tabs/TabRent';
import {TabSell} from './Tabs/TabSell';
import {TabFree} from './Tabs/TabFree';
import {TabCoffee} from './Tabs/TabCoffee';
import {TabService} from './Tabs/TabService';

export const ListingsContainer = () => {
  return (
    <Listings>
      <Tabs variant={'primary'}>
        <Tab label={'All'} tabPaddingX={'10px'}>
          <TabAll />
        </Tab>
        <Tab label={'Rent'} tabPaddingX={'10px'} leftIndent={'32px'}>
          <TabRent />
        </Tab>
        <Tab label={'Sell'} tabPaddingX={'10px'} leftIndent={'22px'}>
          <TabSell />
        </Tab>
        <Tab label={'Free'} tabPaddingX={'10px'} leftIndent={'22px'}>
          <TabFree />
        </Tab>
        <Tab label={'Coffee'} tabPaddingX={'10px'} leftIndent={'22px'}>
          <TabCoffee />
        </Tab>
        <Tab label={'Service'} tabPaddingX={'10px'} leftIndent={'22px'}>
          <TabService />
        </Tab>
        {/*<Tab label={'Blocked'} tabPaddingX={'10px'} leftIndent={'22px'}></Tab>*/}
        {/*<Tab label={'Paid'} tabPaddingX={'10px'} leftIndent={'8px'}></Tab>*/}
      </Tabs>
    </Listings>
  );
};
