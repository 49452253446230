import styled, {css, StyledProps} from 'styled-components';
import {Avatar} from '../../Avatar/Avatar';
import {Media, theme} from '../../theme';

export const MessagePreviewCardWrapper = styled.div<{
  $isHighlighted?: boolean;
  $isNewRequest?: boolean;
  bordered?: boolean;
  fullWidth?: boolean;
  $isInternal?: boolean;
  $unSeenedMsg?: boolean;
  $isActive?: boolean;
}>`
  display: inline-flex;
  width: ${(props) => (props.fullWidth ? `100%` : `auto`)};
  padding: 13px 24px 17px 24px;

  background-color: ${({theme, $unSeenedMsg, $isActive, $isInternal}) => {
    if ($isActive) return theme.palette.background.nonary;
    if ($isInternal) return theme.palette.common.yellowLight;
    if ($unSeenedMsg) return theme.palette.grayscale._20;
    return theme.palette.background.primary;
  }};

  box-sizing: border-box;

  border-bottom: ${(props) => (props.bordered ? `1px solid` : `none`)};
  border-bottom-color: ${({theme}) => theme.palette.grayscale._11};
  &:hover {
    background: ${({theme}) => theme.palette.background.duodecimal};
  }
  ${Media.down.l} {
    padding: 7px 8px 8px 9px;
  }
  ${Media.down.m} {
    align-items: flex-start;
    width: calc(100% - 24px);
    margin: 4px 12px;
    padding: 12px 12px;
    border: none !important;
    border-radius: 12px;
  }

  & > .preview-message_avatar {
    // ${Media.down.l} {
    //   width: 40px;
    //   height: 40px;
    // }
    // ${Media.down.s} {
    //   width: 37px;
    //   height: 37px;
    // }
  }
`;

const hiddenElipsisStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PreviewMessageCardBody = styled.div<{fullWidth?: boolean}>`
  margin-left: 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  ${(props) => props.fullWidth && `flex-grow:1`};
`;

export const PreviewRequestCardBody = styled.div<{fullWidth?: boolean}>`
  margin-left: 12px;
  width: auto;
  display: flex;
  ${(props) => props.fullWidth && `flex-grow:1`};
`;

export const PreviewRequestWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PreviewRequestAvatar = styled(Avatar)`
  display: none;
  ${Media.down.m} {
    display: block;
    margin-right: 10px;
  }
`;

export const PreviewMessageStatusLine = styled.div`
  display: inline;
  justify-content: space-between;
`;

const nameStyles = css`
  ${theme.font.primary.regular};
  font-size: 18px;
  line-height: 27px;
  color: ${({theme}) => theme.palette.grayscale._6};
  max-width: 193px;
  ${Media.down.l} {
    font-size: 16px;
    max-width: 98%;
  }
  ${hiddenElipsisStyles};
`;

const messageTextStyles = css`
  ${theme.font.primary.regular};
  font-size: 12px;
  line-height: 21px;
  color: ${({theme}) => theme.palette.grayscale._5};
  max-width: 200px;
  ${Media.down.l} {
    max-width: 250px;
    font-size: 14px;
  }
  ${hiddenElipsisStyles};
`;

export const MessageUserName = styled.span`
  ${nameStyles};
  display: flex;
  flex-direction: column;

  ${Media.down.l} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const MessageRequestItemName = styled.span`
  ${nameStyles};
  ${theme.font.primary.medium};
  max-width: 300px;
  ${Media.down.s} {
    font-size: 14px;
    line-height: 21px;
    max-width: 200px;
  }
  display: block;
`;

export const PreviewMessageText = styled.span`
  ${messageTextStyles};

  a {
    color: #0097b8;
  }
  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const RequestOptionsText = styled.span`
  ${messageTextStyles};
  display: block;
  line-height: 24px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreviewMessageDate = styled.div`
  ${theme.font.primary.regular};
  font-size: 12px;
  line-height: 18px;
  color: ${({theme}) => theme.palette.grayscale._14};
`;

export const StatusText = styled.span`
  ${theme.font.primary.regular};
  font-size: 12px;
  line-height: 18px;
  color: ${({theme}) => theme.palette.grayscale._5};
  display: block;
`;

export const MessageBoxWrapper = styled.div<{$isOwner?: boolean}>`
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({$isOwner}) => $isOwner && ` align-items: flex-end;`}
`;
export const MessageItemWrapper = styled.div<{$isOwner?: boolean}>`
  display: flex;
  ${({$isOwner}) => $isOwner && ` justify-content: flex-end;`}
`;

export const MessageInfoContainer = styled.div<{$isOwner?: boolean; $isEnd?: boolean; $isInternal?: boolean}>`
  padding-left: 7px;
  width: auto;
  max-width: 450px;
  box-sizing: border-box;
  overflow: hidden;
  ${Media.down.xxl} {
    max-width: 320px;
  }
  ${Media.down.l} {
    max-width: 250px;
  }
  border-radius: 20px;
  padding: 11px 14px;
  & > img {
    border-radius: 4px;
    width: -webkit-fill-available;
  }
  .pswp__img {
    width: auto;
    height: auto;
    object-fit: contain; !important;
    background-color: transparent !important;
  }
  ${({$isOwner, $isEnd}) =>
    $isEnd && `${$isOwner ? 'border-bottom-right-radius: 2px;' : 'border-bottom-left-radius: 2px;'}`};
  background-color: ${({$isOwner, $isInternal, theme}) =>
    $isOwner && $isInternal
      ? theme.palette.common.yellowLight
      : $isOwner
      ? theme.palette.common.aqua
      : theme.palette.grayscale._3};
`;

export const MessageTime = styled.div`
  ${theme.font.primary.regular};
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 4px;
  color: ${({theme}) => theme.palette.grayscale._5};
`;

export const MessageItemText = styled.div<{$isOwner?: boolean; $isInternal?: boolean}>`
  ${theme.font.primary.regular};
  color: ${({theme, $isOwner, $isInternal}) =>
    $isOwner && $isInternal
      ? theme.palette.grayscale._10
      : $isOwner
      ? theme.palette.grayscale._0
      : theme.palette.grayscale._10};
  // white-space: pre-line;
  ${Media.down.l} {
    font-size: 15px;
  }
  p {
    margin: 0;
  }
  a {
    color: ${({theme, $isOwner}) => ($isOwner ? theme.palette.grayscale._0 : '#0097b8')};
  }
`;

export const MessageItemStatus = styled.div`
  ${theme.font.primary.regular};
  font-style: italic;
  font-size: 10px;
  color: ${({theme}) => theme.palette.grayscale._14};
  line-height: 21px;

  ${Media.down.l} {
    font-size: 13px;
  }
`;
export const MessageRequestContainer = styled.div<{$isOwner?: boolean}>`
  display: flex;
  flex-direction: column;
  ${({$isOwner}) => $isOwner && ` align-items: flex-end;`}
`;

export const MessageRequestButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
  margin: 14px 0 2px 0;
  align-items: center;
  box-sizing: border-box;
  border: none;
  background-color: ${({theme}) => theme.palette.common.yellowLight3};
  cursor: pointer;
  transition: 0.2s all;
  border-radius: 12px;
  text-align: left;
  &:hover {
    filter: brightness(96%);
  }
  & svg {
    transform: rotate(180deg);
  }
  & span {
    font-size: 13px;
    color: ${({theme}) => theme.palette.grayscale._6};
    font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    padding-right: 20px;
    box-sizing: border-box;
  }
`;

export type GridProps = {
  count: number;
  maxWidth?: number;
  minWidth?: number;
  columnGap?: number;
  rowGap?: number;
  isDragging?: boolean;
};

const columnGap = ({columnGap}: GridProps) => (columnGap ? columnGap : 5) + 'px';
const rowGap = ({rowGap}: GridProps) => (rowGap ? rowGap : 5) + 'px';

const getColums = ({count}: StyledProps<GridProps>) => {
  const repetCount = count > 2 ? 3 : count;
  return `repeat(${repetCount}, 1fr)`;
};

export const InnerImgsMessage = styled.div<GridProps>`
  margin-top: 5px;
  max-width: 300px;
  min-width: 140px;
  width: 100%;
  display: grid;
  grid-template-columns: ${getColums};
  grid-row-gap: ${rowGap};
  grid-column-gap: ${columnGap};
  cursor: pointer;
`;

export const ImgMsg = styled.img`
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 300px;
  cursor: pointer;
`;

export const ContainerPreviewImg = styled.div<{$isVisible?: boolean}>`
  opacity: 1;
  visibility: visible;
  transition: 0.5s all;
  ${(props) => !props.$isVisible && ` opacity:0; visibility:hidden;`}
`;

export const WrapperPreviewImg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PreviewImg = styled.img`
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 12px;
  max-height: calc(100vh - 10%);
  max-width: calc(100vw - 10%);
  ${Media.down.s} {
    max-height: calc(100vh - 35%);
    max-width: calc(100vw - 14%);
  }
`;

export const BtnCloseImg = styled.button<{$top?: boolean}>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 501;
  background: transparent;
  ${Media.down.m} {
    top: ${({$top}) => ($top ? `13vh` : `29%`)};
    right: 20px;
    width: 30px;
    height: 30px;
    display: block;
    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export const SwitchImageBtn = styled(BtnCloseImg)<{next: boolean}>`
  ${({next}) => (next ? 'right: 20px' : 'left: 20px')};
  top: 50%;
  transform: translateY(-50%) ${({next}) => (next ? 'rotate(180deg)' : '')};
`;

export const ImgElement = styled.span`
  display: flex;
  align-items: center;
  justify-conent: center;
  // background-color: rgba(0, 0, 0, 0.1);
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    max-width: 144px;
    max-height: 144px;
  }
`;

export const SoloImg = styled.img`
  widht: auto;
  max-height: 200px;
  max-width: 220px;
  display: block;
`;
