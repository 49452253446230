import {Route} from '../entities/Route';

export const route = {
  main: Route.of('/'),
  dashboard: Route.of('/dashboard'),
  communities: Route.of('/communities'),
  community: Route.of<{id: string}>('/community/:id'),
  plan: Route.of<{id: string}>('/plan/:id'),
  members: Route.of('/members'),
  member: Route.of<{id: string}>('/member/:id'),
  listings: Route.of('/listings'),
  configurations: Route.of('/configurations'),
  messages: Route.of<{contactId?: string}>('/messages/:contactId?'),
};
