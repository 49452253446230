import {gql} from '@apollo/react-hooks';
import {ItemFragment} from './fragments/item';
import {Item} from '../types/item';
import {GQLItemWhereInput} from '../graphql.schema';

export const getListings = gql`
  query getItems($first: Int, $skip: Int, $where: ItemWhereInput) {
    items(first: $first, skip: $skip, where: $where) {
      count
      edges {
        node {
          ...ItemFragment
        }
      }
    }
  }
  ${ItemFragment}
`;

export const SearchListings = gql`
  query itemsSearch($where: ItemWhereInput) {
    items(where: $where) {
      edges {
        node {
          ...ItemFragment
        }
      }
    }
  }

  ${ItemFragment}
`;

export interface DeleteItemResponseType {
  deleteItem: {
    item: Item;
  };
}

export interface DeleteItemRequestType {
  id: string;
}

export const DeleteItemQuery = gql`
  mutation deleteItem($id: ID!) {
    deleteItem(input: {id: $id}) {
      item {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;

export interface TGetCountListingResponse {
  items: {
    count: number;
  };
}

export interface TGetCountListingRequest {
  where: GQLItemWhereInput;
}

export const queryGetCountListing = gql`
  query items($where: ItemWhereInput) {
    items(where: $where) {
      count
    }
  }
`;
