import {css} from 'styled-components';

//400
export const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

//500
export const fontPrimaryMedium = css`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
`;

//600
export const fontPrimarySemiBold = css`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
`;

//400
export const fontTertiaryReg = css`
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
`;
