import gql from 'graphql-tag';

export const CurrencyFragment = gql`
  fragment CurrencyFragment on Currency {
    id
    objectId
    createdAt
    updatedAt
    sign
    code
  }
`;
export const PaymentPlanFragment = gql`
  fragment PaymentPlanFragment on PaymentPlan {
    id
    objectId
    name
    priceYear
    priceMonth
    listingCap
    residentCap
    communityFee
    rentFees
    serviceFees
    salesFees
    isPublic
    extraListing
    extraResident
    descr
    options {
      ... on Element {
        value
      }
    }
    Location {
      Country {
        name
      }
      State {
        name
      }
      City {
        name
      }
      name
      id
    }
    Currency {
      ...CurrencyFragment
    }
    serviceExternal
    rentalExternal
    salesExternal
  }
  ${CurrencyFragment}
`;

export const SubscrFragment = gql`
  fragment SubscrFragment on Subscr {
    id
    objectId
    status
    netPaid
    paymentPeriod
    expireAt
  }
`;
