import React from 'react';
import {BtnGroupe, StyledBtn, TableTitle, TitleWrapper, WrapperTable, TitleLeftWrapper, LabelWrapper} from './styles';
import {Label} from '../../../ui-kit/Labels';

interface Props {
  title?: string;
  onSubmit?: () => void;
  cancel?: () => void;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  label?: {text: string; active: boolean};
  isCustomPlan?: boolean;
  toggle?: (r?: boolean) => void;
}

export const TableWrapper: React.FC<Props> = ({
  title,
  children,
  onSubmit,
  cancel,
  isEdit,
  setIsEdit,
  label,
  toggle,
}) => {
  const EditItem = () => {
    setIsEdit(true);
  };

  return (
    <WrapperTable>
      <TitleWrapper>
        <TitleLeftWrapper>
          <TableTitle>{title}</TableTitle>
          <LabelWrapper>
            {label && (
              <>
                {isEdit ? (
                  <StyledBtn onClick={() => toggle?.(false)}>
                    {label?.active ? 'Deactivate App Payments' : 'Activate App Payments'}
                  </StyledBtn>
                ) : (
                  <Label variant={label?.active ? 'quaternary' : 'quinary'}>{label?.text}</Label>
                )}
              </>
            )}
          </LabelWrapper>
        </TitleLeftWrapper>

        <BtnGroupe>
          {isEdit ? (
            <>
              <StyledBtn onClick={cancel}>Cancel</StyledBtn>
              <StyledBtn dark onClick={onSubmit}>
                Save
              </StyledBtn>
            </>
          ) : (
            <StyledBtn onClick={EditItem}>Edit</StyledBtn>
          )}
        </BtnGroupe>
      </TitleWrapper>
      {children}
    </WrapperTable>
  );
};
