import React from 'react';
import {
  Add,
  AnimalPath,
  ArrowLeft,
  ArtsPath,
  Bell,
  BigCircleOk,
  Bike,
  BizBox,
  Bored,
  Box,
  Bug,
  ButtonHelp,
  Calendar,
  Calendar2,
  Camera,
  CancelRounded,
  Car,
  Chat,
  ChatAlt,
  CheckedMark,
  Checkmark,
  CheckmarkCircle,
  ChevroneDown,
  ChevroneLeft,
  ChevroneRight,
  CircleArrowDown,
  CircleOk,
  Clipboard,
  Close,
  Close2,
  Coffee,
  CoffeeCup,
  ControlsAlt,
  Copy,
  CreditCard,
  CreditCard2,
  DancePath,
  Dollar,
  Download,
  DuotoneCheck,
  Ellypsis,
  EmptyAddress,
  EventsPath,
  Feed,
  File,
  FitnessPath,
  Folder,
  FoodPath,
  GamesPath,
  Grid,
  GroupsPath,
  Hamburger,
  HamburgerLight,
  HangehLogo,
  HangehLogo2,
  HangehLogo3,
  HangehLogoBeta,
  HappyFace,
  Help,
  HelpAlt,
  HomeAlt,
  Horn,
  Image,
  Info,
  Layers,
  Lightbulb,
  Link,
  ListAlt,
  ListView,
  Loading,
  Location,
  LockAlt,
  MessageSend,
  MessageWarning,
  Minus,
  More,
  MoviesPath,
  MsgSentLight,
  MusicPath,
  News,
  News2,
  NoAttendees,
  NoBookings,
  NoMessagesLight,
  NoReguestsLight,
  OthersPath,
  Pencil,
  People,
  People2,
  Person,
  PersonAdd,
  Phone,
  Pin,
  Plane,
  Plus,
  Poll,
  PolygonDown,
  Right,
  Rocket,
  Search,
  Search2,
  SearchFilled,
  Send,
  Setting,
  Settings,
  Shape,
  Share,
  Shield,
  SignOut,
  Sounds,
  Spam,
  Spam2,
  SportsPath,
  Star,
  Sticker,
  SunPath,
  SunPath2,
  Support,
  TagAlt,
  TravelPath,
  Tv,
  Upload,
  User,
  VectorLeft,
  VectorRight,
  Verified,
  Verified2,
  Video,
  Walking,
  Walking2,
  Cart,
  Umbrella,
  Close3,
  Star2,
  Star3,
  Wave,
  Close4,
  NewMessage,
  Comment,
  InfoAlt,
  Speak,
  Previous,
  Entrance,
  Pencil2,
  Like,
  Message,
  List,
  DeleteAlt,
  EyeOpen,
  EyeClosed,
  MarkedCircle,
  EmptyCircle,
  ArrowDown,
  CheckedCircle,
  CheckCircle,
  AddCircle,
  CloseCircle,
  Mail,
  CircledArrow,
  CircledPlus,
  CircleDouble,
  Edit,
  CombinedShape,
  Protection,
  Settings_3,
  Bell_2,
} from './Paths';
import {StyledSvgIconProps} from './types';

export const SvgChildren: React.FC<StyledSvgIconProps> = ({type, fill, fillChildren, stroke}) => {
  if (type === 'sun') {
    return <SunPath />;
  }
  if (type === 'sun-2') {
    return <SunPath2 />;
  }
  if (type === 'wave') {
    return <Wave />;
  }
  if (type === 'credit-card') {
    return <CreditCard />;
  }
  if (type === 'credit-card-2') {
    return <CreditCard2 />;
  }
  if (type === 'Box') {
    return <Box />;
  }
  if (type === 'circle-arrow-down') {
    return <CircleArrowDown />;
  }
  if (type === 'feed') {
    return <Feed />;
  }
  if (type === 'hangeh_logo-aqua') {
    return <HangehLogo />;
  }
  if (type === 'hangeh_logo-aqua-2') {
    return <HangehLogo2 />;
  }
  if (type === 'home-alt') {
    return <HomeAlt />;
  }
  if (type === 'message-warning') {
    return <MessageWarning />;
  }
  if (type === 'person') {
    return <Person />;
  }
  if (type === 'settings') {
    return <Setting />;
  }
  if (type === 'tag-alt') {
    return <TagAlt />;
  }
  if (type === 'chevrone-left') {
    return <ChevroneLeft />;
  }
  if (type === 'chevrone-right') {
    return <ChevroneRight />;
  }
  if (type === 'chevrone-down') {
    return <ChevroneDown />;
  }
  if (type === 'vector-left') {
    return <VectorLeft />;
  }
  if (type === 'vector-right') {
    return <VectorRight />;
  }
  if (type === 'polygon-down') {
    return <PolygonDown />;
  }
  if (type === 'buttonHelp') {
    return <ButtonHelp />;
  }
  if (type === 'calendar') {
    return <Calendar />;
  }
  if (type === 'calendar-2') {
    return <Calendar2 />;
  }
  if (type === 'location') {
    return <Location />;
  }
  if (type === 'more') {
    return <More />;
  }
  if (type === 'close') {
    return <Close />;
  }
  if (type === 'verified') {
    return <Verified />;
  }
  if (type === 'verified-2') {
    return <Verified2 />;
  }
  if (type === 'spam') {
    return <Spam />;
  }
  if (type === 'spam-2') {
    return <Spam2 />;
  }
  if (type === 'people') {
    return <People />;
  }
  if (type === 'search') {
    return <Search />;
  }
  if (type === 'search-2') {
    return <Search2 />;
  }
  if (type === 'circle-ok') {
    return <CircleOk />;
  }
  if (type === 'chat-alt') {
    return <ChatAlt />;
  }
  if (type === 'news') {
    return <News />;
  }
  if (type === 'news-2') {
    return <News2 />;
  }
  if (type === 'walking') {
    return <Walking />;
  }
  if (type === 'walking-2') {
    return <Walking2 />;
  }
  if (type === 'close-2') {
    return <Close2 />;
  }
  if (type === 'close-3') {
    return <Close3 />;
  }
  if (type === 'people2') {
    return <People2 />;
  }
  if (type === 'grid') {
    return <Grid />;
  }
  if (type === 'chat') {
    return <Chat />;
  }
  if (type === 'plus') {
    return <Plus />;
  }
  if (type === 'help') {
    return <Help />;
  }
  if (type === 'copy') {
    return <Copy />;
  }
  if (type === 'star') {
    return <Star />;
  }
  if (type === 'star-2') {
    return <Star2 />;
  }
  if (type === 'lock-alt') {
    return <LockAlt />;
  }
  if (type === 'support') {
    return <Support />;
  }
  if (type === 'hangeh-logo') {
    return <HangehLogo3 />;
  }
  if (type === 'checked-mark') {
    return <CheckedMark />;
  }
  if (type === 'cancel-rounded') {
    return <CancelRounded />;
  }
  if (type === 'bell') {
    return <Bell />;
  }
  if (type === 'bike') {
    return <Bike />;
  }
  if (type === 'arrow-left') {
    return <ArrowLeft />;
  }
  if (type === 'settings-2') {
    return <Settings fill={fill} stroke={stroke} />;
  }
  if (type === 'happy-face') {
    return <HappyFace fill={fill} stroke={stroke} />;
  }
  if (type === 'poll') {
    return <Poll />;
  }
  if (type === 'video') {
    return <Video />;
  }
  if (type === 'controls-alt') {
    return <ControlsAlt />;
  }
  if (type === 'list-alt') {
    return <ListAlt />;
  }
  if (type === 'image') {
    return <Image />;
  }
  if (type === 'send') {
    return <Send />;
  }
  if (type === 'coffee-cup') {
    return <CoffeeCup />;
  }
  if (type === 'shield') {
    return <Shield />;
  }
  if (type === 'pencil') {
    return <Pencil />;
  }
  if (type === 'checkmark') {
    return <Checkmark />;
  }
  if (type === 'checkmark-circle') {
    return <CheckmarkCircle />;
  }
  if (type === 'ellypsis') {
    return <Ellypsis />;
  }
  if (type === 'info') {
    return <Info fillChildren={fillChildren} />;
  }
  if (type === 'lightbulb') {
    return <Lightbulb />;
  }
  if (type === 'camera') {
    return <Camera />;
  }
  if (type === 'list-view') {
    return <ListView />;
  }
  if (type === 'add') {
    return <Add />;
  }
  if (type === 'person-add') {
    return <PersonAdd />;
  }
  if (type === 'bug') {
    return <Bug />;
  }
  if (type === 'help-alt') {
    return <HelpAlt />;
  }
  if (type === 'right') {
    return <Right />;
  }
  if (type === 'dollar') {
    return <Dollar />;
  }
  if (type === 'msg-sent-light') {
    return <MsgSentLight />;
  }
  if (type === 'no-messages-light') {
    return <NoMessagesLight />;
  }
  if (type === 'no-requests-light') {
    return <NoReguestsLight />;
  }
  if (type === 'no-attendees') {
    return <NoAttendees />;
  }
  if (type === 'no-bookings') {
    return <NoBookings />;
  }
  if (type === 'shape') {
    return <Shape />;
  }
  if (type === 'minus') {
    return <Minus />;
  }
  if (type === 'clipboard') {
    return <Clipboard />;
  }
  if (type === 'empty-address') {
    return <EmptyAddress />;
  }
  if (type === 'sign-out') {
    return <SignOut />;
  }
  if (type === 'search-filled') {
    return <SearchFilled />;
  }
  if (type === 'message-send') {
    return <MessageSend />;
  }
  if (type === 'bored') {
    return <Bored />;
  }
  if (type === 'share') {
    return <Share />;
  }
  if (type === 'rocket') {
    return <Rocket />;
  }
  if (type === 'plane') {
    return <Plane />;
  }
  if (type === 'horn') {
    return <Horn />;
  }
  if (type === 'file') {
    return <File />;
  }
  if (type === 'download') {
    return <Download />;
  }
  if (type === 'loading') {
    return <Loading />;
  }
  if (type === 'folder') {
    return <Folder />;
  }
  if (type === 'sticker') {
    return <Sticker />;
  }
  if (type === 'pin') {
    return <Pin />;
  }
  if (type === 'hangeh-logo-beta') {
    return <HangehLogoBeta />;
  }
  if (type === 'layers') {
    return <Layers />;
  }
  if (type === 'hamburger') {
    return <Hamburger />;
  }
  if (type === 'hamburger-light') {
    return <HamburgerLight />;
  }
  if (type === 'car-alt') {
    return <Car />;
  }
  if (type === 'coffee') {
    return <Coffee />;
  }
  if (type === 'tv') {
    return <Tv />;
  }
  if (type === 'sounds') {
    return <Sounds />;
  }
  if (type === 'user') {
    return <User />;
  }
  if (type === 'sports') {
    return <SportsPath />;
  }
  if (type === 'food') {
    return <FoodPath />;
  }
  if (type === 'travel') {
    return <TravelPath />;
  }
  if (type === 'events') {
    return <EventsPath />;
  }
  if (type === 'movies') {
    return <MoviesPath />;
  }
  if (type === 'arts') {
    return <ArtsPath />;
  }
  if (type === 'fitness') {
    return <FitnessPath />;
  }
  if (type === 'games') {
    return <GamesPath />;
  }
  if (type === 'music') {
    return <MusicPath />;
  }
  if (type === 'dance') {
    return <DancePath />;
  }
  if (type === 'others') {
    return <OthersPath />;
  }
  if (type === 'big-circle-ok') {
    return <BigCircleOk />;
  }
  if (type === 'groups') {
    return <GroupsPath />;
  }
  if (type === 'animal') {
    return <AnimalPath />;
  }
  if (type === 'duotone-check') {
    return <DuotoneCheck />;
  }
  if (type === 'upload') {
    return <Upload />;
  }
  if (type === 'biz-box') {
    return <BizBox />;
  }
  if (type === 'link') {
    return <Link />;
  }
  if (type === 'phone') {
    return <Phone />;
  }
  if (type === 'cart') {
    return <Cart />;
  }
  if (type === 'umbrella') {
    return <Umbrella />;
  }
  if (type === 'close-4') {
    return <Close4 />;
  }
  if (type === 'new-message') {
    return <NewMessage />;
  }
  if (type === 'comment') {
    return <Comment />;
  }
  if (type === 'info-alt') {
    return <InfoAlt />;
  }
  if (type === 'speak') {
    return <Speak />;
  }
  if (type === 'previous') {
    return <Previous />;
  }
  if (type === 'entrance') {
    return <Entrance />;
  }
  if (type === 'arrow-down') {
    return <ArrowDown />;
  }
  if (type === 'checked-circle') {
    return <CheckedCircle />;
  }
  if (type === 'add-circle') {
    return <AddCircle />;
  }
  if (type === 'close-circle') {
    return <CloseCircle />;
  }
  if (type === 'check-circle') {
    return <CheckCircle />;
  }
  if (type === 'pencil-2') {
    return <Pencil2 />;
  }
  if (type === 'like') {
    return <Like />;
  }
  if (type === 'message') {
    return <Message />;
  }
  if (type === 'eye-open') {
    return <EyeOpen />;
  }
  if (type === 'eye-closed') {
    return <EyeClosed />;
  }
  if (type === 'delete-alt') {
    return <DeleteAlt />;
  }
  if (type === 'list') {
    return <List />;
  }
  if (type === 'marked-circle') {
    return <MarkedCircle />;
  }
  if (type === 'empty-circle') {
    return <EmptyCircle />;
  }
  if (type === 'mail') {
    return <Mail />;
  }
  if (type === 'circled-arrow') {
    return <CircledArrow />;
  }
  if (type === 'circled-plus') {
    return <CircledPlus />;
  }
  if (type === 'circle-double') {
    return <CircleDouble />;
  }
  if (type === 'edit') {
    return <Edit />;
  }
  if (type === 'combined-shape') {
    return <CombinedShape />;
  }
  if (type === 'star-3') {
    return <Star3 />;
  }
  if (type === 'protection') {
    return <Protection />;
  }
  if (type === 'settings-3') {
    return <Settings_3 />;
  }
  if (type === 'bell-2') {
    return <Bell_2 />;
  }
  return null;
};
