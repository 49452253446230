import React, {useCallback, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import Parse from 'parse';
import {userState} from '../states';
import {TUserInsights} from '../types/user';
import {useLiveQuery} from './parse';
import {CreateMsgFields, EChatStatus, TChat, TContact, TMessage, TMessageData, TUserChat} from '../types/mesages';
import {
  getChatsDataForContacts,
  getContactsList,
  getCountUnreadMsgs,
  getDataWithAvatars,
  isManager,
  isOnline,
  mapMessageParseObject,
  mapUsersParseObject,
  prepareData,
  sortDescByDate,
  toPointerMessage,
} from '../helpers/messages';
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks';
import {isString} from '../ui-kit/utils/helpers';
import {QueryGetUser, TQueryGetUserRequest, TQueryGetUserResponse} from '../queries/members';
import {CreateAppFileQuery, CreateFileResponseType} from '../queries/file';
import {
  CreateMessageQuery,
  CreateMessageRequestType,
  CreateMessageResponseType,
  getChatsQuery,
  SetSeenMessageQuery,
  SetSeenMsgRequestType,
  SetSeenMsgResponseType,
  TGetChatsRequest,
  TGetChatsResponse,
  TUpdateChatRequest,
  TUpdateChatResponse,
  TUserInsightsRequest,
  TUserInsightsResponse,
  updateChatQuery,
  userInsightsQuery,
} from '../queries/messages';
import {useHistory} from 'react-router-dom';
import {TCommunityItem, TInsightItem, TPersonData} from '../ui-kit/Chat/PersonInfo/types';
import {useGetUser} from './members';
import {useGetUserCommunities} from './communities';
import {useGetCountUserListings} from './listings';
import {clear} from '../helpers/object';
import {fromConnection} from '../helpers/parse';
import {Connection} from '../types/parse';

export const useGetMessages = () => {
  const viewer = useRecoilValue(userState);

  const viewerObjectId = viewer?.objectId;

  const [queryMsg, setQueryMsg] = useState<Parse.Query>();
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapMessageParseObject(object);
    },
    [queryMsg],
  );
  const {data, refetch, loading} = useLiveQuery<TMessage>({
    query: queryMsg as Parse.Query,
    map: memoMap,
  });

  useEffect(() => {
    if (!viewerObjectId) return;
    const user = new Parse.User();
    user.id = viewerObjectId;
    const queryMsg = new Parse.Query('Message')
      .equalTo('ShowTo', user)
      .include(['Attachments', 'Author.Avatar', 'ShowTo', 'Seen.User', 'Chat'])
      .addAscending('createdAt')
      .limit(20000);
    setQueryMsg(queryMsg);
  }, [viewerObjectId]);

  return {data: Object.values(data || {}), refetch, loading};
};

interface IUseActiveContact {
  (params: {
    contactId?: string;
    contacts?: TContact[] | null;
    unsetOrder?: () => void;
    orderUsers?: string[];
  }): readonly [TContact | null | undefined, React.Dispatch<React.SetStateAction<TContact | null | undefined>>];
}

export const useActiveContact: IUseActiveContact = ({contactId, contacts}) => {
  const [activeContact, setActiveContact] = useState<TContact | null>();

  useEffect(() => {
    if (!contactId || !contacts?.length) return;
    const activeC = contacts?.find((it) => it?.objectId === contactId);
    activeC && setActiveContact(activeC);
  }, [contactId, contacts?.length]);
  return [activeContact, setActiveContact] as const;
};

export const useContacts = (msgs?: TMessage[], viewerId?: string, activeId?: string) => {
  const client = useApolloClient();
  const [activeUser, setActiveUser] = useState<TUserChat | null>(null);
  const mapping: Record<string, boolean> = {};
  const contacts: TUserChat[] = [];
  const contactsIds: string[] = [];

  const getActiveContact = async () => {
    try {
      const {data} = await client.query<TQueryGetUserResponse, TQueryGetUserRequest>({
        query: QueryGetUser,
        variables: {
          id: activeId || '',
        },
      });

      if (data?.user) {
        setActiveUser({
          objectId: data.user.objectId,
          Avatar: data.user.Avatar,
          firstName: data.user.firstName as string,
          lastName: data.user.lastName as string,
          onlineDate: data.user.onlineDate as Date,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (!activeId?.trim()) return;
    if (contacts?.find((it) => it.objectId === activeId)) return;
    getActiveContact();
  }, [activeId]);

  sortDescByDate(msgs, 'createdAt')?.forEach((it) => {
    const user = (it.ShowTo || [])?.find((el) => el?.objectId !== viewerId);
    if (!user?.objectId) return;
    if (!mapping[user.objectId]) {
      mapping[user.objectId] = true;
      contacts.push(user);
      contactsIds.push(user.objectId);
    }
  });

  if (activeUser && !contactsIds?.includes(activeUser.objectId)) {
    return {
      contacts: [activeUser, ...contacts],
      contactsIds: [activeUser.objectId, ...contactsIds],
    };
  }

  return {
    contacts: contacts,
    contactsIds: contactsIds,
  };
};

interface IUseCreateMessage {
  (options: {
    initialState: TMessageData;
    onSuccess?: () => void;
    idActiveContact?: string;
    idActiveChat?: string;
    communityId?: string;
  }): {
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
    values: TMessageData;
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    deleteImage: (index: number) => void;
  };
}

export const useCreateMessage: IUseCreateMessage = (options) => {
  const viewer = useRecoilValue(userState);
  const [values, setValues] = useState(options?.initialState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFileQuery);
  const [CreateMessageRequest, {loading: requestLoading}] = useMutation<
    CreateMessageResponseType,
    CreateMessageRequestType
  >(CreateMessageQuery);

  useEffect(() => {
    if (!isString(options?.idActiveContact)) return;
    setValues((prev) => ({...prev, ShowTo: options?.idActiveContact as string, Attachments: []}));
  }, [options?.idActiveContact]);

  const handleChange = (params: {name: CreateMsgFields; value: string | File[] | null}) => {
    setSuccess(false);
    if (params.name === CreateMsgFields.Attachments) {
      return setValues((prev) => ({
        ...prev,
        Attachments: [...((prev.Attachments as File[]) || []), ...(params.value as File[])],
      }));
    }
    return setValues((prev) => ({...prev, [params.name]: params.value}));
  };

  const deleteImage = (index: number) => {
    const newImgs = (values?.Attachments as File[])?.filter((it, inx) => inx !== index);
    setValues((prev) => ({...prev, Attachments: newImgs}));
  };

  const createMessage = async (): Promise<void> => {
    try {
      setLoading(true);
      if (!values?.ShowTo || (!values?.text && !values?.Attachments?.length) || !viewer) return;

      const parseImages = await Promise.all(
        (values?.Attachments ?? [])?.map(async (file) => {
          const result = await createAppFile({
            variables: {
              fields: {
                file: {upload: file},
                Owner: {link: viewer.objectId},
              },
            },
          });
          return result.data?.createAppFile?.appFile;
        }),
      );

      const fields = toPointerMessage({
        ...clear(values),
        Author: viewer.objectId,
        ShowTo: values.ShowTo,
        Attachments: parseImages,
        Community: options?.communityId,
      });

      const result = await CreateMessageRequest({
        variables: {
          fields: {
            ...fields,
            ...(options?.idActiveChat
              ? {
                  Chat: {
                    link: options?.idActiveChat,
                  },
                }
              : {}),
          },
        },
      });

      const message = result?.data?.createMessage?.message;
      if (!message) throw new Error();
      setValues((prev) => ({...prev, text: '', Attachments: []}));
      setSuccess(true);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(false);
      console.log(error);
      return;
    } finally {
      setLoading(requestLoading);
    }
  };

  return {
    values,
    onSubmit: createMessage,
    success,
    loading,
    onChange: handleChange,
    deleteImage,
  };
};

export const useGetContactsForChat = (params: {
  initUsers?: TUserChat[] | null;
  msgs?: TMessage[];
  loading?: boolean;
  contactsIds?: string[] | [];
  chats?: Connection<TChat>;
  mapChats?: Record<string, TChat>;
}) => {
  const viewer = useRecoilValue(userState);
  const viewerId = viewer?.objectId;

  const [query, setQuery] = useState<Parse.Query>();
  const [contacts, setContacts] = useState<TContact[] | null | undefined>();
  const history = useHistory();

  const excludeFields = [
    'Communities',
    'Currency',
    'Orders',
    'Reviews',
    'Viewers',
    'address',
    'aptSuite',
    'bio',
    'birthDate',
    'earned',
    'emailVerified',
    'gender',
    'homepage',
    'howDidYouHear',
    'isVerified',
    'languages',
    'phone',
    'spent',
    'status',
    'stripeId',
    'study',
    'username',
    'work',
    'zip',
  ];

  useEffect(() => {
    if (!params?.contactsIds?.length) return;
    const query = new Parse.Query(Parse.User)
      .include(['objectId', 'onlineDate', 'Avatar', 'firstName', 'lastName'])
      .exclude(...excludeFields)
      .containedIn('objectId', params.contactsIds)
      .limit(20000);
    setQuery(query);
  }, [params?.contactsIds?.length]);

  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapUsersParseObject(object);
    },
    [query],
  );
  const {
    data,
    refetch,
    loading: loadingLQ,
  } = useLiveQuery<TUserChat>({
    query: query as Parse.Query,
    map: memoMap,
  });

  useEffect(() => {
    if (!params?.initUsers?.length) return;
    setContacts(
      getChatsDataForContacts(
        // eslint-disable-next-line
        // @ts-ignore
        getDataWithAvatars(Object.values(data || {}), getContactsList(params?.initUsers, viewerId, params?.msgs)),
        params?.mapChats,
      ),
    );
  }, [data, params?.initUsers?.length, params?.msgs?.length, history.location.pathname, params?.chats]);

  useEffect(() => {
    const dataUsers = Object.values(data || {});
    dataUsers?.forEach((userLQ) => {
      contacts?.forEach((user, index) => {
        if (userLQ.objectId !== user.objectId) return;
        const isGotOnline = !user.onlineStatus && isOnline(userLQ.onlineDate);
        const isLostOnline = user.onlineStatus && !isOnline(userLQ.onlineDate);
        if (!isGotOnline && !isLostOnline) return;
        const copyUsers = [...contacts];
        copyUsers[index] = {...user, onlineStatus: isOnline(userLQ.onlineDate)};
        setContacts(copyUsers);
      });
    });
  }, [data, contacts]);
  return {contacts, refetch, loading: params.loading || loadingLQ};
};

interface IUseSeenMessage {
  (options: {onSuccess?: () => void}): {
    setSeenMessage: (params: {seen: string[]}) => void;
    loading: boolean;
  };
}

export const useSetSeenMessage: IUseSeenMessage = (options) => {
  const [UpdateMessageRequest, {loading}] = useMutation<SetSeenMsgResponseType, SetSeenMsgRequestType>(
    SetSeenMessageQuery,
  );

  const setSeenMessage = async (params: {seen: string[]}): Promise<boolean | void> => {
    try {
      if (!params?.seen?.length) return;

      const result = await UpdateMessageRequest({
        variables: {messageIds: params.seen},
      });

      const res = result?.data?.messageSetSeen;
      if (!res) throw new Error();
      options?.onSuccess?.();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return {
    setSeenMessage,
    loading,
  };
};

interface IGetUserInsights {
  (contactId?: string): {
    data?: TUserInsights | null;
    loading: boolean;
  };
}

export const useGetUserInsights: IGetUserInsights = (contactId) => {
  const [query, {data, loading}] = useMutation<TUserInsightsResponse, TUserInsightsRequest>(userInsightsQuery);

  useEffect(() => {
    if (!contactId) return;

    query({variables: {id: contactId}});
  }, [contactId]);

  return {data: data?.getUserInsightsData, loading};
};

export const useGetChatWithUser = (userId?: string) => {
  const viewer = useRecoilValue(userState);
  const {data, ...other} = useQuery<TGetChatsResponse, TGetChatsRequest>(getChatsQuery, {
    variables: {
      where: {
        Members: {
          have: {
            AND: [
              {
                objectId: {equalTo: userId},
              },
              {
                objectId: {equalTo: viewer?.objectId},
              },
            ],
          },
        },
      },
    },
    skip: !userId,
  });

  return {
    data: fromConnection(data?.chats)?.[0],
    ...other,
  };
};

export const useGetChats = () => {
  const viewer = useRecoilValue(userState);
  const {data, ...other} = useQuery<TGetChatsResponse, TGetChatsRequest>(getChatsQuery, {
    variables: {
      where: {
        Members: {
          have: {
            objectId: {equalTo: viewer?.objectId},
          },
        },
      },
    },
    skip: !viewer?.objectId,
    fetchPolicy: 'network-only',
  });

  return {
    data: data?.chats,
    ...other,
  };
};

export const useActionsOnChat = (chatId?: string, refetchChats?: () => void) => {
  const [callUpdate] = useMutation<TUpdateChatResponse, TUpdateChatRequest>(updateChatQuery);

  const close = () => {
    return callUpdate({
      variables: {
        id: chatId as string,
        fields: {
          status: EChatStatus.close,
        },
      },
    }).then(() => refetchChats?.());
  };

  const open = () => {
    return callUpdate({
      variables: {
        id: chatId as string,
        fields: {
          status: EChatStatus.open,
        },
      },
    }).then(() => refetchChats?.());
  };

  const archive = () => {
    return callUpdate({
      variables: {
        id: chatId as string,
        fields: {
          isArchived: true,
        },
      },
    }).then(refetchChats);
  };

  const unarchive = () => {
    return callUpdate({
      variables: {
        id: chatId as string,
        fields: {
          isArchived: false,
        },
      },
    }).then(refetchChats);
  };

  return {
    close,
    open,
    archive,
    unarchive,
  };
};

interface IGetPersonInfo {
  (contactId?: string): {
    insights?: Array<TInsightItem>;
    communities?: Array<TCommunityItem>;
    user?: TPersonData;
  };
}

export const useGetPersonInfo: IGetPersonInfo = (contactId) => {
  const {data: insightsData} = useGetUserInsights(contactId);
  const {data: userData} = useGetUser(contactId);
  const {data: communitiesData} = useGetUserCommunities(contactId);
  const {data: countListings} = useGetCountUserListings(contactId);

  return {
    insights: prepareData.insights({insightsData: insightsData}),
    user: prepareData.user({
      user: userData,
      countListings,
      isManager: isManager({
        userId: contactId,
        communities: communitiesData,
      }),
    }),
    communities: prepareData.communities(communitiesData),
  };
};

const getContactFilterValue = (contact: TContact): number => {
  if (contact.isArchived) return 0;
  if (contact.isClosed) return 1;
  return 2;
};

const filterContacts = (a: TContact, b: TContact) => {
  return getContactFilterValue(b) - getContactFilterValue(a);
};

export enum EFilterOptions {
  all,
  open,
  archived,
  unread,
}

const filterConditions: Record<EFilterOptions, (contact: TContact) => boolean> = {
  [EFilterOptions.all]: () => true,
  [EFilterOptions.archived]: (c) => Boolean(c.isArchived),
  [EFilterOptions.open]: (c) => !c.isClosed,
  [EFilterOptions.unread]: (c) => Boolean(c.dataMsg?.haveUnseenMsg),
};

export const useFilterContacts = (
  contacts: Array<TContact>,
  activeContactData: {
    activeContact: TContact | null | undefined;
    setActiveContact: React.Dispatch<React.SetStateAction<TContact | null | undefined>>;
  },
) => {
  const {setActiveContact, activeContact} = activeContactData;

  const [filter, setFilter] = useState<EFilterOptions>(EFilterOptions.all);

  const handleSetFilter = (type: EFilterOptions) => {
    setFilter(type);
  };

  let filteredContacts: Array<TContact> | undefined;

  if (filter === EFilterOptions.archived)
    filteredContacts = contacts?.filter(filterConditions[EFilterOptions.archived]).sort(filterContacts);
  else if (filter === EFilterOptions.open)
    filteredContacts = contacts?.filter(filterConditions[EFilterOptions.open]).sort(filterContacts);
  else if (filter === EFilterOptions.unread)
    filteredContacts = contacts?.filter(filterConditions[EFilterOptions.unread]).sort(filterContacts);
  else filteredContacts = [...contacts].sort(filterContacts);

  useEffect(() => {
    if (!activeContact) return;
    if (!filterConditions[filter](activeContact)) setActiveContact(null);
  }, [filter]);

  return {
    contacts: filteredContacts,
    handleSetFilter,
    currentFilter: filter,
  };
};

export const useUnreadMessages = () => {
  const viewer = useRecoilValue(userState);
  const [queryMsg, setQueryMsg] = useState<Parse.Query>();
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapMessageParseObject(object);
    },
    [queryMsg],
  );
  const {data, refetch} = useLiveQuery<TMessage>({
    query: queryMsg as Parse.Query,
    map: memoMap,
  });
  useEffect(() => {
    if (!viewer?.objectId) return;
    const user = new Parse.User();
    user.id = viewer.objectId;
    const queryMsg = new Parse.Query('Message')
      .equalTo('ShowTo', user)
      .notEqualTo('Author', user)
      .exclude('Attachments', 'Order', 'text')
      .limit(50000);
    setQueryMsg(queryMsg);
  }, [viewer?.objectId]);
  return {count: getCountUnreadMsgs(Object.values(data || {}), viewer?.objectId), refetch};
};
