import React from 'react';
import {EmptyCardProps} from './types';
import {EmptyCardDescription, EmptyCardImageWrapper, EmptyCardImg, EmptyCardTitle, EpmtyCardWrapper} from './styles';

export const EmptyCard: React.FC<EmptyCardProps> = ({children, imageSrc, imageSvg, title, description}) => {
  return (
    <EpmtyCardWrapper>
      {imageSrc ? (
        <EmptyCardImageWrapper>
          <EmptyCardImg src={imageSrc} alt={'image'} />
        </EmptyCardImageWrapper>
      ) : (
        imageSvg && <EmptyCardImageWrapper>{imageSvg}</EmptyCardImageWrapper>
      )}
      <EmptyCardTitle>{title}</EmptyCardTitle>
      <EmptyCardDescription>{description}</EmptyCardDescription>
      {children}
    </EpmtyCardWrapper>
  );
};
