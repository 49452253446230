import React from 'react';
import {Configrations} from '../../components/Configrations';
import {Tab} from '../../ui-kit/Tabs/Tab';
import {Tabs} from '../../ui-kit/Tabs/Tabs';
import {PricingAndFeaturesContainer} from './PricingAndFeatures';

export const ConfigrationsContainer = () => {
  return (
    <Configrations>
      <Tabs variant={'primary'}>
        <Tab label={'Pricing & Features'}>
          <PricingAndFeaturesContainer />
        </Tab>
        {/*<Tab label={'Pricing Demo'}></Tab>*/}
      </Tabs>
    </Configrations>
  );
};
