import React, {useEffect, useState} from 'react';
import {BrowserDataProps} from '../../../components/BrowserData';
import {useGetListings, useSearchListing} from '../../../hooks/listings';
import {ListingTableProps} from '../../../components/Listings/table';
import {Item} from '../../../types/item';
import {ItemsListContainer} from '../ItemsListContainer';

export const TabService = () => {
  const {data, count, refetch} = useGetListings('service');
  const [items, setItems] = useState<Item[]>(data ?? []);
  const search = useSearchListing({listingType: {equalTo: 'service'}});

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  const browserDataProps = {
    count,
    initialItems: data,
    setItems,
    onSearch: search,
  } as BrowserDataProps<Item>;

  const tableProps = {
    items,
  } as ListingTableProps;

  return <ItemsListContainer refetch={refetch} browserDataProps={browserDataProps} tableProps={tableProps} />;
};
