import {isFile, isFileInfo, isServer, isString} from './validations';

export const excludeImages = ['webp'];

export const getResize = (url?: string, size?: 'sm' | 'md' | 'lg') => {
  if (!url || !size) return url as string;
  const ext = url.split('.')?.pop();
  if (!ext || excludeImages.includes(ext?.toLowerCase())) return url as string;

  return url.replace(`.${ext}`, `.${size}.${ext}`);
};

export type FileInfo = {
  name: string;
  url: string;
};

export const getSourceUrl = (value?: File | FileInfo | string): string | undefined => {
  if (!value) return;

  if (isString(value)) return value;

  if (isFileInfo(value)) return value.url;

  if (isFile(value) && !isServer()) {
    return (window.URL || window.webkitURL)?.createObjectURL(value);
  }
};
