import React, {useEffect, useState} from 'react';
import {
  BolderText,
  CardMainText,
  CheckListWrapper,
  CheckListWrapperSingle,
  ErrorText,
  HorizontalLine,
  List,
  LoaderWrapper,
  UploadHeader,
  UploadStepButton,
  UploadStepButtonAlert,
  UploadWrapper,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Br} from '../../ui-kit/Typography/Br';
import {Checkbox} from '../../ui-kit/Checkbox/Checkbox';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {CreateAppFileQuery, CreateFileResponseType} from '../../queries/file';
import {CreateTaskQuery, CreateTaskResponseType, getTaskQuery} from '../../queries/task';
import {useViewerId} from '../../hooks/auth';
import {PlanOption} from '../Configrations/ConfigurePlan/ConfigurePlan';
import {PlanOptionsValues} from '../../constants/plan';

enum UploadSteps {
  upload_main = 'upload_main',
  upload_communities = 'upload_communities',
  upload_manage = 'upload_manage',
  upload_create = 'upload_create',
  upload_success = 'upload_success',
  upload_error = 'upload_error',
}

const csvColumnsText = [
  'name',
  'description (max 500 chars)',
  'alias (no spaces)',
  'verified (true or false)',
  'owner (user email)',
  'street address',
  'city',
  'province',
  'zip',
  'country',
  'type (public or private)',
  'approval (manual or auto)',
  'image (image URL)',
];

const possibleErrors = [
  'File format is incorrect',
  'Community alias exist already',
  'Community ZIP code exist already',
];

export const UploadPanel: React.FC<{setModal: (value: boolean) => void}> = ({setModal}) => {
  const [lastIdFile, setLastIdFile] = useState<string | undefined>('');
  const [lastIdTask, setLastIdTask] = useState<string | undefined>('');
  const [error, setError] = useState<string | undefined>('');
  const [processedRows, setProcessedRows] = useState<string | undefined>('');
  const [step, setStep] = useState<UploadSteps>(UploadSteps.upload_main);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFileQuery);
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setStep(UploadSteps.upload_communities);
    const csvFile = event.target.files?.[0];
    if (csvFile?.type != 'text/csv') return setStep(UploadSteps.upload_error);
    createAppFile({
      variables: {
        fields: {
          file: {upload: csvFile},
          type: 'task',
        },
      },
    }).then((res) => {
      setLastIdFile(res.data?.createAppFile.appFile.objectId);
      setStep(UploadSteps.upload_manage);
    });
  };
  return (
    <UploadWrapper>
      {step === UploadSteps.upload_main && <UploadMain handleChange={handleChange} />}
      {step === UploadSteps.upload_communities && <UploadCommunities />}
      {step === UploadSteps.upload_manage && (
        <ManageCommunities setStep={setStep} lastIdFile={lastIdFile} setLastIdTask={setLastIdTask} />
      )}
      {step === UploadSteps.upload_create && (
        <CreateCommunities
          setStep={setStep}
          lastIdTask={lastIdTask}
          setError={setError}
          setProcessedRows={setProcessedRows}
        />
      )}
      {step === UploadSteps.upload_error && <ErrorCommunities handleChange={handleChange} error={error} />}
      {step === UploadSteps.upload_success && <SuccessCommunities setModal={setModal} processedRows={processedRows} />}
    </UploadWrapper>
  );
};

export const UploadMain: React.FC<{
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({handleChange}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <>
      <UploadHeader>Step 1: Upload Communities</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={19} />
      <CardMainText>
        <div>
          Please make sure you have the CSV properly formatted to be able to create bulk communities. Label your CSV
          columns as:
        </div>
        <List>
          {csvColumnsText.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </List>
        <Br indent={19} />
        <BolderText>None of the communities will be created if:</BolderText>
        <List>
          {possibleErrors.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </List>
      </CardMainText>
      <input ref={hiddenFileInput} type="file" accept=".csv" onChange={handleChange} style={{display: 'none'}} />
      <UploadStepButton onClick={handleClick}>Upload CSV</UploadStepButton>
    </>
  );
};

export const UploadCommunities: React.FC = () => {
  return (
    <>
      <UploadHeader>Step 1: Upload Communities</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={50} />
      <CardMainText>
        <LoaderWrapper>
          <IconSvg className="loading" type="loading" width={'36'} height={'36'} viewBox={'-2 -2 24 24'} />
          <Br indent={5} />
          <div>Uploading and validating CSV...</div>
          <Br indent={40} />
        </LoaderWrapper>
      </CardMainText>
    </>
  );
};

type Options = [string, boolean];

export const PlanOptions = (planOptions: string[]): [string, boolean][] => {
  const plan: [string, boolean][] = [];
  for (const i of planOptions) {
    plan.push([i, false]);
  }
  return plan;
};

export const ManageCommunities: React.FC<{
  setStep: (step: UploadSteps) => void;
  lastIdFile: string | undefined;
  setLastIdTask: (value: string) => void;
}> = ({setStep, lastIdFile, setLastIdTask}) => {
  const currentOptions = Object.values(PlanOptionsValues);
  const optionsPlan = PlanOptions(currentOptions);
  const [options, setOptions] = useState<Options[]>(optionsPlan);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [createTask] = useMutation<CreateTaskResponseType>(CreateTaskQuery);
  const id = useViewerId('objectId');
  const [loading, setLoading] = useState<boolean>(false);
  const handleChecked = (key: string) => (next: boolean) => {
    setOptions(options.map((opt) => (opt[0] === key ? [key, next] : opt)));
  };

  const handleSelectAll = ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setOptions(options.map((opt) => [opt[0], true]));
    }
    if (!checked) {
      setOptions(options.map((opt) => [opt[0], false]));
    }
    setSelectAll(checked);
  };

  const handleSubmit = () => {
    setLoading(true);
    const optionsType = options.filter((opt) => opt[1]).map((opt) => opt[0]);
    createTask({
      variables: {
        fields: {
          File: {link: lastIdFile},
          Owner: {link: id},
          options: optionsType,
        },
      },
    }).then((res) => {
      setLoading(false);
      setLastIdTask(res.data?.createTask.task.id);
      setStep(UploadSteps.upload_create);
      if (res.data?.createTask.task.error) {
        setStep(UploadSteps.upload_error);
      }
    });
  };

  return (
    <>
      <UploadHeader>Step 2: Manage Features</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={26} />
      <CardMainText>
        <div>Community Account Features</div>
        <CheckListWrapper>
          {options.map((opt) => (
            <PlanOption option={opt} key={opt[0]} onCheck={handleChecked(opt[0])} />
          ))}
        </CheckListWrapper>
        <HorizontalLine />
        <Br indent={24} />
        <CheckListWrapperSingle>
          <Checkbox size={18} $isChecked={selectAll} onChange={handleSelectAll} />
          <div>Select All</div>
        </CheckListWrapperSingle>
        <Br indent={24} />
        <HorizontalLine />
        <Br indent={24} />
        <div>
          Based on your Selection new communities will be created with the above permissions under the account owner
        </div>
        <UploadStepButtonAlert loading={loading} onClick={handleSubmit}>
          Create Communities
        </UploadStepButtonAlert>
      </CardMainText>
    </>
  );
};

export const CreateCommunities: React.FC<{
  lastIdTask: string | undefined;
  setStep: (step: UploadSteps) => void;
  setError: (error: string) => void;
  setProcessedRows: (rows: string) => void;
}> = ({lastIdTask, setStep, setError, setProcessedRows}) => {
  const {data} = useQuery(getTaskQuery, {variables: {id: lastIdTask}, skip: false, pollInterval: 1000});
  useEffect(() => {
    switch (data?.task?.result) {
      case 'invalid':
        setError(data?.task?.error);
        setStep(UploadSteps.upload_error);
        break;
      case 'done':
        setProcessedRows(data?.task?.processedRow);
        setStep(UploadSteps.upload_success);
        break;
    }
  }, [data]);
  return (
    <>
      <UploadHeader>Creating Communities...</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={50} />
      <CardMainText>
        <LoaderWrapper>
          <IconSvg className="loading" type="loading" width={'36'} height={'36'} viewBox={'-2 -2 24 24'} />
          <Br indent={5} />
          <div>Please hold on, this can take a few minutes...</div>
          <Br indent={40} />
        </LoaderWrapper>
      </CardMainText>
    </>
  );
};
export const ErrorCommunities: React.FC<{
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string | undefined;
}> = ({handleChange, error}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  return (
    <>
      <UploadHeader>Step 1: Upload Communities</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={50} />
      <CardMainText>
        <LoaderWrapper>
          <div>
            Your CSV has errors or some of the communities
            <br /> mentioned already exists, please try again. <br />
            <ErrorText>Error: {error}</ErrorText>
          </div>
          <input ref={hiddenFileInput} type="file" accept=".csv" onChange={handleChange} style={{display: 'none'}} />
          <UploadStepButton onClick={handleClick}>Upload Again</UploadStepButton>
        </LoaderWrapper>
      </CardMainText>
    </>
  );
};

export const SuccessCommunities: React.FC<{setModal: (value: boolean) => void; processedRows: string | undefined}> = ({
  setModal,
  processedRows,
}) => {
  const handleClose = () => {
    setModal(false);
  };
  return (
    <>
      <UploadHeader> {processedRows} Communities have been created</UploadHeader>
      <Br indent={26} />
      <HorizontalLine />
      <Br indent={50} />
      <CardMainText>
        <LoaderWrapper>
          <div>Congrats... all of your new communities have been created!</div>
          <UploadStepButton onClick={handleClose}>Close</UploadStepButton>
        </LoaderWrapper>
      </CardMainText>
    </>
  );
};
