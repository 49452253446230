import {useLocation} from 'react-router-dom';
import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {
  Btn,
  BtnSetRows,
  BtnVector,
  CountRowsSelect,
  CountRowsVariant,
  CountRowsWrapper,
  PaginationWrapper,
  SetRowsBtnWrapper,
  Text,
} from './styles';

const LIMIT_ELEMENTS_PAGINATION = 10;

export const Pagination: React.FC<{
  count: number;
  t?: (alias: string, options?: Record<string, any>) => string;
  stateTitleAlias?: string;
  rowsTitleAlias?: string;
}> = ({count, rowsTitleAlias, stateTitleAlias, t}) => {
  const location = useLocation();
  const [ShowCountRows, setStateBtn] = useState(false);
  const countRowsRef = useRef<HTMLSpanElement>(null);

  const page = Number(new URLSearchParams(location.search).get('page')) || 1;
  const rows = Number(new URLSearchParams(location.search).get('elements')) || LIMIT_ELEMENTS_PAGINATION;
  const skipElements = rows * (page - 1);

  useEffect(() => setStateBtn(false), [page, rows]);

  const getPage = (page: number) => ({...location, search: `?page=${page}&elements=${rows}`});
  const getRows = (rows: number) => ({...location, search: `?page=${1}&elements=${rows}`});

  const handlerNextPage = () => {
    if (page < Math.ceil(count / rows)) return getPage(page + 1);
    return getPage(page);
  };
  const handlerPrevPage = () => {
    if (page > 1) return getPage(page - 1);
    return getPage(page);
  };

  const CountRows = () => {
    return (
      <CountRowsSelect ref={countRowsRef}>
        <CountRowsVariant to={getRows(10)}>
          <Text>10</Text>
        </CountRowsVariant>
        <CountRowsVariant to={getRows(50)}>
          <Text>50</Text>
        </CountRowsVariant>
        <CountRowsVariant to={getRows(100)}>
          <Text>100</Text>
        </CountRowsVariant>
      </CountRowsSelect>
    );
  };

  const openSelect: MouseEventHandler = (event) => {
    const closeSelect = (e: MouseEvent) => {
      if (countRowsRef.current !== e.target && event.target !== e.target) {
        setStateBtn(false);
        document.body.removeEventListener('click', closeSelect);
      }
    };

    setStateBtn(true);
    document.body.addEventListener('click', closeSelect);
    setTimeout(() => countRowsRef.current?.scrollIntoView());
  };

  const startEl = Math.min(skipElements + 1, count);
  const endEl = Math.min(skipElements + rows, count);

  const rowsTitle = (rowsTitleAlias && t?.(rowsTitleAlias, {count})) || `Rows per page: ${rows}`;
  const stateTitle =
    (stateTitleAlias && t?.(stateTitleAlias, {startEl, endEl, count})) || `${startEl}-${endEl} of ${count}`;

  return (
    <PaginationWrapper>
      <Text>
        {rowsTitle}
        <CountRowsWrapper>
          <SetRowsBtnWrapper onClick={openSelect}>
            <BtnSetRows type="polygon-down" />
          </SetRowsBtnWrapper>
          {ShowCountRows && <CountRows />}
        </CountRowsWrapper>
      </Text>
      <Text>{stateTitle}</Text>
      <Btn isHidden={count <= rows} to={handlerPrevPage()}>
        <BtnVector type="vector-left" />
      </Btn>
      <Btn isHidden={count <= rows} to={handlerNextPage()}>
        <BtnVector type="vector-right" />
      </Btn>
    </PaginationWrapper>
  );
};
