import React from 'react';
import {ClearWrapper, PlaceHolder, SearchInputWrapper, StyledInput as CustomInput} from './styles';
import {HTMLInputProps} from './types';
import {IconSvg} from '../../Icon/Svg';

interface Props {
  canClear?: boolean;
  onClear?: () => void;
  placeHolder?: string;
}

export const SearchInput: React.FC<HTMLInputProps & Props> = (props) => {
  const onClear = () => props?.onClear?.();

  return (
    <SearchInputWrapper>
      {!props?.canClear && <IconSvg type="search" />}
      {props?.placeHolder && <PlaceHolder>{props?.placeHolder}</PlaceHolder>}
      <CustomInput {...props} />
      {props?.canClear && props?.value && (
        <ClearWrapper onClick={onClear}>
          <IconSvg type="close-4" viewBox={'-1 0 22 24'} />
        </ClearWrapper>
      )}
    </SearchInputWrapper>
  );
};
