import React, {useEffect, useState} from 'react';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {format} from 'date-fns';
import {NOTIFICATIONS_TEXTS} from '../../../constants/messages';
import {getNotificationMessage} from '../../../helpers/messages';
import {MsgStatus, TMessage, TUserChat} from '../../../types/mesages';
import {MessageBox as UiMessageBox} from '../../../ui-kit/Chat/Messages/MessageBox';
import {textsMessages} from '../texts';
import {Notification} from './Notification';
import {leftOnlyLinkTag} from '../../../helpers/common';
import linkifyHtml from 'linkifyjs/html';
import {DateLine, UiMessageBoxWrapper} from './styles';

type MessageProps = {
  message?: TMessage;
  isSeen?: boolean;
  isOwner?: boolean;
  avatar?: string;
  name?: string;
  onSeen: () => void;
  setRef: ((el: HTMLDivElement) => void) | null;
  authorId?: string;
  imagePreviewToggle: (f: {previewImg?: string; images: string[]}) => void;
  author?: Partial<TUserChat>;
  viewerId?: string;
  isNote: boolean;
  isNotification: boolean;
  isEndOfBlock?: boolean;
  isDifferentDay?: boolean;
  showStatus?: boolean;
};

export const MessageBox: React.FC<MessageProps> = ({
  avatar,
  name,
  message,
  isSeen,
  isOwner,
  onSeen,
  setRef: $setRef,
  imagePreviewToggle,
  isNote,
  isNotification,
  isEndOfBlock,
  showStatus,
  isDifferentDay,
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref) return;

    const handleObserve = function (entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.target === ref && entry.isIntersecting) {
          observer.disconnect();
          onSeen();
        }
      });
    };

    const observer = new IntersectionObserver(handleObserve, {
      root: null,
      threshold: [0.1],
    });

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (!ref || !$setRef) return;

    $setRef(ref);
  }, [ref, $setRef]);

  const status = isSeen ? MsgStatus.seen : MsgStatus.delivered;
  const formattedText = leftOnlyLinkTag(message?.text);
  const messageDate = message?.createdAt ? format(new Date(message?.createdAt), 'cccc, LLL d') : '';

  const callLinkify = (str: string, ...args: any[]): string => {
    return linkifyHtml(str, args);
  };

  if (isNotification && message?.text && NOTIFICATIONS_TEXTS.includes(message.text as any))
    return (
      <div ref={setRef}>
        <Notification text={getNotificationMessage(message)} />
      </div>
    );
  return (
    <UiMessageBoxWrapper ref={setRef}>
      {isDifferentDay && <DateLine>{messageDate}</DateLine>}
      <UiMessageBox
        time={message?.createdAt as unknown as Date}
        userAvatar={avatar}
        userName={name || ''}
        messageText={formattedText}
        messageStatus={getMessageText({isNote, isOwner: Boolean(isOwner), status})}
        images={message?.Attachments}
        linkify={callLinkify}
        Item={Item}
        Gallery={Gallery}
        isOwner={isOwner}
        imagePreviewToggle={imagePreviewToggle}
        $isInternal={isNote}
        isEnd={isEndOfBlock}
        showStatus={showStatus}
      />
    </UiMessageBoxWrapper>
  );
};

const getMessageText = (data: {isOwner: boolean; isNote: boolean; status: MsgStatus}): string => {
  const {isNote, isOwner, status} = data;

  if (isNote && isOwner) return textsMessages.status.internalNote;
  if (isOwner) return textsMessages.status[status];

  return '';
};
