import React from 'react';
import {Community as CommunityType} from '../../types/communities';
import {Table as BaseTable} from '../../ui-kit/Table';
import {Columns} from '../../ui-kit/Table/types';
import {Community, getMenu, NetPaid, Payment, Subscription, Traction} from './columns';

export type updateCommunity = (community: CommunityType) => Promise<void>;

interface Props {
  items?: CommunityType[];
  disableCommunity: updateCommunity;
  activeCommunity: updateCommunity;
  verifyCommunity: updateCommunity;
}

function isInactiveItem(community: CommunityType) {
  if (community.status === 'disabled') return true;
}

export const Table: React.FC<Props> = ({items, disableCommunity, verifyCommunity, activeCommunity}) => {
  const columns: Columns<CommunityType> = [
    {
      title: 'created',
      width: '110px',
      padLeft: '25px',
      dataIndex: 'createdAt',
      render: 'date',
    },
    {
      title: 'community',
      width: '200px',
      padRight: '30px',
      render: Community,
    },
    {
      title: 'subscription',
      width: '150px',
      render: Subscription,
    },
    {
      title: 'payment',
      width: '150px',
      padRight: '20px',
      render: Payment,
    },
    {
      title: 'traction',
      width: '100px',
      padRight: '36px',
      render: Traction,
    },
    {
      title: 'net paid',
      width: '80px',
      align: 'right',
      render: NetPaid,
      dataIndex: 'createdAt',
    },
    {
      render: getMenu(verifyCommunity, disableCommunity, activeCommunity),
      width: '60px',
    },
  ];
  if (!items) return null;
  return (
    <BaseTable<CommunityType>
      variant="primary"
      items={items}
      columns={columns}
      height="62px"
      isInactiveItem={isInactiveItem}
    />
  );
};
