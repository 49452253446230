import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;
  width: 100%;

  ${Media.down.s} {
    justify-content: center;
    align-items: flex-end;
  }
`;

export const ModalWrapper = styled.div`
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
`;

export const ButtonClose = styled.div`
  position: absolute;
  cursor: pointer;
  top: 28px;
  right: 20px;
`;
