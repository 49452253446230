import {gql} from '@apollo/client';

export const InsightsDataFragment = gql`
  fragment InsightsDataFragment on SupportInsightPayload {
    amenitiesBooked
    eventsAttended
    listingsPosted
    listingsValueSum
    listingsTransactions
    totalSessions
    totalMessages
    totalLikes
    totalPosts
    moneySpent
  }
`;
