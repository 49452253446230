import styled from 'styled-components';
import arrow from './images/arrow-down.png';

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const HeaderWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BackButton = styled.button`
  display: block;
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 14px;
  padding: 5px 30px;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &::before {
    content: '';
    position: absolute;
    background: center / contain no-repeat url('${arrow}');
    top: 50%;
    left: -40px;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%) rotateY(180deg);
  }
`;

export const TextContainer = styled.div`
  text-align: right;

  h2 {
    margin: 0 0 10px;
  }
`;
