import styled from 'styled-components';
import {Button} from '../../ui-kit/Button/Button';

export const Wrapper = styled.div<{navIsOpen: boolean}>`
  margin-left: ${({navIsOpen}) => (navIsOpen ? '255px' : '73px')};
`;

export const Header = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
`;

export const LogOutBtn = styled(Button).attrs({width: '64px', height: '19px', ghost: true, variant: 'secondary'})`
  ${({theme}) => theme.font.primary.regular}
  font-size: 10px;
  line-height: 15px;
  padding: 0;
  color: ${({theme}) => theme.palette.grayscale._12};
  margin: 0 17px;
  border-width: 1.5px;
  div {
    padding: 2px;
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const DisabledWrapper = styled.div`
  pointer-events: none;
`;
