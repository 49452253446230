import React from 'react';
import styled from 'styled-components';
import {TabType, TabMenuProps} from './types';

const Tabs = styled.div<{lineLocation: number}>`
  ${({theme}) => theme.font.quaternary.medium}
  padding-bottom: ${({lineLocation}) => lineLocation}px;
  border-bottom: 1px solid ${({theme}) => theme.palette.common.blueGray2};
  font-size: 14px;
  line-height: 17px;
`;

const TabWrapper = styled.span<{
  active: boolean;
  leftIndent?: string;
  tabPaddingX?: string;
  lineLocation: number;
}>`
  position: relative;
  cursor: pointer;
  margin-left: ${({leftIndent}) => leftIndent};
  padding: 0 ${({tabPaddingX}) => tabPaddingX};
  color: ${({
    active,
    theme: {
      palette: {
        common: {purpleDark, blueGrayDark},
      },
    },
  }) => (active ? purpleDark : blueGrayDark)};
  &::before {
    position: absolute;
    content: '';
    height: 2px;
    background-color: ${({theme, active}) => (active ? theme.palette.common.purpleDark : 'transparent')};
    bottom: -${({lineLocation}) => lineLocation + 1}px;
    left: 0;
    right: 0;
  }
`;

const TabItem: React.FC<
  TabType & {
    lineLocation: number;
    activeTab?: string;
    onClick: () => void;
  }
> = ({activeTab, label, leftIndent, tabPaddingX, lineLocation, onClick, component: Component}) => {
  return (
    <TabWrapper
      onClick={onClick}
      leftIndent={leftIndent}
      active={activeTab == label}
      tabPaddingX={tabPaddingX}
      lineLocation={lineLocation}>
      {Component ? <Component>{label}</Component> : label}
    </TabWrapper>
  );
};

export const PrimaryTabMenu: React.FC<TabMenuProps> = ({tabs, lineLocationPx = 12, activeTab, setActiveTab}) => {
  const changeActiveTab = (label: string) => {
    const setActive = () => setActiveTab(label);
    return setActive;
  };
  return (
    <div>
      <Tabs lineLocation={lineLocationPx}>
        {tabs?.map((el) => {
          return (
            <TabItem
              key={el.label}
              label={el.label}
              leftIndent={el.leftIndent}
              tabPaddingX={el.tabPaddingX}
              lineLocation={lineLocationPx}
              activeTab={activeTab}
              component={el.component}
              onClick={changeActiveTab(el.label)}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
