import {gql} from '@apollo/client';

export const ChatFragment = gql`
  fragment ChatFragment on Chat {
    id
    objectId
    createdAt
    updatedAt
    isArchived
    status
  }
`;
