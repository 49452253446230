import styled from 'styled-components';
import {Button} from '../../../ui-kit/Button/Button';
import {Text} from '../../../ui-kit/Typography/Text';

export const SelectsWrapper = styled.div`
  padding: 28px 0 22px 0;
  display: flex;
  & > * {
    margin-right: 22px;
  }
`;

export const WrapperTable = styled.div`
  position: relative;
  margin-bottom: 26px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableTitle = styled(Text).attrs({size: 18, color: 'octonary'})`
  margin-bottom: 10px;
`;

export const StyledText = styled(Text).attrs({color: 'octonary', size: 14, weight: 'medium'})<{
  underline?: boolean;
  paddingLeft?: number;
}>`
  ${({underline}) => underline && 'text-decoration: underline;'}
  ${({paddingLeft}) => paddingLeft && `padding-left: ${paddingLeft}px;`}
`;

export const StyledInput = styled.input<{
  $underline?: boolean;
  paddingLeft?: number;
}>`
  ${({theme}) => theme.font.primary.medium}
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.octonary};
  border: 0;
  text-align: center;
  background-color: transparent;
  outline: none;
  display: inline-flex;
  ${({$underline}) => $underline && 'text-decoration: underline;'}
  ${({paddingLeft}) => paddingLeft && `padding-left: ${paddingLeft}px;`}
  width: 50px;
`;

export const GroupeColumns = styled.div`
  display: flex;
  max-width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: ${({theme}) => theme.palette.background.secondary};
  }
`;

export const ContentColumn = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBtn = styled(Button).attrs({ghost: true, variant: 'custom', height: '22px'})<{dark?: boolean}>`
  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  color: ${({theme, dark}) => theme.palette.text[dark ? 'primary' : 'octonary']};
  font-size: ${({theme}) => theme.palette.text.quindecimal};
  ${({theme, dark}) => dark && `background-color: ${theme.palette.background.quinary};`};
  ${({theme}) => theme.font.primary.regular};
  padding: 0 14px;
  & * {
    border: 0;
    padding: 0;
  }
`;

export const BtnGroupe = styled.div`
  display: flex;
  & > * {
    margin-left: 10px;
  }
`;

export const CFButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FeaturesContent = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  padding: 20px 15px;
  color: ${({theme, active}) => (active ? theme.palette.text.octonary : theme.palette.text.duodecimal)};
`;

export const TableWrapperFixed = styled.div`
  td {
    max-width: 50%;
    width: 100%;
  }
`;

export const TitleLeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
`;
