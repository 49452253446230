declare global {
  interface Window {
    _env_: {
      REACT_APP_APPLICATION_ID: string | undefined;
      REACT_APP_GRAPHQL_URI: string | undefined;
      REACT_APP_API_KEY: string | undefined;
      REACT_APP_SERVER_URI: string | undefined;
      REACT_APP_LQ_SERVER_URL: string | undefined;
    };
  }
}

export const APPLICATION_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APPLICATION_ID
    : process.env.REACT_APP_APPLICATION_ID;

export const GRAPHQL_URI =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GRAPHQL_URI
    : process.env.REACT_APP_GRAPHQL_URI;

export const SERVER_URI =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SERVER_URI : process.env.REACT_APP_SERVER_URI;

export const API_KEY =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_API_KEY : process.env.REACT_APP_API_KEY;

export const LQ_SERVER_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_LQ_SERVER_URL
    : process.env.REACT_APP_LQ_SERVER_URL;

console.log('APPLICATION_ID', APPLICATION_ID);
