import Select from 'react-dropdown-select';
import styled from 'styled-components';
import {Media, theme} from '../../theme';

export const StyledSelect = styled(Select)<{className: string}>`
  ${theme.font.primary.regular}
  color: ${({theme}) => theme.palette.text.octonary};
  height: 100%;
  &.react-dropdown-select {
    height: 100%;
    border: none;
    border-radius: 8px !important;
    padding: 16px;
    box-sizing: border-box;
    font-size: 16px;
    ${Media.down.s} {
      font-size: 12px;
    }
  }

  & .react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: none;
  }
  & .react-dropdown-select-content.react-dropdown-select-type-single {
    flex-wrap: nowrap;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Media.down.s} {
      font-size: 16px;
    }
    & > span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & .react-dropdown-select-input {
    margin-left: 0;
  }
`;

export const InnerSelect = styled.div<{width?: string; height?: string; $isError?: boolean}>`
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  background: ${({theme}) => theme.palette.background.primary};
  border: ${({$isError, theme}) =>
    $isError ? `2px solid ${theme.palette.common.red}` : `1px solid ${theme.palette.common.gray}`};
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || 'auto'};
  & > div {
    height: 100%;
  }
`;

export const LabelSelect = styled.span<{$isActive?: boolean; $isError?: boolean}>`
  position: absolute;
  background-color: ${({theme}) => theme.palette.background.primary};
  ${theme.font.tertiary.regular};
  color: ${({$isError, theme}) => ($isError ? theme.palette.common.red : 'rgba(0, 0, 0, 0.5)')};
  white-space: nowrap;
  padding: 3px 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  pointer-events: none;
  left: ${({$isActive}) => ($isActive ? '7px' : '10px')};
  top: ${({$isActive}) => ($isActive ? '-12px' : '50%')};
  transform: ${({$isActive}) => ($isActive ? 'none' : 'translateY(-50%)')};
  font-size: ${({$isActive}) => ($isActive ? '12px' : '16px')};
  transition: 0.2s ease all;
  ${Media.down.s} {
    font-size: 16px;
  }
`;

export const WrapperItem = styled.div<{isChecked?: boolean}>`
  position: relative;
  color: ${({theme, isChecked}) => (isChecked ? theme.palette.common.blueSky : theme.palette.text.octonary)};
  padding: 10px 25px 10px 15px;
  box-sizing: border-box;
  width: 100%;
  ${theme.font.primary.regular}

  &:first-child {
    margin-top: 3px;
  }
  &:hover {
    background-color: ${({theme}) => theme.palette.background.secondary};
  }
`;

// separated select

export const SeparatedSelectWrapper = styled.div<{$isLoading?: boolean}>`
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  ${({$isLoading}) =>
    $isLoading &&
    `
    opacity: 0.70;
    pointer-events: none;
  `}
`;

export const SeparatedSelectOptionsWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  box-sizing: border-box;
  overflow-x: auto;

  ${Media.down.m} {
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 4px;
    width: calc(100% + 15px);
  }
  ${Media.down.l} {
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
      background-color: transparent !important; /* or add it to the track */
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`;

export const SeparatedInputOption = styled.div<{selected?: boolean}>`
  --transition-duration: 0.3s;
  background-color: ${({theme, selected}) =>
    selected ? theme.palette.background.denary : theme.palette.background.septenary};
  padding: min(10px, 2vw) min(25px, 6vw);
  border: 1.2px solid
    ${({theme, selected}) => (selected ? theme.palette.background.denary : theme.palette.background.septenary)};
  box-sizing: border-box;
  border-radius: 25px;
  text-align: center;
  min-width: max-content;
  color: ${({theme, selected}) => (selected ? theme.palette.text.primary : theme.palette.text.octonary)};
  cursor: pointer;
  transition: background-color var(--transition-duration) ease, color var(--transition-duration) ease,
    border var(--transition-duration) ease;
  ${({theme}) => theme.font.primary.medium};
  font-size: 0.875rem;

  ${Media.down.m} {
    font-size: 12px;
    padding: 6px 12px;
  }
`;

export const SeparatedSelectLabel = styled.label`
  display: inline-block;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 15px;
  cursor: text;
  ${({theme}) => theme.font.primary.medium};
`;
