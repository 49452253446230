import React from 'react';
import {User, User as UserType} from '../../types/user';
import {Table as BaseTable} from '../../ui-kit/Table';
import {Columns} from '../../ui-kit/Table/types';
import {Communities, Earned, Members, getMenu, Spent, Transactions} from './columns';
import {TableWrapper} from './styles';

export type updateUser = (user: User) => Promise<void>;

interface Props {
  blockUser: updateUser;
  activeUser: updateUser;
  items?: UserType[];
}

function isInactiveItem(user: UserType) {
  if (user.status === 'block') return true;
}

export const Table: React.FC<Props> = ({items, blockUser, activeUser}) => {
  const columns: Columns<UserType> = [
    {
      title: 'date',
      width: '110px',
      padLeft: '17px',
      render: 'date',
      dataIndex: 'createdAt',
    },
    {
      title: 'member',
      width: '160px',
      render: Members,
    },
    {
      title: 'transactions',
      width: '260px',
      render: Transactions,
    },
    {
      title: 'communities',
      width: '110px',
      render: Communities,
    },
    {
      title: 'spent',
      width: '80px',
      align: 'right',
      render: Spent,
    },
    {
      title: 'earned',
      width: '80px',
      align: 'right',
      padLeft: '50px',
      render: Earned,
    },
    {
      render: getMenu(blockUser, activeUser),
      width: '65px',
    },
  ];

  if (!items) return null;
  return (
    <TableWrapper>
      <BaseTable<UserType>
        variant="primary"
        items={items}
        columns={columns}
        height="62px"
        isInactiveItem={isInactiveItem}
      />
    </TableWrapper>
  );
};
