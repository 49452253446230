import {TextBaseProps} from './types';
import styled, {css, StyledProps} from 'styled-components';
import {Link, LinkProps} from 'react-router-dom';

const color = ({color, theme}: StyledProps<TextBaseProps>) => {
  return color ? theme.palette.text[color] : theme.palette.text.grayLight;
};

const colorLink = ({color, theme}: StyledProps<TextBaseProps>) => {
  return color ? theme.palette.text[color] : theme.palette.common.blue;
};

const fontFamily = ({variant}: StyledProps<TextBaseProps>) => {
  switch (variant) {
    case 'primary':
      return 'Inter, sans-serif';
    // return 'Noto Sans';
    case 'secondary':
      return 'Cabin, sans-serif';
    case 'tertiary':
      return 'Roboto, sans-serif';
    case 'quaternary':
      return 'Inter, sans-serif';
    default:
      // return 'Noto Sans';
      return 'Inter, sans-serif';
  }
};

const fontSize = ({size}: TextBaseProps) => {
  return `${size ? `${size}px` : '12px'}`;
};

const fontWeight = ({weight}: TextBaseProps) => {
  switch (weight) {
    case 'bold':
      return 700;
    case 'semi-bold':
      return 500;
    case 'medium':
      return 500;
    case 'regular':
      return 400;
    default:
      return 400;
  }
};

const fontCase = ({case: _case}: TextBaseProps) => {
  return _case ? _case : 'none';
};

const textDecoration = ({decoration}: TextBaseProps) => {
  return decoration ? decoration : 'none';
};

const styles = css`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  text-decoration: ${textDecoration};
  text-transform: ${fontCase};
`;

export const StyledText = styled.span<TextBaseProps>`
  ${styles};
  color: ${color};
`;

export const StyledLink = styled(Link)<TextBaseProps & LinkProps>`
  ${styles};
  color: ${colorLink};
  text-decoration-color: ${colorLink};
`;
