import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  MessagePreviewCardWrapper,
  MessageUserName,
  PreviewMessageCardBody,
  PreviewMessageText,
} from '../../../../ui-kit/Chat/Messages/styles';

export const SkeletonContactCard = () => {
  return (
    <MessagePreviewCardWrapper bordered fullWidth>
      <Skeleton circle={true} width={'53px'} height={'53px'} />
      <PreviewMessageCardBody fullWidth>
        <MessageUserName>
          <Skeleton width={'100%'} />
        </MessageUserName>
        <PreviewMessageText>
          <Skeleton width={'80%'} />
        </PreviewMessageText>
        <PreviewMessageText>
          <Skeleton width={'60%'} />
        </PreviewMessageText>
      </PreviewMessageCardBody>
    </MessagePreviewCardWrapper>
  );
};
