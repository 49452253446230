import styled from 'styled-components';

export const ImageLoaderInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
`;

export const RemoveImg = styled.div`
  position: absolute;
  top: 2px;
  right: 4px;
`;
