import React from 'react';
import {IconSvg} from '../Icon/Svg';
import {ArrowButton} from './components/ArrowButton';
import {MenuItem} from './components/MenuItem';
import {Bar, CenteringWrapper, colors, Footer, SmallText} from './styles';
import {NavMenuProps} from './types';

export const NavMenu: React.FC<NavMenuProps> = ({
  menuItemsTop,
  menuItemsBottom,
  variant,
  mode,
  $isOpenNav,
  setStateNav,
  currentMenuName,
  logout,
  logoutText,
}) => {
  const ChangeStateNav = () => {
    setStateNav((nav) => !nav);
  };
  return (
    <>
      <Bar variant={variant} $isOpenNav={$isOpenNav}>
        <div>
          <ul>
            {menuItemsTop?.map((it, i) =>
              it.render ? (
                it.render(it, variant, mode, logout, logoutText, $isOpenNav)
              ) : (
                <MenuItem
                  key={it.content + `${i}`}
                  variant={variant}
                  mode={mode}
                  $isOpenNav={$isOpenNav}
                  currentMenuName={currentMenuName}
                  {...it}
                />
              ),
            )}
          </ul>
        </div>
        <div>
          <ul>
            {menuItemsBottom?.map((it, i) =>
              it.render ? (
                it.render(it, variant, mode, logout, logoutText, $isOpenNav)
              ) : (
                <MenuItem
                  key={it.content + `${i}`}
                  variant={variant}
                  mode={mode}
                  $isOpenNav={$isOpenNav}
                  currentMenuName={currentMenuName}
                  {...it}
                />
              ),
            )}
          </ul>
          <Footer>
            {$isOpenNav && (
              <CenteringWrapper>
                <IconSvg type="hangeh-logo-beta" width="76px" height="32px" viewBox="15 0 152 64" stroke="none" />
                <SmallText color={colors[variant]}>© {new Date().getFullYear()}</SmallText>
              </CenteringWrapper>
            )}
            <ArrowButton onClick={ChangeStateNav} $isOpenNav={$isOpenNav} variant={variant} mode={mode} />
          </Footer>
        </div>
      </Bar>
    </>
  );
};
