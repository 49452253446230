import React, {useState} from 'react';
import {PricingAndFeatures} from '../../../components/Configrations/PricingAndFeatures';
import {useGetPaymentPlan, useUpdatePaymentPlan} from '../../../hooks/payments';
import {PaymentPlanName} from '../../../types/payments';

export const PricingAndFeaturesContainer = () => {
  const [currentPaymentPlan, setPaymentPlan] = useState(PaymentPlanName.basicUSA);
  const {data: paymentPlan} = useGetPaymentPlan(currentPaymentPlan);
  const {updatePaymentPlan} = useUpdatePaymentPlan();

  if (!paymentPlan) return null;

  return (
    <PricingAndFeatures updatePaymentPlan={updatePaymentPlan} setPaymentPlan={setPaymentPlan} payments={paymentPlan} />
  );
};
