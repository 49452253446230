import {Connection, ParseEntity, ParseObject, Pointer} from '../types/parse';

export const toGQLId = (ParseClassName: ParseEntity, objectId: string) => {
  return btoa(`${ParseClassName}:${objectId}`);
};

export const toPOJO = <T, K extends [...(keyof T)[]] = (keyof T)[], V = any>(
  fields: K,
  item: Parse.Object,
  mapFields?: Partial<Record<keyof T, (value: V | undefined) => T[keyof T]>>,
): T => {
  const entries = fields.map((field) => {
    if (field === 'objectId') return [field, item.id];

    const fieldMap = mapFields?.[field];

    if (fieldMap) {
      return [field, fieldMap(item.get(field as string))];
    }

    return [field, item.get(field as string)];
  });

  return Object.fromEntries(entries);
};

export const toPointer = (item: {__typename: string; objectId: string}): Pointer => {
  return {
    __type: 'Pointer',
    className: item.__typename,
    objectId: item.objectId,
  };
};

export const fromConnection = <T = unknown>(data?: Connection<T>): Array<T> | undefined => {
  return data?.edges.map((el) => el.node);
};

export const toMap = <T extends ParseObject>(data?: Array<T>): Record<T['objectId'], T> | undefined => {
  return data?.reduce((acc, el) => ({...acc, [el.objectId]: el}), {}) as Record<T['objectId'], T>;
};
