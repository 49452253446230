import React from 'react';
import {Communities} from '../../components/Communities';
import {TabAll} from './Tabs/TabAll';
import {TabBasic} from './Tabs/TabBasic';
import {TabPro} from './Tabs/TabPro';
import {TabStarter} from './Tabs/TabStarter';
import {TabInactive} from './Tabs/TabInactive';
import {Tabs} from '../../ui-kit/Tabs/Tabs';
import {Tab} from '../../ui-kit/Tabs/Tab';
import {TabFree} from './Tabs/TabFree';

const CommunitiesContainer = () => {
  return (
    <Communities>
      <Tabs variant="primary">
        <Tab label="All" tabPaddingX="10px">
          <TabAll />
        </Tab>
        <Tab label="Basic" leftIndent="33px">
          <TabBasic />
        </Tab>
        <Tab label="Pro" leftIndent="35px">
          <TabPro />
        </Tab>
        <Tab label="Starter" leftIndent="32px">
          <TabStarter />
        </Tab>
        <Tab label="Free" leftIndent="32px">
          <TabFree />
        </Tab>
        <Tab label="Inactive" leftIndent="43px">
          <TabInactive />
        </Tab>
      </Tabs>
    </Communities>
  );
};

export default CommunitiesContainer;
