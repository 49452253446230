import React from 'react';
import {SNotices} from './styles';

export const NoticesMemo: React.FC<{className?: string; value?: number; width?: string}> = ({
  className,
  value,
  width,
}) => {
  if (!value || value <= 0) return null;

  return (
    <SNotices className={className} width={width}>
      {value}
    </SNotices>
  );
};

const Notices = React.memo(NoticesMemo);
export {Notices};
