import {gql} from 'apollo-boost';
import {CreatePaymentPlanType, PaymentPlan} from '../types/payments';
import {PaymentPlanFragment, SubscrFragment} from './fragments/payments';

export const paymentsPlan = gql`
  query paymentPlan($where: PaymentPlanWhereInput) {
    paymentPlans(where: $where) {
      edges {
        node {
          ...PaymentPlanFragment
        }
      }
    }
  }
  ${PaymentPlanFragment}
`;

export const updatePaymentPlan = gql`
  mutation updatePaymentPlan($input: UpdatePaymentPlanInput!) {
    updatePaymentPlan(input: $input) {
      paymentPlan {
        ...PaymentPlanFragment
      }
    }
  }
  ${PaymentPlanFragment}
`;

export interface UpdatePaymentPlanResponse {
  updatePaymentPlan: {
    paymentPlan: PaymentPlan;
  };
}

export interface CreatePaymentPlanRequest {
  input: {
    fields: Partial<CreatePaymentPlanType>;
  };
}

export interface CreatePaymentPlanResponse {
  createPaymentPlan: {
    paymentPlan: PaymentPlan;
  };
}

export const CreatePaymentPlan = gql`
  mutation createPlan($input: CreatePaymentPlanInput!) {
    createPaymentPlan(input: $input) {
      paymentPlan {
        ...PaymentPlanFragment
      }
    }
  }

  ${PaymentPlanFragment}
`;

interface SubscrBase {
  id?: string;
  objectId?: string;
  status?: string;
  netPaid?: number;
  paymentPeriod?: string;
  expireAt?: string;
}

export interface CreateSubscrResponse {
  createSubscr: {
    subscr: SubscrBase;
  };
}

export interface CreateSubscrRequest extends SubscrBase {
  PaymentPlan?: {
    link?: string;
  };
  Communities?: {
    add?: string[];
  };
}

export const CreateSubscr = gql`
  mutation createSubscr($fields: CreateSubscrFieldsInput) {
    createSubscr(input: {fields: $fields}) {
      subscr {
        ...SubscrFragment
      }
    }
  }

  ${SubscrFragment}
`;
