import {BrowserData, BrowserDataProps} from '../../components/BrowserData';
import {ListingTableProps, Table} from '../../components/Listings/table';
import React from 'react';
import {useDeleteItems} from '../../hooks/listings';
import {actionsWithItems} from './types';

export const ItemsListContainer = <T,>({
  browserDataProps,
  tableProps,
  refetch,
}: {
  browserDataProps: BrowserDataProps<T>;
  tableProps: ListingTableProps;
  refetch: () => void;
}) => {
  const {onDelete} = useDeleteItems({
    onSuccess: refetch,
  });

  const actions: actionsWithItems = {
    onDelete,
  };

  return (
    <BrowserData {...browserDataProps}>
      <Table {...tableProps} actions={actions} />
    </BrowserData>
  );
};
