import React from 'react';
import styled from 'styled-components';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';
import {useHistory} from 'react-router-dom';

const Wrapper = styled.div`
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${({theme}) => theme.font.primary.semiBold};
`;

const ArrowButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const ButtonText = styled(Text).attrs({weight: 'semi-bold', size: 14})`
  margin-left: 10px;
`;

interface Props {
  title: string;
  redirectTo?: string;
}

export const ViewHeader: React.FC<Props> = ({title, redirectTo}) => {
  const history = useHistory();
  const handleRedirect = () => history.push(redirectTo || '/');

  return (
    <Wrapper>
      <ArrowButton onClick={handleRedirect}>
        <IconSvg type="arrow-left" />
        <ButtonText>{title}</ButtonText>
      </ArrowButton>
    </Wrapper>
  );
};
