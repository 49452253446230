import React from 'react';
import {useAuth} from '../hooks/auth';
import {SignIn} from '../components/SignIn';

const AuthWrap: React.FC = () => {
  const {login, onChange, values, loading, errors, clearErrors} = useAuth();

  return (
    <SignIn
      login={login}
      onChange={onChange}
      values={values}
      loading={loading}
      errors={errors}
      clearErrors={clearErrors}
    />
  );
};

export default AuthWrap;
