import React from 'react';
import {TextBaseProps} from './types';
import {StyledLink} from './styles';
import {LinkProps as ReactLinkProps} from 'react-router-dom';

export type LinkProps = TextBaseProps & ReactLinkProps;

export const Link: React.FC<LinkProps> = (props) => {
  return <StyledLink {...props} />;
};
