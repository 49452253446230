import React, {useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {CSSTransitionProps} from 'react-transition-group/CSSTransition';
import styled from 'styled-components';
import {useIsomorphicLayoutEffect} from '../utils/hooks';
import {isClient} from '../utils/helpers';

// @types mistake
const endListener = () => {
  return;
};

const StyledFade = styled(CSSTransition)`
  transition: opacity ${({timeout}) => timeout}ms ease;
  // add ssr prop
  opacity: ${isClient() ? 0 : 1};

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
  }
`;

type FadeProps = Omit<CSSTransitionProps, 'addEndListener' | 'classNames'>;

// const initialIn = isServer() ? true : false;

export const Fade: React.FC<FadeProps> = ({children, timeout, in: inProp, ...props}) => {
  const [internalIn, setInternalIn] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setInternalIn(inProp);
  }, [inProp]);

  return (
    <StyledFade
      {...props}
      in={internalIn}
      appear
      timeout={timeout ?? 300}
      classNames="fade"
      addEndListener={endListener}>
      <div>{children}</div>
    </StyledFade>
  );
};
