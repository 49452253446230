import React from 'react';
import {IconSvg} from '../Icon/Svg';
import {StyledSvgIconProps} from '../Icon/Svg/types';
import {BaseLabel, LabelWithPoint, LabelRow} from './styles';
import {variant} from './types';

export const Label: React.FC<{variant?: variant; point?: boolean; width?: string; squared?: boolean}> = ({
  point,
  width,
  variant,
  squared,
  children,
}) => {
  return (
    <>
      {point ? (
        <LabelWithPoint variant={variant} width={width} squared={squared}>
          {children}
        </LabelWithPoint>
      ) : (
        <BaseLabel variant={variant} width={width} squared={squared}>
          {children}
        </BaseLabel>
      )}
    </>
  );
};

export const RowInfoLabel: React.FC<{
  iconType?: StyledSvgIconProps['type'];
  text?: string;
  onClose?: () => void;
}> = ({iconType, text, onClose}) => {
  return (
    <LabelRow>
      <IconSvg type={iconType || 'info-alt'} />
      <span>{text}</span>
      {onClose && (
        <button onClick={onClose}>
          <IconSvg type="close" />
        </button>
      )}
    </LabelRow>
  );
};
