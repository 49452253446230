import React from 'react';
import styled from 'styled-components';
import {TabMenuProps, TabType} from './types';

const Tabs = styled.div<{lineLocation: number}>`
  ${({theme}) => theme.font.primary.medium}
  font-size: 16px;
  line-height: 150%;
`;

const TabWrapper = styled.span<{
  active: boolean;
  leftIndent?: string;
  lineLocation: number;
}>`
  position: relative;
  cursor: pointer;
  margin-left: ${({leftIndent}) => leftIndent};
  color: ${({
    active,
    theme: {
      palette: {
        grayscale: {_5, _6},
      },
    },
  }) => (active ? _6 : _5)};
  &::before {
    position: absolute;
    content: '';
    height: 5px;
    background-color: ${({theme, active}) => (active ? theme.palette.grayscale._6 : 'transparent')};
    bottom: -${({lineLocation}) => lineLocation + 1}px;
    left: 0;
    right: 0;
  }
`;

const TabItem: React.FC<
  TabType & {
    lineLocation: number;
    activeTab?: string;
    onClick: () => void;
  }
> = ({activeTab, label, lineLocation, onClick, component: Component}) => {
  return (
    <TabWrapper onClick={onClick} active={activeTab == label} lineLocation={lineLocation}>
      {Component ? <Component>{label}</Component> : label}
    </TabWrapper>
  );
};

export const TertiaryTabMenu: React.FC<TabMenuProps> = ({tabs, lineLocationPx = 12, activeTab, setActiveTab}) => {
  const changeActiveTab = (label: string) => {
    const setActive = () => setActiveTab(label);
    return setActive;
  };
  return (
    <div>
      <Tabs lineLocation={lineLocationPx}>
        {tabs?.map((el) => {
          return (
            <TabItem
              key={el.label}
              label={el.label}
              lineLocation={lineLocationPx}
              activeTab={activeTab}
              component={el.component}
              onClick={changeActiveTab(el.label)}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
