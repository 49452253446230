import React from 'react';
import {PaymentPlan, UpdatePaymentPlan} from '../../../../types/payments';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {Table} from '../../../../ui-kit/Table';
import {Columns} from '../../../../ui-kit/Table/types';
import {ToolTips} from '../../../ToolTip';
import {useEditPaymentPlans} from '../hooks';
import {TableWrapper} from '../TableWrapper';
import {getColumns} from './columns';

interface Props {
  payments: PaymentPlan[];
  updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>;
}

export const SubscriptionTable: React.FC<Props> = ({payments, updatePaymentPlan}) => {
  const {changeValue, fields, isEditMode, setIsEdit, cancel, onSubmit} = useEditPaymentPlans(updatePaymentPlan);

  const currSign = payments?.[0]?.Currency?.sign;

  const {Cap, CF, Offering, PaymentPrice, RewardValue} = getColumns(changeValue, fields, isEditMode, currSign || '');

  const columns: Columns<PaymentPlan> = [
    {
      title: 'Offering',
      width: '145px',
      padLeft: '26px',
      render: Offering,
    },
    {
      titles: ['Monthly', 'Annual'],
      width: '245px',
      render: PaymentPrice,
    },
    {
      titles: ['Listing Cap', 'Resident Cap'],
      width: '245px',
      render: Cap,
    },
    {
      titles: ['+ Listing', '+ Resident'],
      width: '260px',
      render: RewardValue,
    },
    {
      title: 'CF',
      width: '42px',
      padLeft: '11px',
      render: CF,
    },
  ];

  return (
    <TableWrapper isEdit={isEditMode} setIsEdit={setIsEdit} title={'Subscription'} cancel={cancel} onSubmit={onSubmit}>
      <Table<PaymentPlan> variant={'secondary'} items={payments} columns={columns} height={'44px'} />
      <ToolTips
        text={
          'CF = Community Fee. Enabling this option will activate community fee for the selected subscription option. '
        }
        bottom={'16px'}
        left={'calc(100% + 14px)'}>
        <IconSvg type={'info'} width={'17px'} height={'17px'} viewBox={'0 0 20 20'} />
      </ToolTips>
    </TableWrapper>
  );
};
