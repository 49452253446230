import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {InnerInfoCard} from '../../../../ui-kit/Chat/PersonInfo/styles';

export const SkeletonInfoCard = () => {
  return (
    <InnerInfoCard>
      <Skeleton width={'40%'} />
      <Skeleton width={'80%'} />
    </InnerInfoCard>
  );
};

export const SkeletonShortInfoCard = () => {
  return (
    <InnerInfoCard $short>
      <Skeleton width={'40%'} />
      <Skeleton width={'80%'} />
    </InnerInfoCard>
  );
};
