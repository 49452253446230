import {gql} from 'apollo-boost';
import {Session} from '../types/auth';
import {UserFragment} from './fragments/user';

export const Login = gql`
  mutation LogIn($username: String!, $password: String!) {
    adminLogin(input: {username: $username, password: $password}) {
      clientMutationId
      viewer {
        sessionToken
        user {
          ...UserFragment
        }
      }
    }
  }
  ${UserFragment}
`;

export interface CurrentDataType {
  viewer: Session;
}

export const Logout = gql`
  mutation LogOut($id: String) {
    logOut(input: {clientMutationId: $id}) {
      clientMutationId
      ok
    }
  }
`;

export const Current = gql`
  query viewer {
    viewer {
      sessionToken
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export interface TLoginResponse {
  logIn: {
    viewer: Session;
  };
}

export interface TLoginRequest {
  username: string;
  password: string;
}

export const QueryLogin = gql`
  mutation LogIn($username: String!, $password: String!) {
    logIn(input: {username: $username, password: $password}) {
      clientMutationId
      viewer {
        sessionToken
        user {
          ...UserFragment
        }
      }
    }
  }
  ${UserFragment}
`;
