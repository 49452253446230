import React, {useRef} from 'react';
import {EmptyCard} from '../../../ui-kit/Chat/EmptyCard';
import {MessagePreviewCard} from '../../../ui-kit/Chat/Messages/MessagePreviewCard';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {SkeletonContactCard} from '../Sceleton/Card/ContactCard';
import {SkeletonLoader} from '../Sceleton/Loader';
import {InnerContacts, InnerEmptyCard} from '../styles';
import {TContact} from '../../../types/mesages';
import {route} from '../../../constants/routes';
import {getResize} from '../../../helpers/file';
import {textsMessages} from '../texts';
import {NeutralLink} from '../../common';
import {InfiniteScroll} from '../../common/InfiniteScroll';

type TProps = {
  contactsList?: TContact[] | null;
  setActiveContact: (user: TContact | null) => void;
  contactsLoading: boolean;
  activeContact?: TContact | null;
  hasMore: boolean;
  fetchMore: (v?: number) => void;
};

export const ContactListContent: React.FC<TProps> = ({
  contactsList,
  contactsLoading,
  activeContact,
  hasMore,
  fetchMore,
}) => {
  const scrollParentRef = useRef<HTMLDivElement>(null);
  const handleGetScrollParent = () => {
    return scrollParentRef.current;
  };

  const handleFetchMore = () => fetchMore();
  if (!contactsList?.length && !contactsLoading) {
    return (
      <InnerEmptyCard>
        <EmptyCard
          title={textsMessages.emptyState.messaging.text1}
          description={textsMessages.emptyState.messaging.text2}
          imageSvg={
            <IconSvg stroke={'none'} type={'msg-sent-light'} width={'200px'} height={'200px'} viewBox="0 0 200 200" />
          }
        />
      </InnerEmptyCard>
    );
  }

  return (
    <InnerContacts ref={scrollParentRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleFetchMore}
        hasMore={hasMore}
        loader={!contactsList?.length && contactsLoading ? <SkeletonLoader card={SkeletonContactCard} /> : <div />}
        useWindow={false}
        getScrollParent={handleGetScrollParent}>
        {contactsList?.map((it) => {
          return (
            <NeutralLink key={it.objectId} to={route.messages.get({contactId: it.objectId})}>
              <MessagePreviewCard
                messageDate={it.dataMsg?.date}
                userName={it.name}
                bordered={true}
                fullWidth={true}
                userAvatar={getResize(it.avatar, 'sm')}
                onlineStatus={it.onlineStatus}
                messageText={it.dataMsg?.text}
                unSeenedMsg={it.dataMsg?.haveUnseenMsg}
                isActive={it.objectId === activeContact?.objectId}
              />
            </NeutralLink>
          );
        })}
      </InfiniteScroll>
    </InnerContacts>
  );
};
