import React from 'react';
import {IDataHookMessage, TContact, TMessage} from '../../../types/mesages';
import {ChatArea} from './ChatArea';
import {HeaderMainCard, InnerBodyCard, InnerChat, SubInnerHeaderCard, TitleHeaderCard} from '../styles';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {ContextMenuWrapper} from './styles';

type TProps = {
  firstName?: string;
  lastName?: string;
  msgs?: TMessage[] | null;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  dataHookMessage: IDataHookMessage;
  activeContact?: TContact | null;
  isOpenDialog: boolean;
  isArchivedDialog: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  archiveDialog: () => void;
  unarchiveDialog: () => void;
};

export const MessagesChat: React.FC<TProps> = ({
  activeContact,
  dataHookMessage,
  msgs,
  viewerId,
  viewerData,
  isOpenDialog,
  isArchivedDialog,
  closeDialog,
  openDialog,
  archiveDialog,
  unarchiveDialog,
}) => {
  const options: Array<MenuItemType> = [
    ...(isOpenDialog
      ? [
          {
            title: 'Mark as closed',
            onClick: closeDialog,
          },
        ]
      : [
          {
            title: 'Mark as open',
            onClick: openDialog,
          },
        ]),
    ...(isArchivedDialog
      ? [
          {
            title: 'Un archive',
            onClick: unarchiveDialog,
          },
        ]
      : [
          {
            title: 'Archive',
            onClick: archiveDialog,
          },
        ]),
  ];
  return (
    <InnerChat>
      <HeaderMainCard>
        <SubInnerHeaderCard>
          {activeContact?.name && <TitleHeaderCard>{activeContact?.name}</TitleHeaderCard>}
        </SubInnerHeaderCard>
        {activeContact?.name && (
          <ContextMenuWrapper>
            <MenuButton item={activeContact} options={options} menu={Menu} />
          </ContextMenuWrapper>
        )}
      </HeaderMainCard>

      <InnerBodyCard>
        <ChatArea
          messages={msgs}
          dataHookMessage={dataHookMessage}
          contact={activeContact}
          viewerId={viewerId}
          viewerData={viewerData}
        />
      </InnerBodyCard>
    </InnerChat>
  );
};
