import React from 'react';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu as MenuTable} from '../../ui-kit/Menu';
import {User as UserType} from '../../types/user';
import {WrapperContent} from './styles';
import {Text} from '../../ui-kit/Typography/Text';
import {BoldText} from '../Communities/styles';
import {updateUser} from './table';
import {convertCentToDollar} from '../../helpers/calculate';

export const getMenu = (blockUser: updateUser, activeUser: updateUser) => {
  const Menu = (item: UserType) => {
    const TableOptions: MenuItemType[] = [
      {
        title: 'View',
        to: (id) => `/member/${id}`,
      },
      {
        title: 'Send Message',
        to: (id) => `/messages/${id}`,
      },
      // {
      //   title: 'Access',
      // },
      // {
      //   title: 'Edit',
      // },
      {
        render: 'line',
      },
      {
        title: 'Block',
        render: 'danger',
        onClick: () => blockUser(item),
        isHidden: item.status === 'block',
      },
      {
        title: 'Active',
        render: 'successful',
        onClick: () => activeUser(item),
        isHidden: item.status === 'active',
      },
    ];
    return <MenuButton item={item} menu={MenuTable} options={TableOptions} />;
  };
  return Menu;
};

export const Members = (item: UserType) => {
  return (
    <WrapperContent>
      <Text color={'quartdecimal'} variant={'quaternary'} size={14} weight={'medium'}>
        {item.firstName} {item.lastName}
      </Text>
      <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
        {item.email}
      </Text>
    </WrapperContent>
  );
};

export const Transactions = (item: UserType) => {
  return (
    <WrapperContent>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Total: {item.Total?.count}
      </Text>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Active: -
      </Text>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Lent: {item.Lent?.count}
      </Text>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Borrowed: {item.Borrowed?.count}
      </Text>
    </WrapperContent>
  );
};

export const Communities = (item: UserType) => {
  return (
    <WrapperContent>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Admin: {item.Communities?.edges?.reduce((count, el) => count + el.node.Admins.count, 0)}
      </Text>
      <Text color={'tertdecimal'} variant={'quaternary'}>
        Joined: {item.Communities?.edges?.reduce((count, el) => count + el.node.Residents.count, 0)}
      </Text>
    </WrapperContent>
  );
};

export const Spent = (item: UserType) => {
  const currency = item.Communities?.edges[0]?.node.Subscr?.PaymentPlan?.Currency;

  return (
    <WrapperContent alignRight>
      <BoldText>
        {currency?.sign || '$'}
        {convertCentToDollar(item.spent) || 0}
      </BoldText>
      <Text variant="quaternary">{currency?.code || 'USD'}</Text>
    </WrapperContent>
  );
};

export const Earned = (item: UserType) => {
  const currency = item.Communities?.edges[0]?.node.Subscr?.PaymentPlan?.Currency;

  return (
    <WrapperContent alignRight>
      <BoldText>
        {currency?.sign || '$'}
        {convertCentToDollar(item.earned) || 0}
      </BoldText>
      <Text variant="quaternary">{currency?.code || 'USD'}</Text>
    </WrapperContent>
  );
};
