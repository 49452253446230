import React from 'react';
import styled from 'styled-components';
import {PersonInfo} from '../../../ui-kit/Chat/PersonInfo';
import {IPersonInfo, TCommunityItem, TInsightItem, TPersonData} from '../../../ui-kit/Chat/PersonInfo/types';
import {PersonInfoLoader} from '../Sceleton/PersonInfoLoader';

const labels: IPersonInfo['labels'] = {
  insights: 'Insights',
  person: 'Person',
  location: 'Location',
  communities: 'Communities',
};

export const PersonInfoWrapper = styled.div`
  width: 25%;
  max-height: 100%;
  overflow-y: auto;
`;

interface IProps {
  personInfoData?: {
    insights?: Array<TInsightItem>;
    communities?: Array<TCommunityItem>;
    user?: TPersonData;
  };
  loading?: boolean;
}

export const ContactInfo: React.FC<IProps> = ({personInfoData}) => {
  if (!personInfoData) return null;

  const {insights, user, communities} = personInfoData;
  if (!insights || !user || !communities)
    return (
      <PersonInfoWrapper>
        <PersonInfoLoader />
      </PersonInfoWrapper>
    );

  return (
    <PersonInfoWrapper>
      <PersonInfo labels={labels} person={user} communities={communities} insights={insights} />
    </PersonInfoWrapper>
  );
};
