import React, {useState, useEffect, useMemo} from 'react';
import throttle from 'lodash.throttle';

import {AddButton} from './components/AddButton';
import {CheckListButton} from './components/CheckListButton';
import {Notification} from './components/Notification';
import {UserInfo} from './components/UserInfo';
import {
  HeaderWrapper,
  HeaderMenu,
  CenteringWrapper,
  MenuIconSvg,
  getNavStrokeColor,
  colors,
  Header,
  LogOutBtn,
  TopContent,
  WrapperHeaderWeb,
  MenuHeaderText,
  MenuHeaderDropdownIcon,
  HiddenTopBlock,
  GoBackWrapper,
  GoBackText,
  HeaderRightSide,
  CommNameLabel,
  SettingsButton,
  Hidden,
} from './styles';
import {variant, NavHeaderProps} from './types';
import {IconSvg} from '../Icon/Svg';
import {Popup} from '../Popup';
import {Media} from '../theme';
import {Link} from '../Typography/Link';

const HeaderAdmin: React.FC<NavHeaderProps> = ({userinfo, logout, variant, logoutText = 'Log out'}) => {
  return (
    <Header>
      <UserInfo variant={variant} {...userinfo} />
      <LogOutBtn onClick={logout}>{logoutText}</LogOutBtn>
    </Header>
  );
};

const HeaderWeb: React.FC<NavHeaderProps> = ({
  isChecklistCompleted,
  checklistButtonName,
  activeStepsComp,
  openProgressMenuHandler,
  progressMenuOpen,
  plusIcon,
  userinfo,
  mode,
  variant,
  homePathUser,
  $isNotices,
  onClickNotifications,
  menuItemsMobile,
  currentMenuName,
  langSwitch,
  openProfMenu,
  openAddMenu,
  mobNavTitle,
  goBack,
  alias,
  communityName,
  openCD,
  showCommDropdown,
  onOpenSetting,
  isVisibleSettings,
}) => {
  const currentItem = useMemo(
    () => menuItemsMobile?.find((el) => el.path === currentMenuName),
    [menuItemsMobile, currentMenuName],
  );
  const showArrow = showCommDropdown && !!communityName;
  const openCommunitiesDropdown = () => {
    if (!showArrow) return;
    openCD?.();
  };
  return (
    <WrapperHeaderWeb>
      <HiddenTopBlock mq={Media.up.l}>
        {goBack?.show ? (
          <GoBackWrapper onClick={goBack.onClick}>
            <IconSvg type={'arrow-left'} />
            <GoBackText>{goBack?.text}</GoBackText>
          </GoBackWrapper>
        ) : (
          <div>
            <TopContent $isUser={!communityName} onClick={openCommunitiesDropdown}>
              {mobNavTitle || currentItem?.content || userinfo?.name}
              {showArrow && <IconSvg type="chevrone-down" />}
            </TopContent>
            <CommNameLabel>{communityName}</CommNameLabel>
          </div>
        )}
      </HiddenTopBlock>
      <Header>
        {/*<Dashboard mode={mode} dashboardLink={dashBoardLink} />*/}
        <div>
          {isChecklistCompleted && (
            <CheckListButton
              checklistMenuOpen={progressMenuOpen}
              openChecklistMenu={openProgressMenuHandler}
              activeStepsComp={activeStepsComp}
              name={checklistButtonName}
            />
          )}
        </div>
        <HeaderRightSide>
          {alias && <AddButton openAddMenu={openAddMenu} plusIcon={plusIcon} />}
          {isVisibleSettings && (
            <Hidden mq={Media.up.l}>
              <SettingsButton onClick={onOpenSetting}>
                <IconSvg type={'settings-3'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />
              </SettingsButton>
            </Hidden>
          )}
          <UserInfo variant={variant} onClick={openProfMenu} homePathUser={homePathUser} {...userinfo} />
          <Notification mode={mode} isNotices={$isNotices} onClickNotifications={onClickNotifications} />
          {langSwitch}
          {/*<LogOutBtn onClick={logout}>{logoutText}</LogOutBtn>*/}
        </HeaderRightSide>
      </Header>
    </WrapperHeaderWeb>
  );
};

const headers: Record<variant, React.FC<NavHeaderProps>> = {
  admin: HeaderAdmin,
  web: HeaderWeb,
};

export const NavHeader: React.FC<NavHeaderProps> = (props) => {
  const {
    closeProgressMenuHandler,
    progressMenuOpen,
    progressMenuComponent,
    addMenuComponent,
    addMenuOpen,
    openAddMenuHandler,
    closeAddMenuHandler,
    variant,
    plusIcon,
    mode,
    $isOpenNav,
    head,
    headerComponent,
    currentMenuName,
    dashBoardLink,
    profMenuComponent,
    commMenuComponent,
    goBack,
    alias,
    communityLoading,
    onOpenSetting,
    isVisibleSettings,
  } = props;
  const Header = headerComponent || headers[variant];
  const isActive = currentMenuName === head.path || currentMenuName === head.to;
  const [profMenuOpen, setProfMenuOpen] = useState(false);
  const [commMenuOpen, setCommMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const commClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    commMenuComponent && setCommMenuOpen(true);
  };
  useEffect(() => {
    const handleScroll = throttle(() => {
      window?.pageYOffset > 30 ? setScrolled(true) : setScrolled(false);
    }, 500);
    window?.addEventListener('scroll', handleScroll);
    return () => window?.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <HeaderWrapper viewMode={props.viewMode} $isHidden={props.$isHidden} $scrolled={scrolled}>
      <HeaderMenu $isOpenNav={$isOpenNav} variant={variant}>
        <Link to={head.to || ''} color={isActive ? 'nonary' : colors[variant]} variant="tertiary" size={16}>
          <CenteringWrapper>
            {communityLoading ? null : (
              <>
                <MenuIconSvg
                  stroke={isActive ? 'aqua' : getNavStrokeColor(variant, mode)}
                  fill={isActive ? 'aqua-2' : undefined}
                  type={head?.type}
                  marginRight="7px"
                />
                <MenuHeaderText>{head.content}</MenuHeaderText>
                {commMenuComponent && (
                  <MenuHeaderDropdownIcon onClick={commClick}>
                    <IconSvg type={'chevrone-down'} />
                  </MenuHeaderDropdownIcon>
                )}
              </>
            )}
          </CenteringWrapper>
        </Link>
      </HeaderMenu>
      <Header
        {...props}
        openProfMenu={() => setProfMenuOpen(true)}
        openAddMenu={openAddMenuHandler}
        variant={variant}
        dashBoardLink={dashBoardLink}
        goBack={goBack}
        plusIcon={plusIcon}
        alias={alias}
        onOpenSetting={onOpenSetting}
        isVisibleSettings={isVisibleSettings}
      />
      {progressMenuOpen && (
        <Popup isOpen={progressMenuOpen} onClose={closeProgressMenuHandler}>
          {progressMenuComponent}
        </Popup>
      )}
      {profMenuOpen && (
        <Popup isOpen={profMenuOpen} onClose={() => setProfMenuOpen(false)}>
          {profMenuComponent}
        </Popup>
      )}
      {addMenuOpen && (
        <Popup isOpen={addMenuOpen} onClose={closeAddMenuHandler}>
          {addMenuComponent}
        </Popup>
      )}
      {commMenuOpen && (
        <Popup isOpen={commMenuOpen} onClose={() => setCommMenuOpen(false)}>
          {commMenuComponent}
        </Popup>
      )}
    </HeaderWrapper>
  );
};
