import styled, {css} from 'styled-components';

const linePseudoElement = css`
  content: '';
  right: 0;
  left: 0;
  height: 1px;
  background-color: ${({theme}) => theme.palette.text.duodecimal};
  position: absolute;
`;

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;

  &,
  & * {
    box-sizing: border-box;
  }
`;

export const ConfigWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.primary};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 25px;

  & :is(svg) {
    cursor: pointer;
  }
`;

export const ConfigContent = styled.div`
  padding: 15px 10px;
  position: relative;

  &::before {
    ${linePseudoElement};
    top: 0;
  }

  &::after {
    ${linePseudoElement};
    bottom: 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const CheckboxArea = styled.div`
  padding: 15px 0;
  display: grid;
  grid-template-rows: repeat(9, 1fr);
  grid-auto-flow: column dense;
  gap: 15px;
`;

export const PlanOptionWrapper = styled.div`
  & > * {
    margin-right: 5px;
  }
`;

export const SubmitWrapper = styled.div`
  width: 100%;
  //display: flex;
  //justify-content: space-between;
  padding: 20px 0 10px;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  align-content: space-between;
`;
