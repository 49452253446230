import React from 'react';
import {MenuItemType, mode, variant} from '../types';
import {
  colors,
  IsNoticesLbl,
  MenuIconSvg,
  MenuItemText,
  Notices,
  NoticesIconWrapper,
  getNavStrokeColor,
  StyledLink,
  MenuContentText,
  MenuRow,
} from '../styles';
import {Link, LinkProps} from '../../Typography/Link';

const MenuItemWrapper: React.FC<LinkProps> = ({to, ...props}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return to ? <Link to={to || ''} {...props} /> : <StyledLink {...props} />;
};

export const MenuItem: React.FC<
  MenuItemType & {$isOpenNav: boolean; variant: variant; currentMenuName: string; mode?: mode}
> = ({content, type, $isOpenNav, variant, mode, to, path, currentMenuName, notices, onClick, children}) => {
  const isActive = currentMenuName === path;

  if (!content) return null;

  return (
    <li>
      <MenuItemWrapper
        to={to || ''}
        onClick={onClick}
        color={isActive ? 'nonary' : colors[variant]}
        variant="tertiary"
        size={16}>
        <MenuRow $isActive={isActive}>
          {$isOpenNav ? (
            <div>
              <MenuIconSvg
                viewBox="0 0 26 26"
                fill={isActive ? 'aqua-2' : undefined}
                stroke={isActive ? 'aqua' : getNavStrokeColor(variant, mode)}
                type={type}
              />
              <MenuItemText>
                <MenuContentText>{content}</MenuContentText>
                <Notices value={notices} />
              </MenuItemText>
            </div>
          ) : (
            <NoticesIconWrapper>
              <MenuIconSvg
                viewBox="0 0 26 26px"
                fill={isActive ? 'aqua-2' : undefined}
                stroke={isActive ? 'aqua' : getNavStrokeColor(variant, mode)}
                type={type}
              />
              {notices && <IsNoticesLbl top="2px" />}
            </NoticesIconWrapper>
          )}
        </MenuRow>
      </MenuItemWrapper>
      {children}
    </li>
  );
};
