import {QueryResult, useMutation, useQuery} from '@apollo/react-hooks';
import {useEffect, useMemo, useState} from 'react';
import {
  QueryGetUserCommunities,
  getCommunity,
  getCommunityDashboard,
  IGetCommunitiesRequest,
  IGetCommunitiesResponse,
  SearchCommunity,
  updateCommunity as updateCommunityQuery,
} from '../queries/communities';
import {isLoading} from '../states/load';
import {Communities, Community, CommunityDashboard, ViewCommunityType} from '../types/communities';
import {useGetCard} from './cards';
import {usePagination} from './pagination';
import {useSetRecoilState} from 'recoil';
import {GQLUpdateCommunityFieldsInput, GQLCommunityWhereInput} from '../graphql.schema';
import {CommunitiesFragment} from '../queries/fragments/communities';
import {PaymentPlanName} from '../types/payments';
import {fromConnection} from '../helpers/parse';

type communityType = 'pro' | 'basic' | 'starter' | 'free' | 'inactive';

const communitiesWhere: Partial<Record<communityType, GQLCommunityWhereInput>> = {
  pro: {
    Subscr: {
      have: {
        PaymentPlan: {
          have: {OR: [{name: {equalTo: PaymentPlanName.proUSA}}, {name: {equalTo: PaymentPlanName.proCAN}}]},
        },
      },
    },
  } as GQLCommunityWhereInput,
  basic: {
    Subscr: {
      have: {
        PaymentPlan: {
          have: {OR: [{name: {equalTo: PaymentPlanName.basicUSA}}, {name: {equalTo: PaymentPlanName.basicCAN}}]},
        },
      },
    },
  } as GQLCommunityWhereInput,
  starter: {
    Subscr: {
      have: {
        PaymentPlan: {
          have: {OR: [{name: {equalTo: PaymentPlanName.starterUSA}}, {name: {equalTo: PaymentPlanName.starterCAN}}]},
        },
      },
    },
  } as GQLCommunityWhereInput,
  free: {
    Subscr: {
      have: {
        PaymentPlan: {
          have: {OR: [{name: {equalTo: PaymentPlanName.free}}]},
        },
      },
    },
  } as GQLCommunityWhereInput,
  inactive: {status: {equalTo: 'disabled'}} as GQLCommunityWhereInput,
};

export const useGetCommunities = (type?: communityType): {data?: Community[]; count: number} => {
  const [communities, setData] = useState<Community[] | undefined>();
  const GetCard = useGetCard();
  const setIsLoading = useSetRecoilState(isLoading);

  const {page, first_skip} = usePagination();

  const getCard = async (community: Community): Promise<Community> => {
    try {
      const {data} = await GetCard({variables: {id: community.Owner?.objectId}});
      const defaultCard = data?.paymentGetUserCards?.defaultCardId;
      return {...community, infoCard: data?.paymentGetUserCards?.cards?.find((el) => el.id === defaultCard)};
    } catch (e) {
      console.log(e);
    }
    return {...community};
  };

  const {data, fetchMore, loading}: QueryResult = useQuery(QueryGetUserCommunities, {
    variables: {
      where: type && communitiesWhere[type],
      ...first_skip,
    },
  });

  setIsLoading(loading);

  const fetchMoreCommunities = async () => {
    try {
      await fetchMore({
        variables: {
          where: type && communitiesWhere[type],
          ...first_skip,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreCommunities();
  }, [page]);

  const comm = useMemo(
    () => (data as Communities)?.communities?.edges?.map((el: {node: Community}) => el.node),
    [data],
  );

  useEffect(() => {
    setData(comm);
  }, [data]);

  useEffect(() => {
    const commWithCard = comm?.map(getCard);
    commWithCard &&
      commWithCard.length > 0 &&
      Promise.all(commWithCard).then((data) => {
        setData(data);
      });
  }, [data]);

  return {
    data: communities,
    count: data?.communities?.count || 0,
  };
};

export const useGetCommunity = (id: string): {data?: ViewCommunityType} => {
  const [getDashboard, {data: dashboard}] = useMutation<{managerDashboard: CommunityDashboard}>(
    getCommunityDashboard,
    {},
  );
  const {data, loading} = useQuery<{community: Community}>(getCommunity, {variables: {id: id}});

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  useEffect(() => {
    getDashboard({variables: {id}});
  }, []);

  return {data: {...data?.community, ...dashboard?.managerDashboard} as ViewCommunityType};
};

export const useUpdateCommunity = () => {
  const [updateCommunity, {loading, client}] = useMutation<{community: Community}>(updateCommunityQuery);

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const UpdateCommunity = async (community: Community, fields: GQLUpdateCommunityFieldsInput | any) => {
    const {data} = await updateCommunity({variables: {id: community.objectId, fields: fields}});
    data &&
      client.writeFragment({
        id: `Community:${community.id}`,
        fragment: CommunitiesFragment,
        data: data.community,
      });
  };

  const verifyCommunity = (community: Community) => UpdateCommunity(community, {isVerified: true});
  const disableCommunity = (community: Community) =>
    UpdateCommunity(community, {status: 'disabled'} as GQLUpdateCommunityFieldsInput);
  const activeCommunity = (community: Community) =>
    UpdateCommunity(community, {status: 'active'} as GQLUpdateCommunityFieldsInput);

  return {updateCommunity: UpdateCommunity, verifyCommunity, disableCommunity, activeCommunity};
};

export const useSearchCommunity = (constraints?: Record<string, any>) => {
  const {refetch} = useQuery<Communities>(SearchCommunity, {
    skip: true,
    fetchPolicy: 'cache-and-network',
  });

  return async (name: string) => {
    const response = await refetch({
      where: {
        name: {
          matchesRegex: name,
          options: 'i',
        },
        ...(constraints ?? {}),
      },
    });

    return response?.data?.communities?.edges?.map((e) => e.node);
  };
};

export const useGetUserCommunities = (userId?: string) => {
  const {data} = useQuery<IGetCommunitiesResponse, IGetCommunitiesRequest>(QueryGetUserCommunities, {
    skip: !userId,
    variables: {
      where: {
        OR: [
          {
            Residents: {
              have: {
                objectId: {equalTo: userId},
              },
            },
          },
          {
            Owner: {
              have: {
                objectId: {equalTo: userId},
              },
            },
          },
          {
            Admins: {
              have: {
                objectId: {equalTo: userId},
              },
            },
          },
        ],
      },
    },
  });

  return {
    data: fromConnection(data?.communities),
  };
};
