import React from 'react';
import {IInfoCard, IProfileCard} from './types';
import {
  ArrowWrapper,
  CardTitle,
  InnerInfoCard,
  InnerLabel,
  InnerProfileCard,
  InnerSection,
  NeutralLink,
  SectionLabel,
  SectionWrapper,
  WrapperProfileCard,
} from './styles';
import {Avatar} from '../../Avatar/Avatar';
import {Label} from '../../Labels';
import {IconSvg} from '../../Icon/Svg';
import {Text} from '../../Typography/Text';

export const Section: React.FC<{label: string}> = ({label, children}) => {
  return (
    <SectionWrapper>
      <SectionLabel>{label}</SectionLabel>
      <InnerSection>{children}</InnerSection>
    </SectionWrapper>
  );
};

export const ProfileCard: React.FC<IProfileCard> = ({
  link,
  name,
  label,
  labelVariant,
  members,
  avatar,
  listings,
  arrow,
}) => {
  return (
    <OptionalLink to={link}>
      <WrapperProfileCard>
        <Avatar src={avatar} size={'sm'} />
        <InnerProfileCard>
          <CardTitle>{name}</CardTitle>
          {label && <Label variant={labelVariant}>{label}</Label>}
          {Boolean(listings) && (
            <InnerLabel>
              <IconSvg type={'Box'} stroke={'aqua'} viewBox={'0 0 24 24'} width={'14px'} height={'14px'} /> {listings}
            </InnerLabel>
          )}
          {Boolean(members) && (
            <InnerLabel>
              <IconSvg type={'people'} stroke={'gray-2'} viewBox={'0 0 24 24'} width={'14px'} height={'14px'} />{' '}
              {members}
            </InnerLabel>
          )}
        </InnerProfileCard>
        {arrow && (
          <ArrowWrapper>
            <IconSvg type={'chevrone-right'} viewBox={'0 0 24 24'} width={'24px'} height={'24px'} strokeWidth={1} />
          </ArrowWrapper>
        )}
      </WrapperProfileCard>
    </OptionalLink>
  );
};

export const InfoCard: React.FC<IInfoCard> = ({label, value, short}) => {
  return (
    <InnerInfoCard $short={short}>
      <Text color={'septenary'} weight={'medium'} size={12}>
        {label}
      </Text>
      <Text color={'octonary'} weight={'medium'} size={14}>
        {value}
      </Text>
    </InnerInfoCard>
  );
};

const OptionalLink: React.FC<{to?: string}> = ({to, children}) => {
  return to ? <NeutralLink to={to}>{children}</NeutralLink> : <>{children}</>;
};
