import React from 'react';
import {TContact} from '../../../types/mesages';
import {ContactListContent} from './ContactListContent';
import {textsMessages} from '../texts';
import {
  ContactsListWrapper,
  HeaderSideCard,
  InnerBodyCard,
  MenuButtonWrapper,
  SubTitleHeaderMsgList,
  TitleHeaderCard,
} from '../styles';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {EFilterOptions} from '../../../hooks/messages';
import {byCond} from '../../../helpers/object';

type TProps = {
  count?: number;
  contactsList?: TContact[] | null;
  setActiveContact: (user: TContact | null) => void;
  contactsLoading: boolean;
  activeContact?: TContact | null;
  filterContacts: (type: EFilterOptions) => void;
  currentFilter: EFilterOptions;
  hasMore: boolean;
  fetchMore: (v?: number) => void;
};

export const ContactsList: React.FC<TProps> = ({
  contactsList,
  contactsLoading,
  setActiveContact,
  activeContact,
  count,
  filterContacts,
  currentFilter,
  hasMore,
  fetchMore,
}) => {
  const getFilterFn = (type: EFilterOptions) => () => filterContacts?.(type);

  const options: Array<MenuItemType> = [
    ...byCond(currentFilter !== EFilterOptions.all, {
      title: 'Show all',
      onClick: getFilterFn(EFilterOptions.all),
    }),
    ...byCond(currentFilter !== EFilterOptions.open, {
      title: 'Show open only',
      onClick: getFilterFn(EFilterOptions.open),
    }),
    ...byCond(currentFilter !== EFilterOptions.archived, {
      title: 'Show archive',
      onClick: getFilterFn(EFilterOptions.archived),
    }),
    ...byCond(currentFilter !== EFilterOptions.unread, {
      title: 'Show unread only',
      onClick: getFilterFn(EFilterOptions.unread),
    }),
  ];
  return (
    <ContactsListWrapper>
      <HeaderSideCard>
        <TitleHeaderCard>{textsMessages.messaging}</TitleHeaderCard>
        {!!count && (
          <SubTitleHeaderMsgList>
            {count} {textsMessages.messages}
          </SubTitleHeaderMsgList>
        )}
        <MenuButtonWrapper>
          <MenuButton
            item={{objectId: 'sadf'}}
            menu={Menu}
            options={options}
            iconData={{
              type: 'controls-alt',
              width: '24px',
              height: '24px',
              viewBox: '0 0 24 24',
              stroke: 'gray-2',
            }}
          />
        </MenuButtonWrapper>
      </HeaderSideCard>
      <InnerBodyCard>
        <ContactListContent
          contactsList={contactsList}
          setActiveContact={setActiveContact}
          contactsLoading={contactsLoading}
          activeContact={activeContact}
          hasMore={hasMore}
          fetchMore={fetchMore}
        />
      </InnerBodyCard>
    </ContactsListWrapper>
  );
};
