import {gql} from 'apollo-boost';
import {DashboardFragment, residentDashboardFragment} from './fragments/dashboard';
import {residentDashboard as residentDashboardType} from '../types/dashboard';

export const getDashboard = gql`
  mutation adminDashboard($startDate: String, $endDate: String, $location: String) {
    adminDashboard(input: {startDate: $startDate, endDate: $endDate, locationId: $location}) {
      ...DashboardFragment
    }
  }
  ${DashboardFragment}
`;

export const residentDashboard = gql`
  mutation residentDashboard($id: ID) {
    residentDashboard(input: {userId: $id}) {
      ...residentDashboardFragment
    }
  }
  ${residentDashboardFragment}
`;

export interface ResidentDashboardResponceType {
  residentDashboard: residentDashboardType;
}
