import {gql} from '@apollo/client';
import {AppFileFragment} from './file';

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    objectId
    createdAt
    updatedAt
    Author {
      objectId
      firstName
      lastName
      onlineDate
      Avatar {
        ...AppFileFragment
      }
    }
    ShowTo {
      ... on Element {
        value
      }
    }
    text
    type
    Attachments {
      ... on Element {
        value
      }
    }
    Seen {
      ... on Element {
        value
      }
    }
  }
  ${AppFileFragment}
`;
