import React from 'react';
import {Date, TitleWrapper} from '../styles';
import {Column, Columns, ItemType, renderFunctionsType, TableProps} from '../types';
import {color as menuColor} from '../../Icon/Svg/types';
import {getFormatDate} from '../../utils/Date';
import {ClmnTitle, ColumnTitles, TableHeaderRow, TableItem, TableWrapper, Td} from './styles';

const renderFunctions: renderFunctionsType = {
  date: getFormatDate,
};

const getRender = <T,>(item: ItemType<T>, options: Column<ItemType<T>>) => {
  const {render, dataIndex} = options;
  if (!render && dataIndex) return item[dataIndex];
  if (typeof render === 'function') return render(item);
  if (render === 'date' && dataIndex && typeof item[dataIndex] === 'string') {
    return <Date>{renderFunctions[render](item[dataIndex] as unknown as string)}</Date>;
  }
  return;
};

interface ItemProps<T> {
  item: ItemType<T>;
  columns: Columns<ItemType<T>>;
  height?: string;
  colorBtn?: menuColor;
}

const Item = <T,>({item, height, columns}: ItemProps<T>) => {
  return (
    <TableItem height={height}>
      {columns.map((el, i) => (
        <Td marked={el.marked} key={item.objectId + (el.title || i)}>
          {getRender<T>(item, el)}
        </Td>
      ))}
    </TableItem>
  );
};

const CoulumnTitle = <T,>({column}: {column: Column<T>}) => {
  return column.titles ? (
    <TitleWrapper {...column}>
      <ColumnTitles>
        {column.titles.map((title, idx) => (
          <ClmnTitle key={`ClmnTitle-${idx}`}>{title}</ClmnTitle>
        ))}
      </ColumnTitles>
    </TitleWrapper>
  ) : (
    <TitleWrapper {...column}>{column.title}</TitleWrapper>
  );
};

export const SecondaryTable = <T,>({items, columns, height, headerColor, ...props}: TableProps<ItemType<T>>) => {
  return (
    <TableWrapper>
      <TableHeaderRow color={headerColor}>
        {columns.map((el, idx) => (
          <CoulumnTitle column={el} key={`CoulumnTitle-${idx}`} />
        ))}
      </TableHeaderRow>
      {items?.map((i) => (
        <Item item={i} key={i.objectId} height={height} columns={columns} {...props} />
      ))}
    </TableWrapper>
  );
};
