import React from 'react';
import {Dashboard} from '../components/Dashboard';
import {useGetDashboard} from '../hooks/dashboard';

const DashboardContainer: React.FC = () => {
  const {data} = useGetDashboard();

  return <Dashboard data={data} />;
};

export default DashboardContainer;
