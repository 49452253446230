import styled, {css} from 'styled-components';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';
import {Button} from '../../ui-kit/Button/Button';

const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UploadCVSButton = styled(Button).attrs({rounded: true, ghost: true, color: '#4F4F4F'})`
  margin-top: 36px;
  gap: 24px;
  justify-content: space-around;
  width: initial;
  padding: 0 18px 0 0;
  color: ${({theme}) => theme.palette.button.secondary};

  & .button-value {
    ${fontPrimaryReg};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 150%;
    font-weight: 500;
    margin: 0;
  }
`;

export const WrapperContent = styled.div<{alignRight?: boolean}>`
  display: flex;
  height: 39px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: ${({alignRight}) => (alignRight ? 'flex-end' : 'flex-start')};
`;

export const BoldText = styled(Text).attrs({variant: 'quaternary', weight: 'medium', size: 14, color: 'quartdecimal'})`
  position: relative;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CommunityLabel = styled(IconSvg).attrs({width: '16px', height: '16px', viewBox: '0 0 16 16'})`
  position: absolute;
  left: -21px;
`;

export const CommunityLabelVerified = styled(CommunityLabel).attrs({stroke: 'green', type: 'verified'})``;

export const CommunityLabelSpam = styled(CommunityLabel).attrs({stroke: 'red', type: 'spam'})``;
