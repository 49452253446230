import React, {ReactNode} from 'react';
import {ProfileIntro} from '../../../ui-kit/ProfileIntro';
import {Card} from '../../../ui-kit/Cards';
import {User} from '../../../types/user';
import {residentDashboard} from '../../../types/dashboard';
import {Location} from '../../../types/location';
import {Currency} from '../../../types/payments';
import {convertCentToDollar} from '../../../helpers/calculate';
import {DisabledWrapper} from '../../Layout/styles';
import {ViewHeader} from '../../HeaderViewPage';
import {CardsWrapper, PageWrapper, ProfileIntroWrapper} from './styles';

type render = (item: residentDashboard, el: Field, currency?: Currency) => ReactNode;

interface Field {
  title: string;
  dataIndex: keyof residentDashboard;
  render?: render | 'value' | 'stars';
  link?: {
    url: string;
    title: string;
    dataIndex?: string;
  };
}

export const fields: Field[] = [
  {
    title: 'Total listings',
    dataIndex: 'countListings',
    // link: {
    //   title: 'Post your first item',
    //   url: '',
    // },
  },
  {
    title: 'Total value of items',
    dataIndex: 'countItemValue',
    render: 'value',
  },
  {
    title: 'Total transactions (with your listings)',
    dataIndex: 'countOwnTransactions',
  },
  {
    title: 'Total transactions (with other’s listings)',
    dataIndex: 'countOthersTransactions',
  },
  {
    title: 'Total money earned',
    dataIndex: 'countEarned',
    render: 'value',
  },
  {
    title: 'Total money spent',
    dataIndex: 'countSpent',
    render: 'value',
    // link: {
    //   title: 'Borrow items',
    //   url: '',
    // },
  },
  {
    title: 'Average rating received',
    dataIndex: 'averageRatingReceived',
    render: 'stars',
  },
  {
    title: 'Average rating given',
    dataIndex: 'averageRatingGiven',
    render: 'stars',
    // link: {
    //   title: 'Borrow items',
    //   url: '',
    // },
  },
  {
    title: 'Total people interacted with',
    dataIndex: 'countInteractions',
  },
  {
    title: 'Total listing views',
    dataIndex: 'countListingViews',
  },
];

const renderValue: render = (data, el, currency) => {
  const {dataIndex, title} = el;
  return (
    <Card
      value={`${currency?.sign || ''}${convertCentToDollar(data[dataIndex]) || 0}`}
      title={title}
      link={el?.link && {url: el.link.url, title: el.link.title}}
    />
  );
};

const renderStars: render = (data, el) => {
  const {dataIndex, title} = el;
  return (
    <Card
      value={`${data[dataIndex]?.toFixed(1) || 0} stars`}
      title={title}
      link={el?.link && {url: el.link.url, title: el.link.title}}
    />
  );
};

const getRender = (data: residentDashboard, el: Field, currency?: Currency) => {
  const {dataIndex, render} = el;
  if (render === 'value') return renderValue(data, el, currency);
  if (render === 'stars') return renderStars(data, el);
  return <Card {...el} value={data[dataIndex] || 0} key={el.title} />;
};

const getName = ({firstName, lastName}: User): string | undefined => {
  return firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;
};

const getAddress = (location: Location): string | undefined => {
  const {City, State} = location;
  const {name: city} = City || {};
  const {name: state} = State || {};

  return city && state ? `${city}, ${state}` : city || state;
};

export const View: React.FC<{user: User; dashboard: residentDashboard}> = ({user, dashboard}) => {
  const currency = user.Communities?.edges[0]?.node.Subscr?.PaymentPlan?.Currency;

  return (
    <PageWrapper>
      <ViewHeader title={'Back to members'} redirectTo={'/members'} />
      <ProfileIntroWrapper>
        <ProfileIntro
          title={getName(user)}
          avatar={user.Avatar?.file?.url}
          isVerified={user.emailVerified ? true : undefined}
          countReviews={user.Reviews?.count || 0}
          joined={user.createdAt}
          description={user.bio}
          work={user.work}
          education={user.study}
          languages={user.languages}
          address={user.Location && getAddress(user.Location)}
          interestsTitle={'Interests'}
          recommendsTitle={'Recommendations'}
        />
      </ProfileIntroWrapper>
      <CardsWrapper>
        {fields.map((el, idx) => (
          <DisabledWrapper key={idx}>{getRender(dashboard, el, currency)}</DisabledWrapper>
        ))}
      </CardsWrapper>
    </PageWrapper>
  );
};
