import {Text} from '../../ui-kit/Typography/Text';
import React from 'react';
import {Item} from '../../types/item';
import {Label} from '../../ui-kit/Labels';
import {AlignContentRight} from './styles';
import {User as UserType} from '../../types/user';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu as MenuTable} from '../../ui-kit/Menu';
import {actionsWithItems} from '../../containers/Listings/types';
import {convertCentToDollar} from '../../helpers/calculate';

export const Listing = (item: Item) => {
  return (
    <Text color={'quartdecimal'} variant={'quaternary'} weight={'medium'} size={14}>
      {cutLongName(item.name)}
    </Text>
  );
};

export const Type = (item: Item) => {
  return <Label>{capsFirstLetter(item.listingType)}</Label>;
};

export const Price = (item: Item) => {
  const currency = item.Lister?.Communities?.edges[0]?.node.Subscr.PaymentPlan?.Currency;

  return (
    <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
      {item.price ? `${currency?.sign || ''}${convertCentToDollar(item.price)} ${currency?.code}` : item.rewardType}
    </Text>
  );
};

export const Shared = () => {
  return (
    <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
      {/*{`${} times`}*/}-
    </Text>
  );
};

export const Member = (item: Item) => {
  return (
    <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
      {item.Lister?.firstName} {item.Lister?.lastName && item.Lister.lastName[0]}
    </Text>
  );
};

export const Earned = () => {
  return (
    <AlignContentRight>
      <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
        {/*{`$${} USD`}*/}-
      </Text>
    </AlignContentRight>
  );
};

function capsFirstLetter(string?: string) {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function cutLongName(string?: string) {
  if (!string) return;
  if (string.length > 25) return string.slice(0, 25) + '...';
  return string;
}

export const getMenu = (actions?: actionsWithItems) => {
  const {onDelete} = actions || {};

  return (item: UserType) => {
    const TableOptions: MenuItemType[] = [
      // {
      //   title: 'View',
      // },
      // {
      //   title: 'Edit',
      // },
      {
        render: 'line',
      },
      {
        title: 'Delete',
        render: 'danger',
        onClick: onDelete,
      },
    ];
    return <MenuButton item={item} menu={MenuTable} options={TableOptions} />;
  };
};
