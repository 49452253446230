import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const WrapperContent = styled.div<{alignRight?: boolean}>`
  display: flex;
  height: 39px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 160px;
  align-items: ${({alignRight}) => (alignRight ? 'flex-end' : 'flex-start')};

  & span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TableWrapper = styled.div`
  tr {
    td:nth-child(5),
    td:nth-child(6) {
      text-align: right;
    }
  }
`;
