import React from 'react';
import {changeValueFn, UpdatedFields} from '../hooks';
import {PaymentPlan, UpdatePaymentPlan} from '../../../../types/payments';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {CFButton, ContentColumn, GroupeColumns, StyledText} from '../styles';
import {Input} from '../Input';
import {fromCents} from './helpers';

export function getColumns(changeValue: changeValueFn, fields: UpdatedFields, isEditMode: boolean, currSign: string) {
  const Offering = (payment: PaymentPlan) => {
    return <StyledText paddingLeft={26}>{payment.name}</StyledText>;
  };
  const PaymentPrice = (payment: PaymentPlan) => {
    const currentUpdateItem = fields[payment.objectId];
    const getValue = (name: keyof PaymentPlan) => {
      const value = (currentUpdateItem && currentUpdateItem[name as keyof UpdatePaymentPlan]) ?? payment[name];
      return typeof value === 'number' && !isEditMode ? fromCents(value) : value;
    };
    const onChange = (name: keyof UpdatePaymentPlan) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isEditMode) return;
      const value = e.target.value;
      changeValue(payment.objectId, name, +value);
    };
    return (
      <GroupeColumns>
        <ContentColumn>
          <Input
            onChange={onChange('priceMonth')}
            disabled={!isEditMode}
            value={`${getValue('priceMonth')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
        <ContentColumn>
          <Input
            onChange={onChange('priceYear')}
            disabled={!isEditMode}
            value={`${getValue('priceYear')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
      </GroupeColumns>
    );
  };
  const Cap = (payment: PaymentPlan) => {
    const getValue = (name: keyof PaymentPlan) => {
      const currentUpdateItem = fields[payment.objectId];
      const value = (currentUpdateItem && currentUpdateItem[name as keyof UpdatePaymentPlan]) ?? payment[name];
      return typeof value === 'number' && !isEditMode ? fromCents(value) : value;
    };
    const onChange = (name: keyof UpdatePaymentPlan) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isEditMode) return;
      const value = e.target.value;
      changeValue(payment.objectId, name, +value);
    };
    return (
      <GroupeColumns>
        <ContentColumn>
          <Input
            onChange={onChange('listingCap')}
            disabled={!isEditMode}
            value={`${getValue('listingCap')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
        <ContentColumn>
          <Input
            onChange={onChange('residentCap')}
            disabled={!isEditMode}
            value={`${getValue('residentCap')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
      </GroupeColumns>
    );
  };
  const RewardValue = (payment: PaymentPlan) => {
    const getValue = (name: keyof PaymentPlan) => {
      const currentUpdateItem = fields[payment.objectId];
      const value = (currentUpdateItem && currentUpdateItem[name as keyof UpdatePaymentPlan]) ?? payment[name];
      return typeof value === 'number' && !isEditMode ? fromCents(value) : value;
    };
    const onChange = (name: keyof UpdatePaymentPlan) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isEditMode) return;
      const value = e.target.value;
      changeValue(payment.objectId, name, +value);
    };
    return (
      <GroupeColumns>
        <ContentColumn>
          <Input
            onChange={onChange('priceMonth')}
            disabled={!isEditMode}
            value={`${getValue('priceMonth')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
        <ContentColumn>
          <Input
            onChange={onChange('priceYear')}
            disabled={!isEditMode}
            value={`${getValue('priceYear')}`}
            isEditMode={isEditMode}
            currency={currSign}
          />
        </ContentColumn>
      </GroupeColumns>
    );
  };
  const CF = (payment: PaymentPlan) => {
    const getValue = () => {
      const currentUpdateItem = fields[payment.objectId];
      return (
        (currentUpdateItem && currentUpdateItem['communityFee' as keyof UpdatePaymentPlan]) ?? payment['communityFee']
      );
    };
    const value = getValue();
    const onChange = () => {
      if (!isEditMode) return;
      changeValue(payment.objectId, 'communityFee', !value);
    };
    return (
      <CFButton onClick={onChange}>
        <IconSvg type={value ? 'plus' : 'minus'} width={'20px'} height={'20px'} viewBox={'0 0 25 25'} />
      </CFButton>
    );
  };
  return {Cap, CF, Offering, PaymentPrice, RewardValue};
}
