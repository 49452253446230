import styled from 'styled-components';
import {LinkButton} from '../Button/LinkButton';
import {IconSvg} from '../Icon/Svg';

export const PaginationWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  & > * {
    margin-left: 50px;
  }
`;

export const Btn = styled(LinkButton).attrs({width: '10px', height: '10px'})<{isHidden?: boolean}>`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  ${({isHidden}) => isHidden && 'visibility: hidden;'}
`;

export const SetRowsBtnWrapper = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
`;

export const BtnVector = styled(IconSvg).attrs({width: '6px', height: '10px', viewBox: '0 0 6 10'})``;

export const BtnSetRows = styled(IconSvg).attrs({width: '8px', height: '5px', viewBox: '0 0 8 5'})`
  position: relative;
  bottom: 2px;
  margin-left: 11px;
`;

export const Text = styled.span`
  ${({theme}) => theme.font.quaternary.semiBold};
  color: ${({theme}) => theme.palette.grayscale._6};
  font-size: 12px;
  line-height: 15px;
`;

export const CountRowsSelect = styled.span`
  padding: 5px;
  position: absolute;
  top: 20px;
  left: -15px;
  border-radius: 6px;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({theme}) => theme.palette.grayscale.white};
  width: 50px;
`;

export const CountRowsVariant = styled(LinkButton).attrs({height: '15px', color: 'white'})`
  padding: 5px;
  width: 100%;
`;

export const CountRowsWrapper = styled.span`
  position: relative;
`;
