import styled from 'styled-components';
import {Link} from '../../ui-kit/Typography/Link';
import {SearchInput} from '../../ui-kit/Form/Input/SearchInput';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
`;

export const Main = styled.div`
  flex-grow: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: solid 1px ${({theme}) => theme.palette.common.gray};
`;

export const DownloadCsv = styled(Link).attrs({
  to: '/',
  variant: 'quaternary',
  weight: 'medium',
  color: 'tertdecimal',
})``;

export const Search = styled(SearchInput)`
  width: 392px;
  ${({theme}) => theme.font.quaternary.regular}
  color: ${({theme}) => theme.palette.text.septenary};
  font-size: 12px;
`;
