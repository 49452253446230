import React from 'react';
import {PrimaryTable} from './PrimaryTable';
import {ItemType, TableProps, variant} from './types';
import {SecondaryTable} from './SecondaryTable';

export const Table = <T,>(props: TableProps<ItemType<T>>) => {
  const variants: Record<variant, React.FC<TableProps<ItemType<T>>>> = {
    primary: PrimaryTable,
    secondary: SecondaryTable,
  };
  const Component = variants[props.variant];
  return <Component {...props} />;
};
