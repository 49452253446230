import {gql} from 'apollo-boost';

export const GetRolesByIdUser = gql`
  query getRoles($id: ID!) {
    roles(where: {users: {have: {id: {equalTo: $id}}}}) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
