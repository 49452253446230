import {useRecoilValue} from 'recoil';
import {MenuItemType} from '../ui-kit/Navigation/types';
import {roleState} from '../states/role';
import {USER_TYPE} from '../types/user';

export interface TNavMenuItems extends MenuItemType {
  roles?: Array<USER_TYPE>;
}

const filterByRole = (role: USER_TYPE, items: Array<TNavMenuItems>) => {
  return items.filter((el) => {
    if (role === USER_TYPE.admin && !el.roles) return true;
    return el.roles?.includes(role);
  });
};

export const useGetNavItems = (
  topItems: Array<TNavMenuItems>,
  bottomItems: Array<TNavMenuItems>,
): {top: Array<MenuItemType>; bottom: Array<MenuItemType>} => {
  const role = useRecoilValue(roleState);

  return {
    top: filterByRole(role as USER_TYPE, topItems),
    bottom: filterByRole(role as USER_TYPE, bottomItems),
  };
};
