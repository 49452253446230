import styled from 'styled-components';
import {Media, theme} from '../theme';
import TextareaAutosize from 'react-textarea-autosize';

export const EpmtyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 249px;
  margin: 0 auto;
`;

export const EmptyCardImageWrapper = styled.div`
  margin-bottom: 10px;
  height: auto;
  padding: 13px 0 2px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyCardImg = styled.img`
  width: auto;
  height: auto;
  max-height: 210px;
`;

export const EmptyCardTitle = styled.span`
  ${theme.font.primary.medium};
  color: ${({theme}) => theme.palette.grayscale._6};
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
`;

export const EmptyCardDescription = styled.p`
  ${theme.font.primary.regular};
  color: ${({theme}) => theme.palette.grayscale._5};
  margin: 0 0 10px 0;
  font-size: 16px;
  text-align: center;
`;

export const ColumnHeaderWrapper = styled.div<{bordered?: boolean}>`
  padding: 19px 30px 22px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => (props.bordered ? `1px solid` : `none`)};
  border-bottom-color: ${({theme}) => theme.palette.grayscale._11};

  ${Media.down.s} {
    padding: 19px 20px 12px 21px;
  }
`;

export const ColumnTitleWrapper = styled.div``;

export const ColumnTitle = styled.span`
  ${theme.font.primary.medium};
  font-size: 20px;
  color: ${({theme}) => theme.palette.grayscale._10};
  display: block;
`;
export const ColumnSubtitle = styled.span`
  ${theme.font.primary.regular};
  font-size: 12px;
  color: ${({theme}) => theme.palette.grayscale._5};
  display: block;
`;

export const HeaderActionsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const InputMessageWrapper = styled.div<{fullWidth?: boolean}>`
  position: relative;
  display: inline-flex;
  border-radius: 50px;
  width: ${(props) => (props.fullWidth ? `100%` : `auto`)};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  background-color: ${({theme}) => theme.palette.grayscale._3};
  padding: 20px 17px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const InputMessageField = styled(TextareaAutosize)`
  ${theme.font.primary.regular};
  background-color: transparent;
  flex-grow: 1;
  border: none;
  outline: none;
  color: ${({theme}) => theme.palette.grayscale._6};
  resize: none;
  height: 20px;
  max-height: 300px;
`;

export const InputMessageActions = styled.div<{rotate?: boolean}>`
  display: flex;
  margin-left: 10px;
  ${(props) => props.rotate && ` flex-direction:column; justify-content:space-between;`};
`;

export const InputAction = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 6px;
  outline: none;
`;

export const LabelInputAction = styled.label`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 6px;
`;
