import styled from 'styled-components';

export const DashBoardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;
