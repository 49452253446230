import React from 'react';
import {SelectsWrapper} from './styles';
import {Select} from '../../../ui-kit/Form/Select';
import {SubscriptionTable} from './Subscription';
import {PaymentPlan, PaymentPlanName, UpdatePaymentPlan} from '../../../types/payments';
import {FeesTable} from './Fees';
import {Features} from './Features';

interface SelectOption {
  value: PaymentPlanName;
  label: string;
}

interface Props {
  payments: PaymentPlan[];
  setPaymentPlan?: React.Dispatch<React.SetStateAction<PaymentPlanName>>;
  updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>;
}

const options: SelectOption[] = [
  {label: PaymentPlanName.basicUSA, value: PaymentPlanName.basicUSA},
  {label: PaymentPlanName.proUSA, value: PaymentPlanName.proUSA},
  {label: PaymentPlanName.starterUSA, value: PaymentPlanName.starterUSA},
  {label: PaymentPlanName.basicCAN, value: PaymentPlanName.basicCAN},
  {label: PaymentPlanName.proCAN, value: PaymentPlanName.proCAN},
  {label: PaymentPlanName.starterCAN, value: PaymentPlanName.starterCAN},
];

const toValues = (plan: PaymentPlan | undefined) => {
  return [
    {
      label: plan ? (plan.name as PaymentPlanName) : PaymentPlanName.starterUSA,
      value: plan ? (plan.name as PaymentPlanName) : PaymentPlanName.starterUSA,
    },
  ];
};

export const PricingAndFeatures: React.FC<Props> = ({payments, setPaymentPlan, updatePaymentPlan}) => {
  const changeCurrentPaimentPlan = (selected: SelectOption[]) => {
    selected[0] && setPaymentPlan?.(selected[0].value);
  };

  return (
    <>
      <SelectsWrapper>
        <Select
          name={'subscr'}
          values={toValues(payments[0])}
          options={options}
          onChange={changeCurrentPaimentPlan}
          width={'240px'}
        />
      </SelectsWrapper>
      <SubscriptionTable payments={payments} updatePaymentPlan={updatePaymentPlan} />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'rentFees'}
        title={'Rent Listings'}
        key={Math.random().toString().slice(2) + 'rentFees'}
      />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'salesFees'}
        title={'Sale Listings'}
        key={Math.random().toString().slice(2) + 'salesFees'}
      />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'serviceFees'}
        title={'Service Listings'}
        key={Math.random().toString().slice(2) + 'serviceFees'}
      />
      <Features paymentPlan={payments[0]} updatePaymentPlan={updatePaymentPlan} />
    </>
  );
};
