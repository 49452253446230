export interface ParseObject {
  id: string;
  objectId: string;
  createdAt?: string;
  updatedAt?: string;
  __typename?: string;
}

interface PageInfo {
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface Edge<T> {
  pageInfo?: PageInfo;
  edges: Node<T>[];
  count: number;
}

export interface Node<T> {
  cursor?: string;
  node: T;
}

export interface AppFile {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;
  file: FileInfo;
  name: string;
  type: string;
  mime: string;
}

export type FileInfo = {
  name: string;
  url: string;
};

export enum ParseEntity {
  Message = 'Message',
  User = 'User',
}

export interface Pointer {
  __type: string;
  className: string;
  objectId: string;
}

export interface PointerInput {
  link: string;
}

export type Connection<T> = {
  pageInfo: PageInfo;
  edges: Array<Node<T>>;
  count: number;
};
