import styled, {css} from 'styled-components';
import {textPositionType} from './types';

export const LoaderContainer = styled.div<{zIndex?: number; $isVisible?: boolean}>`
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1001)};
  opacity: 1;
  visibility: visible;
  transition: 0.51s all;
  ${(props) => !props.$isVisible && ` opacity:0; visibility:hidden;`}
`;

export const LoaderOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
`;

const SpinnerStyles = css`
  & .lds-spinner {
    color: #333;
    display: inline-block;
    position: relative;
    width: 75px;
    height: 40px;
  }
  & .lds-spinner div {
    transform-origin: 40px 25px;
    animation: lds-spinner 1.2s linear infinite;
  }
  & .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 38px;
    width: 3px;
    height: 13px;
    border-radius: 20%;
    background: #333;
  }
  & .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  & .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  & .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  & .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  & .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  & .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  & .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  & .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  & .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  & .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  & .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  & .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const LoaderItemWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  padding: 45px 30px;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${SpinnerStyles};
`;

export const ContentLoaderWrapper = styled.div<{textPosition?: textPositionType; padding?: string}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.textPosition === 'left' ? `flex-start` : props.textPosition === 'right' ? `flex-end` : `center`};
  padding: ${(props) => (props.padding ? props.padding : `0px`)};
`;

export const LoadingTextLabel = styled.div`
  margin-left: 6px;
`;

export const DefaultText = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.palette.grayscale._6};
  &:after {
    content: '.';
    animation: dots 1.2s infinite;}

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)}
    40% {
      color: rgba(0,0,0,1);
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow: .25em 0 0 rgba(0,0,0,1), .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow: .25em 0 0 rgba(0,0,0,1), .5em 0 0 rgba(0,0,0,1);}
`;

export const StyledSpinnerLoader = styled.div<{padding?: string}>`
  ${({padding}) => padding && `padding: ${padding}`};
  margin: 0 auto;
  height: 30px;
  margin-top: -6px;
  ${SpinnerStyles};
  & .lds-spinner div:after {
    top: 8px;
    left: 38px;
    width: 3px;
    height: 10px;
  }
`;
