import React, {useEffect, useState} from 'react';
import {BrowserData} from '../../../components/BrowserData';
import {Table} from '../../../components/Communities/table';
import {useGetCommunities, useSearchCommunity, useUpdateCommunity} from '../../../hooks/communities';
import {Community} from '../../../types/communities';

export const TabAll = () => {
  const {data, count} = useGetCommunities();
  const {verifyCommunity, disableCommunity, activeCommunity} = useUpdateCommunity();
  const [items, setItems] = useState<Community[]>(data ?? []);
  const search = useSearchCommunity();

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <BrowserData count={count} initialItems={data} setItems={setItems} onSearch={search}>
      <Table
        items={items}
        activeCommunity={activeCommunity}
        disableCommunity={disableCommunity}
        verifyCommunity={verifyCommunity}
      />
    </BrowserData>
  );
};
