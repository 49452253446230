import React, {SetStateAction, useState} from 'react';
import {Search} from './styles';

interface SearchProps<T> {
  initialItems?: T[];
  setItems?: React.Dispatch<React.SetStateAction<T[]>>;
  onSearch?: (match: string) => Promise<T[] | undefined>;
  setIsSearchData?: React.Dispatch<SetStateAction<boolean>>;
}

export const SearchField = <T,>({initialItems, setItems, onSearch, setIsSearchData}: SearchProps<T>) => {
  const [match, setMatch] = useState<string>('');

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (match) {
        onSearch?.(match).then((data) => {
          setIsSearchData?.(true);
          setItems?.(data ?? []);
        });
      } else {
        setIsSearchData?.(false);
        setItems?.(initialItems ?? []);
      }
    }
  };

  const handleSearch = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setMatch(value);

    if (!value) {
      setIsSearchData?.(false);
      setItems?.(initialItems ?? []);
    }
  };

  return (
    <Search
      placeholder="Search by community name or tag"
      value={match}
      onChange={handleSearch}
      onKeyPress={handleEnter}
    />
  );
};
