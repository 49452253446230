import Parse from 'parse';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {omit} from '../helpers/object';
import {useRecoilValue} from 'recoil';
import {sessionState} from '../states';

export const useParseQuery = (className: string, deps?: any[]) => {
  return useMemo(() => {
    return new Parse.Query(className);
  }, [...(deps || [])]);
};

enum LiveQueryEvent {
  delete = 'delete',
  update = 'update',
  open = 'open',
  close = 'close',
  enter = 'enter',
  leave = 'leave',
  create = 'create',
}

export const toPOJO = <T, K extends [...(keyof T)[]] = (keyof T)[], V = any>(
  fields: K,
  item: Parse.Object,
  mapFields?: Partial<Record<keyof T, (value: V | undefined) => T[keyof T]>>,
): T => {
  const entries = fields.map((field) => {
    if (field === 'objectId') return [field, item.id];

    const fieldMap = mapFields?.[field];

    if (fieldMap) {
      return [field, fieldMap(item.get(field as string))];
    }

    return [field, item.get(field as string)];
  });

  return Object.fromEntries(entries);
};

export type LiveQueryHandlers<T> = {
  onOpen?: () => void;
  onClose?: () => void;
  onUpdate?: (item: T, dirtyKeys: (keyof T)[], ref: Parse.Object) => T | void;
  onCreate?: (item: T, ref: Parse.Object) => void;
  onDelete?: (item: T, ref: Parse.Object) => void;
  onEnter?: (item: T, ref: Parse.Object) => void;
  onLeave?: (item: T, ref: Parse.Object) => void;
};

const getMap = <T>(items: Parse.Object[], map: (item: Parse.Object) => T) => {
  return items?.reduce((acc, item) => {
    acc[item.id] = map(item);
    return acc;
  }, {} as State<T>);
};

type UseLiveQueryParams<T> = {
  query: Parse.Query;
  eventHandlers?: LiveQueryHandlers<T>;
  onFailure?: (error: unknown) => void;
  map: (object: Parse.Object) => T;
  skip?: boolean;
};

type State<T> = Record<string, T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const useLiveQuery = <T extends object = object>({
  query,
  eventHandlers,
  onFailure,
  map,
}: UseLiveQueryParams<T>) => {
  const ref = useRef<Parse.LiveQuerySubscription | undefined>();

  const [state, setState] = useState<State<T> | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const session = useRecoilValue(sessionState);

  const clear = useCallback(() => {
    setState({});
  }, []);

  const unsubscribe = () => {
    if (!ref.current) return;

    ref.current.unsubscribe();
  };

  const fetch = useCallback(async () => {
    // if (skip) return;

    try {
      setLoading(true);
      const items = await query.find({
        sessionToken: session?.sessionToken,
      });
      const objects = getMap(items, map);
      setState(objects);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [query, map]);

  const updateState = (id: string, object: T, event: LiveQueryEvent) => {
    return setState((prev) => {
      if (!prev) return prev;
      if (prev[id] === object) return;

      if (event === LiveQueryEvent.delete || event === LiveQueryEvent.leave) {
        return omit(prev, [id]);
      }

      return {...prev, [id]: object};
    });
  };

  const handleSubscribe = useCallback(
    (subscription?: Parse.LiveQuerySubscription) => {
      const {onOpen, onClose, onUpdate, onLeave, onEnter, onDelete, onCreate} = eventHandlers || {};

      if (!subscription) return;

      ref.current = subscription;

      if (onOpen) {
        ref.current.on('open', onOpen);
      }

      if (onClose) {
        ref.current.on('close', onClose);
      }

      ref.current.on('create', async (object) => {
        if (onCreate) {
          onCreate(await map(object), object);
        }

        return fetch();
      });

      ref.current.on('update', async (object) => {
        const pojo = await map(object);

        if (onUpdate) {
          const updated = onUpdate(pojo, object.dirtyKeys() as (keyof T)[], object);
          return updateState(object.id, updated || pojo, LiveQueryEvent.update);
        }

        return updateState(object.id, pojo, LiveQueryEvent.update);
      });

      ref.current.on('delete', async (object) => {
        const pojo = await map(object);

        if (onDelete) onDelete(pojo, object);

        return updateState(object.id, pojo, LiveQueryEvent.delete);
      });

      ref.current.on('enter', async (object) => {
        if (onEnter) {
          onEnter(await map(object), object);
        }

        return fetch();
      });

      ref.current.on('leave', async (object) => {
        const pojo = await map(object);

        if (onLeave) onLeave(pojo, object);

        return updateState(object.id, pojo, LiveQueryEvent.leave);
      });
    },
    [eventHandlers, fetch, map],
  );

  useEffect(() => {
    if (!query) return;

    fetch();

    query
      .subscribe()
      .then(handleSubscribe)
      .catch(onFailure || console.error);

    return unsubscribe;
  }, [fetch, query]);

  return {
    data: state,
    loading,
    error,
    refetch: fetch,
    unsubscribe,
    clear,
  };
};

export const getParseQuery = (...params: ConstructorParameters<typeof Parse.Query>) => {
  return new Parse.Query(...params);
};
