import {gql} from 'apollo-boost';
import {CardFragment} from './fragments/cards';

export const getCard = gql`
  mutation getCard($id: String) {
    paymentGetUserCards(input: {userId: $id}) {
      defaultCardId
      cards {
        ...CardFragment
      }
    }
  }

  ${CardFragment}
`;
