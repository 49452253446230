import React, {useState} from 'react';
import {Image, size, Wrapper} from './styles';
import DefaultAvatar from './assets/avatar-default.png';
import Loader from './assets/loader.png';

export type AvatarProps = {
  src?: string;
  alt?: string;
  size?: size;
  $isOnline?: boolean;
  className?: string;
  loading?: boolean;
};

const AvatarMemo: React.FC<AvatarProps> = ({src, alt, loading, ...props}) => {
  const [visibility, setVisibility] = useState(false);

  const onLoad = () => {
    !visibility && setVisibility(true);
  };

  return (
    <Wrapper {...props}>
      <Image
        src={loading ? Loader : src || DefaultAvatar}
        alt={alt || 'Image'}
        onLoad={onLoad}
        $visibility={visibility}
        $loading={loading}
      />
    </Wrapper>
  );
};

const Avatar = React.memo(AvatarMemo);
export {Avatar};
