import React, {useEffect, useState} from 'react';
import {Fieldset, InnerInput, InnerSubElem, Label, PrefixInput} from './styles';
import {StyledProps, InputWrapperProps, HTMLInputProps} from './types';

require('cleave.js/dist/addons/cleave-phone.us');

export function InputWrapper<T extends StyledProps & HTMLInputProps>({
  className,
  value,
  prefix,
  suffix,
  libPrefix,
  libSuffix,
  icon,
  label,
  $isError,
  width,
  height,
  customInput,
  Component,
  disabled,
  onChange,
  ...props
}: InputWrapperProps<T>): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const InputComponent = customInput;

  useEffect(() => {
    props?.autoFocus && handleFocus();
  }, []);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };
  return (
    <Fieldset
      $isError={$isError}
      $isActive={isActive}
      width={width}
      height={height}
      className={'fieldset-box__root ' + className}
      onFocus={handleFocus}>
      <Label $isError={$isError} $isActive={isActive || Boolean(value)}>
        {label}
      </Label>
      <InnerInput>
        {prefix && <PrefixInput>{prefix}</PrefixInput>}
        <InputComponent
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          value={value}
          $isError={$isError}
          className={'fieldset-box__input'}
          disabled={disabled}
          suffix={libSuffix}
          prefix={libPrefix}
          {...props}
        />
        {suffix && <PrefixInput>{suffix}</PrefixInput>}
      </InnerInput>
      <InnerSubElem>
        {icon}
        {Component && <Component />}
      </InnerSubElem>
    </Fieldset>
  );
}
