import styled from 'styled-components';
import {Media} from '../theme';

export const Br = styled.br<{indent: number; mobileIndent?: number}>`
  display: block;
  content: '';
  margin-top: ${({indent}) => `${indent}px`};

  ${Media.down.s} {
    ${({mobileIndent}) => mobileIndent && `margin-top: ${mobileIndent}px`}
  }
`;
