import {KeyMode} from './constans';

const commonColors = {
  aqua: '#0097B8',
  red: '#EB5757',
  redDark: '#D30000',
  blue: '#2269E7',
  redLight: '#FFE0E0',
  redLight2: '#FFE1E1',
  blueSky: '#2D9CDB',
  blueGray: '#E6E6F2',
  blueGray2: '#C6C2DE',
  blueGrayDark: '#6E6893',
  blueDark: '#4A4AFF',
  blueDark2: '#142641',
  blueLight: '#E8F9FF',
  green: '#6FCF97',
  greenLight: '#27AE60',
  greenLight2: '#CDFFCD',
  greenDark: '#007F00',
  orange: '#FFB61D',
  yellow: '#F2C94C',
  yellowLight: '#FFF1D2',
  yellowLight2: '#FFF3DA',
  yellowLight3: '#FFEFCE',
  purpleDark: '#25213B',
  gray: '#D9D5EC',
  grayLight: '#d5d4d4',
  aqua2: '#e9f9ff',
};

const grayscale = {
  white: '#ffffff',
  _0: '#ffffff',
  _1: '#FFFAFA',
  _2: '#FCFCFC',
  _3: '#F2F2F2',
  _4: '#C4C4C4',
  _5: '#828282',
  _6: '#4F4F4F',
  _7: '#555555',
  _10: '#2D2D2D', // changed from #000000;
  _11: '#E0E0E0',
  _12: '#333333',
  _13: '#939393',
  _14: '#BDBDBD',
  _15: 'rgba(0, 0, 0, 0.5)',
  _16: 'rgba(0, 0, 0, 0.03)',
  _17: '#F2F0F9',
  _18: '#25213B',
  _19: '#293145',
  _20: '#F8FAFA',
  _21: '#D9D9D9',
};

//https://en.wikipedia.org/wiki/List_of_numeral_systems

const LightModeColors = {
  common: commonColors,
  grayscale: grayscale,
  text: {
    primary: grayscale._0,
    secondary: grayscale._1,
    tertiary: grayscale._2,
    quaternary: grayscale._10,
    quinary: grayscale._7,
    senary: grayscale._4,
    septenary: grayscale._5,
    octonary: grayscale._6,
    nonary: commonColors.aqua,
    denary: commonColors.blue,
    undecimal: grayscale._14,
    duodecimal: grayscale._13,
    tertdecimal: commonColors.blueGrayDark,
    quartdecimal: commonColors.purpleDark,
    quindecimal: grayscale._12,
    hexadecimal: grayscale._11,
    heptadecimal: grayscale._15,
    octodecimal: grayscale._3,
    enneadecimal: grayscale._18,
    sevendecimal: grayscale._19,
  },
  button: {
    danger: commonColors.red,
    primary: commonColors.aqua,
    secondary: grayscale._6,
    tertiary: commonColors.blue,
    success: commonColors.green,
    select: grayscale._12,
  },
  background: {
    primary: grayscale.white,
    secondary: grayscale._11,
    tertiary: grayscale._14,
    quaternary: commonColors.aqua,
    quinary: grayscale._6,
    senary: commonColors.blueDark2,
    septenary: grayscale._3,
    octonary: grayscale._5,
    nonary: grayscale._2,
    denary: grayscale._13,
    undecimal: grayscale._16,
    duodecimal: commonColors.blueLight,
    tertdecimal: grayscale._17,
  },
};

const darkModeColors = {
  ...LightModeColors,
  text: {
    ...LightModeColors.text,
    octonary: grayscale._2,
  },
  button: {
    ...LightModeColors.button,
    secondary: grayscale._3,
  },
  background: {
    quinary: grayscale._2,
  },
};

export const palette = {
  [KeyMode.light]: LightModeColors,
  [KeyMode.dark]: darkModeColors,
};

export const gradient = {};
