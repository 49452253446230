import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Button} from '../Button/Button';
import {Text} from '../Typography/Text';
import {Media, theme} from '../theme';
import {IconSvg as UiIconSvg} from '../Icon/Svg';

export const ProfileWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  ${Media.down.xs} {
    flex-direction: column;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  &.loop_profile-intro .intro__footer-labels {
    ${Media.down.s} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const CustomAvatar = styled.div`
  ${Media.down.s} {
    width: 71px;
    height: 71px;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${Media.down.xs} {
    flex-direction: column;
  }
`;

export const TitleText = styled(Text).attrs({weight: 'bold', size: 26})`
  line-height: 125%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.palette.text.octonary};
  ${Media.down.s} {
    width: max-content;
    display: block;
    font-size: 20px;
  }
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const LabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }

  ${Media.down.xs} {
    margin-bottom: 0;
  }
`;

export const LabelProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
`;

export const HeaderLabelsWrapper = styled(LabelsWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5px;

  ${Media.down.s} {
    grid-template-columns: repeat(3, max-content);
  }
`;

export const FooterLabelsWrapper = styled.div.attrs({className: 'intro__footer-labels'})<{
  footerLblsGapX?: string;
  footerLblsGapY?: string;
}>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: ${({footerLblsGapX}) => (footerLblsGapX ? footerLblsGapX : '40px')};
  grid-row-gap: ${({footerLblsGapY}) => (footerLblsGapY ? footerLblsGapY : '8px')};
  justify-content: flex-start;
  box-sizing: border-box;

  ${Media.down.s} {
    grid-column-gap: 7px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export const Description = styled.div`
  ${({theme}) => theme.font.primary.regular};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 16px;
  line-height: 150%;
  margin: 10px 0;
  white-space: pre-line;
  ${Media.down.m} {
    margin: 8px 0;
  }
`;

export const WrapperButton = styled.div<{isHidden?: boolean}>`
  margin-left: auto;
  padding-left: 30px;
  box-sizing: border-box;
  ${({isHidden}) => isHidden && 'pointer-events: none;'}
`;

export const WrapperContent = styled.div`
  width: calc(100% - 172px);

  ${Media.down.s} {
    width: calc(100% - 90px);
  }

  ${Media.down.xs} {
    width: 100%;
  }
`;

export const Hidden = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const AvatarWrapper = styled.div`
  margin-right: 46px;
  ${Media.down.xs} {
    display: flex;
    justify-content: space-between;
  }
  ${Media.down.s} {
    margin-right: 20px;
    margin-bottom: 4px;
  }
  ${Media.down.xs} {
    margin-right: 0;
  }
`;

export const LabelWrapper = styled.div<{width?: number}>`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const LinkLabelWrapper = styled(Link)<{width?: number}>`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const LabelText = styled(Text)`
  color: ${({theme}) => theme.palette.text.octonary};
  line-height: 150%;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LabelProfileText = styled(Text)`
  color: ${({theme}) => theme.palette.text.duodecimal};
  line-height: 150%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const JoinedLabelText = styled(LabelText)`
  color: ${({theme}) => theme.palette.text.septenary};
`;

export const IconSvg = styled(UiIconSvg)<{strokeWidth?: number}>`
  flex-shrink: 0;
  margin-right: 9px;
  ${Media.down.m} {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  & path {
    ${({strokeWidth}) => strokeWidth && `stroke-width: ${strokeWidth};`}
  }
`;

export const ProfileIntroButton = styled(Button).attrs({rounded: true, ghost: true, color: '#4F4F4F', align: 'left'})`
  display: inline-flex;
  width: initial;
  padding: 0 12px;
  line-height: 100%;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.grayscale._6};
  background-color: ${({theme}) => theme.palette.background.septenary};
  border: none;

  ${Media.down.m} {
    height: 34px;
    padding: 0 16px;
    align-items: center;

    & .button-value {
      font-size: 14px;
      line-height: 150%;
      height: auto;
      padding: 0;
    }
  }
`;

export const UpdatePhotoError = styled.label`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 10px;
  text-align: center;
  color: ${({theme}) => theme.palette.common.red};
  cursor: pointer;
`;

export const UpdatePhotoInput = styled.input.attrs({type: 'file', id: 'update-photo'})`
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    cursor: pointer;
  }

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export const UpdatePhotoWrapper = styled.div`
  position: relative;
`;

export const UpdatePhotoIcon = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 7%;
  bottom: 2%;

  ${Media.down.s} {
    right: 0;
    bottom: 0;
  }
`;

export const AvatarWithButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

export const TransparentButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;

  & > * {
    padding: 0;
  }
`;

export const InterestsWrapper = styled.div`
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  padding-top: 10px;

  span {
    font-size: 16px !important;
  }
`;

export const InterestWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  padding: 5px 13px;
  background: transparent;
  border: 1px solid ${theme.palette.background.secondary};
  border-radius: 20px;

  & > svg {
    min-width: 24px;
  }
`;

export const RecommendationsWrapper = styled.div`
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const RecommendationWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  padding: 5px 13px;
  background: transparent;
  border: 1px solid ${theme.palette.background.secondary};
  border-radius: 20px;

  & > svg {
    min-width: 24px;
  }
`;

export const ProfileHeaderMobile = styled.div<{$scrolled?: boolean}>`
  position: fixed;
  z-index: 500;
  top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  background: ${theme.palette.background.primary};
  ${Media.down.s} {
    ${({$scrolled, theme}) =>
      $scrolled &&
      `
      border-bottom: 1px solid ${theme.palette.background.secondary};
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    `}
  }
`;

export const ProfileHeaderMobileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px 14px 12px;
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ButtonText = styled.p`
  ${theme.font.primary.regular};
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: ${theme.palette.text.octonary};
`;

export const GoBackWrapper = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const ProfileSectionTitle = styled.p`
  ${theme.font.primary.regular};
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.palette.text.octonary};
`;

export const LabelTitle = styled.p`
  ${theme.font.primary.regular};
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: ${theme.palette.text.octonary};
`;
