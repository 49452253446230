import {gql} from 'apollo-boost';
import {CommunitiesFragment, CommunityFragment} from './fragments/communities';
import {managerDashboardFragment} from './fragments/dashboard';
import {Connection} from '../types/parse';
import {Community} from '../types/communities';
import {GQLCommunityWhereInput} from '../graphql.schema';

export interface IGetCommunitiesResponse {
  communities: Connection<Community>;
}

export interface IGetCommunitiesRequest {
  first?: number;
  skip?: number;
  where?: GQLCommunityWhereInput;
}

export const QueryGetUserCommunities = gql`
  query communities($first: Int, $skip: Int, $where: CommunityWhereInput) {
    communities(first: $first, skip: $skip, where: $where) {
      count
      edges {
        node {
          ...CommunitiesFragment
        }
      }
    }
  }
  ${CommunitiesFragment}
`;

export const getCommunity = gql`
  query community($id: ID!) {
    community(id: $id) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const getCommunityDashboard = gql`
  mutation getCommunity($id: ID!) {
    managerDashboard(input: {communityId: $id}) {
      ...managerDashboardFragment
    }
  }
  ${managerDashboardFragment}
`;

export const updateCommunity = gql`
  mutation updateCommunity($id: ID!, $fields: UpdateCommunityFieldsInput) {
    updateCommunity(input: {id: $id, fields: $fields}) {
      community {
        ...CommunitiesFragment
      }
    }
  }
  ${CommunitiesFragment}
`;

export const SearchCommunity = gql`
  query communitySearch($where: CommunityWhereInput) {
    communities(where: $where) {
      edges {
        node {
          ...CommunitiesFragment
        }
      }
    }
  }

  ${CommunitiesFragment}
`;
