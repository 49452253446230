// import {palette} from './palette';

const antiAliasing = {
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

const font = {
  primary: {
    regular: {
      fontFamily: 'Inter',
      fontWeight: 400,
      ...antiAliasing,
    },
    medium: {
      fontFamily: 'Inter',
      fontWeight: 500,
      ...antiAliasing,
    },
    semiBold: {
      fontFamily: 'Inter',
      fontWeight: 500,
      // fontWeight: 600,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Inter',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
  secondary: {
    regular: {
      fontFamily: 'Cabin, sans-serif',
      fontWeight: 400,
      ...antiAliasing,
    },
  },
  tertiary: {
    regular: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      ...antiAliasing,
    },
    semiBold: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      ...antiAliasing,
    },
  },
  quaternary: {
    regular: {
      fontFamily: 'Inter',
      fontWeight: 400,
      ...antiAliasing,
    },
    medium: {
      fontFamily: 'Inter',
      fontWeight: 500,
      ...antiAliasing,
    },
    semiBold: {
      fontFamily: 'Inter',
      fontWeight: 600,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Inter',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
};

export const mixins = {
  font,
};
