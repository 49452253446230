import React, {useState} from 'react';
import {useCreatePaymentPlan, useCreateSubscr, useUpdatePaymentPlan} from '../../../hooks/payments';
import {ViewCommunityType} from '../../../types/communities';
import {CustomPricingAndFeatures} from './CustomPricing';
import {UpdatePaymentPlan} from '../../../types/payments';
import {getCustomPlanName, getNewPlan} from './helpers';
import {useUpdateCommunity} from '../../../hooks/communities';

interface CustomPlanProps {
  community: {data?: ViewCommunityType};
}

export const CustomPlan: React.FC<CustomPlanProps> = ({community}) => {
  const {updatePaymentPlan} = useUpdatePaymentPlan();
  const createPlan = useCreatePaymentPlan();
  const createSubscr = useCreateSubscr();
  const {updateCommunity} = useUpdateCommunity();
  const [externalRent, setExternalRent] = useState<boolean | null>(null);
  const [externalSale, setExternalSale] = useState<boolean | null>(null);
  const [externalService, setExternalService] = useState<boolean | null>(null);
  if (!community.data?.Subscr?.PaymentPlan) return null;
  const isCustomPlan = community.data?.Subscr.PaymentPlan?.isPublic === false;
  const externalPayments = {
    rent: externalRent === null ? !!community.data?.Subscr.PaymentPlan?.rentalExternal : externalRent,
    sale: externalSale === null ? !!community.data?.Subscr.PaymentPlan?.salesExternal : externalSale,
    service: externalService === null ? !!community.data?.Subscr.PaymentPlan?.serviceExternal : externalService,
    toggleRent: (reset?: boolean) => {
      reset ? setExternalRent(!!community.data?.Subscr.PaymentPlan?.rentalExternal) : setExternalRent((prev) => !prev);
    },
    toggleSale: (reset?: boolean) => {
      reset ? setExternalSale(!!community.data?.Subscr.PaymentPlan?.salesExternal) : setExternalSale((prev) => !prev);
    },
    toggleService: (reset?: boolean) => {
      reset
        ? setExternalService(!!community.data?.Subscr.PaymentPlan?.serviceExternal)
        : setExternalService((prev) => !prev);
    },
  };

  const handleUpdatePlan = async (paymentPlan: UpdatePaymentPlan) => {
    if (!community.data?.Subscr?.PaymentPlan?.isPublic) {
      updatePaymentPlan(paymentPlan);
    } else {
      const subscr = community.data.Subscr;
      const newPlan = {
        ...(subscr.PaymentPlan ?? {}),
        ...paymentPlan,
      };

      if (paymentPlan.options) {
        newPlan.options = paymentPlan.options?.map((o) => ({__typename: 'Element', value: o}));
      }

      const customPlan = await createPlan(getNewPlan(newPlan, getCustomPlanName(community.data)));
      const customSubscr = await createSubscr({
        Communities: {
          add: [community.data?.id ?? ''],
        },
        PaymentPlan: {
          link: customPlan?.createPaymentPlan.paymentPlan.id,
        },
        expireAt: subscr.expireAt,
        netPaid: subscr.netPaid,
        paymentPeriod: subscr.paymentPeriod,
        status: 'inactive',
      });
      await updateCommunity(community.data, {
        Subscr: {
          link: customSubscr?.createSubscr?.subscr?.id,
        },
      });
    }
  };

  return (
    <CustomPricingAndFeatures
      updatePaymentPlan={handleUpdatePlan}
      payments={[community.data.Subscr.PaymentPlan]}
      createPlan={createPlan}
      isCustomPlan={isCustomPlan}
      externalPayments={externalPayments}
      planId={community.data?.Subscr?.PaymentPlan?.objectId}
    />
  );
};
