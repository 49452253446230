import React, {useEffect, useRef, useState} from 'react';
import {Scrollbar} from 'react-scrollbars-custom';
import {EmptyCard} from '../../../ui-kit/Chat/EmptyCard';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {MessageBox} from './MessageBox';
import {
  CustomScrollbar,
  FormChat,
  InnerEmptyCard,
  ThumbStyles,
  WrapMsgBox,
  ChatInputWrapper,
  AddFileWrapper,
  ChatAreaSend,
  InnerImages,
  InnerImgMsg,
  ImgMsg,
  BtnDelImg,
} from '../styles';
import {ParseEntity} from '../../../types/parse';
import {toGQLId} from '../../../helpers/parse';
import {IDataHookMessage, TContact, TMessage, TypeMsg, CreateMsgFields} from '../../../types/mesages';
import {getActualNotifMessages, isSeen, checkIsEmptyMessage, checkIsDifferentDay} from '../../../helpers/messages';
import {textsMessages} from '../texts';
import {getResize, getSourceUrl} from '../../../helpers/file';
import {getUserName} from '../../../helpers/user';
import {RichTextEditor} from '../../common/RichText/Editor';
import {FileInput} from '../../../ui-kit/Form/ImageLoader/FileInput';
import {isMobile} from 'react-device-detect';
import {LabelInputAction} from '../../../ui-kit/Chat/styles';
import {InnerTypePanel, SelectType} from './styles';
import {Text} from '../../../ui-kit/Typography/Text';

type TProps = {
  messages?: TMessage[] | null;
  contact?: TContact | null;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  dataHookMessage: IDataHookMessage;
  setInputFocus?: (val: boolean) => void;
};

type PreviewImg = {
  previewImg?: string;
  images: string[];
};

const messageTypes = [
  {label: 'Response', value: null},
  {label: 'Internal note', value: TypeMsg.internalNote},
];
const LabelImageButton: React.FC<{onAddImage?: () => void}> = ({children}) => {
  return (
    <LabelInputAction>
      <IconSvg type={'circled-plus'} stroke={'gray'} />
      {children}
    </LabelInputAction>
  );
};

export const ChatArea: React.FC<TProps> = ({viewerId, contact, messages, dataHookMessage, setInputFocus}) => {
  const chatContainer = React.useRef<(HTMLDivElement & Scrollbar) | null>(null);
  const [unseenMsgRef, setUnseenMsgRef] = useState<HTMLDivElement | null>(null);
  const [isInitialScrollDone, setIsInitialScrollDone] = useState(false);
  const msgsBox = useRef<HTMLDivElement>(null);
  const bottom = useRef<HTMLDivElement>(null);
  const [activeImg, setActiveImg] = useState<PreviewImg | null>(null);
  const {values: message, onChange, deleteImage, onSubmit, updateSeenMsg, LoadingCreateMsg: loading} = dataHookMessage;

  const [messageValue, setMessageValue] = useState('');
  const [textEditorRef, setTextEditorRef] = useState<{blur: () => void; focus: () => void}>();

  const isEmptyMessage = checkIsEmptyMessage(messageValue) && !message?.Attachments?.length;

  const handleSubmit = () => {
    if (isEmptyMessage) return;
    setMessageValue('');
    onSubmit();
  };

  const handleChangeMessage = (value: string) => {
    setMessageValue(value);
    onChange({name: CreateMsgFields.text, value});
  };

  const onInputFocus = () => setInputFocus?.(true);
  const onInputBlur = () => setInputFocus?.(false);

  const setEditorRef = (ref: any) => {
    if (!textEditorRef) {
      setTextEditorRef(ref);
    }
  };
  const onEditorKeydown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.keyCode === 13 && !ev.shiftKey) {
      handleSubmit();
      setTimeout(() => {
        setMessageValue('');
      }, 1);
    }
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({name: CreateMsgFields.Attachments, value: Object.values(event.target.files || {})});
    event.target.files = null;
    event.target.value = '';
  };
  const handleDeleteImage = (index: number) => () => {
    deleteImage(index);
  };

  const imagePreviewToggle = (f: PreviewImg) => {
    setActiveImg(f);
  };
  typeof activeImg;

  const handleChangeType = ([{value}]: any) => {
    onChange({name: CreateMsgFields.type, value});
  };

  const shouldBecomeSeen = (msg: TMessage) => {
    if (isSeen(msg, viewerId)) return false;

    const isFromViewer = msg?.Author?.objectId === viewerId;

    return !isFromViewer;
  };

  const getFirstUnseenMessageIndex = (msgs: TMessage[]): number => {
    if (isInitialScrollDone) return -1;

    return msgs?.findIndex((it) => {
      const isFromViewerMessage = it?.Author?.objectId === viewerId;
      const seen = isSeen(it, viewerId);
      return !seen && !isFromViewerMessage;
    });
  };

  const handleSeenMessage = (msg: TMessage) => () => {
    if (!msg?.objectId || !shouldBecomeSeen(msg)) return;
    updateSeenMsg({seen: [toGQLId(ParseEntity.Message, msg.objectId)]});
  };

  useEffect(() => {
    msgsBox.current?.scrollIntoView({block: 'end'});
  }, [loading]);

  useEffect(() => {
    if (!messages?.length) return;
    if (!chatContainer.current) return;
    if (isInitialScrollDone) {
      chatContainer.current.scrollToBottom();
      return;
    }
    if (getFirstUnseenMessageIndex(messages) === -1) {
      chatContainer.current.scrollToBottom();
      return setIsInitialScrollDone(true);
    }

    if (unseenMsgRef) {
      const spaceAbove = 20;
      chatContainer.current.scrollTo(0, unseenMsgRef.offsetTop - spaceAbove);
      return setIsInitialScrollDone(true);
    }
  }, [unseenMsgRef, chatContainer.current, messages?.length]);

  const {actualMessages} = getActualNotifMessages(messages);
  const messageWithStatus = [...actualMessages].reverse()?.find((data) => data.Author?.objectId === viewerId)?.objectId;
  return (
    <>
      {!actualMessages?.length ? (
        <>
          <InnerEmptyCard>
            <EmptyCard
              title={contact?.name ? textsMessages.emptyState.chat.message : textsMessages.emptyState.chat.text1}
              description={contact?.name ? `${textsMessages.emptyState.chat.text2}  ${contact.name}` : ''}
              imageSvg={
                <IconSvg
                  stroke={'none'}
                  type={'no-messages-light'}
                  width={'200px'}
                  height={'200px'}
                  viewBox="0 0 200 200"
                />
              }
            />
          </InnerEmptyCard>
        </>
      ) : (
        <CustomScrollbar
          noScrollX={true}
          ref={chatContainer}
          thumbYProps={{
            // eslint-disable-next-line react/display-name
            renderer: (props: any) => {
              const {elementRef, style, ...restProps} = props;
              return <span style={{...style, ...ThumbStyles}} {...restProps} ref={elementRef} className="tHuMbY" />;
            },
          }}
          trackYProps={{
            // eslint-disable-next-line react/display-name
            renderer: (props: any) => {
              const {elementRef, style, ...restProps} = props;
              return (
                <span
                  {...restProps}
                  ref={elementRef}
                  style={{...style, background: 'transparent'}}
                  className="trackYChat"
                />
              );
            },
          }}>
          <WrapMsgBox ref={msgsBox}>
            {actualMessages?.map((data, index) => {
              const isOwner = data.Author?.objectId === viewerId;
              const name = getUserName({
                first: data?.Author?.firstName,
                last: data?.Author?.lastName,
              });
              const avatar = getResize(data.Author?.Avatar?.file?.url, 'lg');
              const nextMessage = index + 1 < actualMessages?.length ? actualMessages?.[index + 1] : undefined;
              const prevMessage = index - 1 >= 0 ? actualMessages?.[index - 1] : undefined;
              const isDifferentDayNext = checkIsDifferentDay(nextMessage?.createdAt, data?.createdAt);
              const isDifferentDay = checkIsDifferentDay(prevMessage?.createdAt, data?.createdAt);
              const isEndOfBlock = nextMessage?.Author?.objectId !== data.Author?.objectId || isDifferentDayNext;
              const showStatus = messageWithStatus === data.objectId;
              return (
                <MessageBox
                  key={data.objectId}
                  onSeen={handleSeenMessage(data)}
                  name={name}
                  avatar={avatar}
                  message={data}
                  authorId={data.Author?.objectId}
                  isSeen={isSeen(data, data?.ShowTo?.find((it) => it?.objectId !== data.Author?.objectId)?.objectId)}
                  isOwner={isOwner}
                  setRef={getFirstUnseenMessageIndex(actualMessages) === index ? setUnseenMsgRef : null}
                  imagePreviewToggle={imagePreviewToggle}
                  author={data.Author}
                  viewerId={viewerId}
                  isNote={data.type === TypeMsg.internalNote}
                  isNotification={data.type === TypeMsg.notification}
                  isEndOfBlock={isEndOfBlock}
                  isDifferentDay={isDifferentDay}
                  showStatus={showStatus}
                />
              );
            })}
          </WrapMsgBox>
        </CustomScrollbar>
      )}
      {message?.Attachments && message?.Attachments?.length > 0 && (
        <InnerImages>
          {message?.Attachments?.map((img: any, index) => (
            <InnerImgMsg key={img.name}>
              <ImgMsg src={getSourceUrl(img)} alt={'item photo'} />
              <BtnDelImg onClick={handleDeleteImage(index)}>
                <IconSvg type={'close'} width={'10px'} height={'10px'} stroke={'red'} viewBox={'0 0 20 20'} />
              </BtnDelImg>
            </InnerImgMsg>
          ))}
        </InnerImages>
      )}
      {Boolean(contact) && (
        <FormChat>
          <InnerTypePanel>
            <Text size={14} variant={'quaternary'}>
              Type:{' '}
            </Text>
            <SelectType
              onChange={handleChangeType}
              options={messageTypes}
              searchable={false}
              values={messageTypes.filter((el) => el.value === null)}
            />
            <IconSvg type={'info'} height={'20px'} width={'20px'} viewBox={'0 0 24 24'} />
          </InnerTypePanel>

          <ChatInputWrapper>
            <RichTextEditor
              placeholder={'Type a message...'}
              name={'text'}
              value={messageValue}
              onChange={(e) => handleChangeMessage(e.value)}
              rows={8}
              noInitialText={true}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              inputRef={setEditorRef}
              onKeyDown={onEditorKeydown}
            />
            <AddFileWrapper>
              <FileInput
                $isDisable={loading || !contact}
                label={LabelImageButton}
                handleChange={handleChangeImage}
                multiple={true}
              />
            </AddFileWrapper>
            <ChatAreaSend
              onTouchStart={isMobile ? handleSubmit : undefined}
              onClick={!isMobile ? handleSubmit : undefined}>
              <IconSvg
                type={'circled-arrow'}
                width={'24px'}
                height={'24px'}
                stroke={isEmptyMessage ? 'darkgray' : 'aqua'}
              />
            </ChatAreaSend>
          </ChatInputWrapper>
          <div ref={bottom} />
        </FormChat>
      )}
    </>
  );
};
