import React from 'react';
import {MessageBoxProps} from './types';
import {
  MessageBoxWrapper,
  MessageItemWrapper,
  MessageInfoContainer,
  MessageTime,
  MessageItemText,
  InnerImgsMessage,
  ImgElement,
  SoloImg,
} from './styles';
import {getSourceUrl} from '../../utils/helpers';
import {format} from 'date-fns';

const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};

export const MessageBox: React.FC<MessageBoxProps> = ({
  messageText,
  $isInternal,
  images,
  time,
  //imagePreviewToggle,
  linkify,
  Gallery,
  Item,
  isOwner,
  isEnd,
  setIsModal,
  isMobile,
  messageStatus,
  showStatus,
}) => {
  //const imagesForPreview = useMemo(() => images?.map((i) => i?.file.url ?? '') ?? [], [images]);
  /*const handleActiveImg = (src?: string) => () => {
    imagePreviewToggle?.({previewImg: src, images: imagesForPreview});
  };*/
  const onOpen = () => {
    if (!isMobile) return;
    if (typeof document !== 'undefined') {
      setIsModal?.(true);
      const interval = setInterval(() => {
        const element = document?.querySelector('.pswp--open');
        if (!element) {
          clearInterval(interval);
          setIsModal?.(false);
        }
      }, 500);
    }
  };
  return (
    <MessageBoxWrapper $isOwner={isOwner}>
      <MessageItemWrapper $isOwner={isOwner}>
        {/* <Link to={profileLink || '#'}>
          <Avatar src={userAvatar} size={'xs'} />
        </Link> */}
        <MessageInfoContainer $isOwner={isOwner} $isInternal={$isInternal} $isEnd={isEnd}>
          {/* <Link to={profileLink || '#'}>
            <MessageUserName>{userName}</MessageUserName>
          </Link> */}
          <MessageItemText
            $isOwner={isOwner}
            $isInternal={$isInternal}
            dangerouslySetInnerHTML={{
              __html: linkify ? linkify((messageText as string) || '', {target: '_blank'}) : '',
            }}></MessageItemText>
          {images?.length ? (
            images?.length === 1 ? (
              <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options} onOpen={onOpen}>
                <Item original={getSourceUrl(images[0]?.file?.url)} width={'1024'} height={'768'}>
                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
                  /*@ts-ignore*/}
                  {({ref, open}) => (
                    <div>
                      <SoloImg
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src={getSourceUrl(images[0]?.file?.url)}
                        alt={''}
                      />
                    </div>
                  )}
                </Item>
                {/*<ImgMsg
                  src={getSourceUrl(images[0]?.file?.url)}
                  onClick={handleActiveImg(getSourceUrl(images[0]?.file?.url))}
                  alt="image"
                />*/}
              </Gallery>
            ) : (
              <InnerImgsMessage count={images?.length || 0} minWidth={100} maxWidth={300}>
                <Gallery
                  shareButton={false}
                  zoomButton={true}
                  fullscreenButton={false}
                  options={options}
                  onOpen={onOpen}>
                  {/*images?.map((img) => (
                  <div key={img?.file?.name} onClick={handleActiveImg(getSourceUrl(img?.file?.url))}>
                    <Responsive aspectRatio={'1:1'}>
                      <ImgMsg src={getSourceUrl(img?.file?.url)} alt="image" />
                    </Responsive>
                  </div>
                ))*/}
                  {images?.map((img) => (
                    <ImgElement key={img?.file?.name}>
                      <Item original={getSourceUrl(img?.file?.url)} width={'1024'} height={'768'}>
                        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
                        /*@ts-ignore*/}
                        {({ref, open}) => (
                          <img
                            width={148}
                            height={148}
                            ref={ref as React.MutableRefObject<HTMLImageElement>}
                            onClick={open}
                            src={getSourceUrl(img?.file?.url)}
                            alt={''}
                          />
                        )}
                      </Item>
                    </ImgElement>
                  ))}
                </Gallery>
              </InnerImgsMessage>
            )
          ) : null}
        </MessageInfoContainer>
      </MessageItemWrapper>
      {isEnd && (
        <MessageTime>
          {time && format(new Date(time), 'p')}
          {showStatus ? ' ' + messageStatus : ''}
        </MessageTime>
      )}
    </MessageBoxWrapper>
  );
};
