import React, {useState} from 'react';
import {HeaderPage} from '../HeaderPage';
import {HeaderWrapper, PageWrapper, UploadCVSButton} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Modal} from '../common/Modal';
import {UploadPanel} from '../UploadCVS';

export const Communities: React.FC = ({children}) => {
  const [modal, setModal] = useState<boolean>(false);

  const toggleModal = () => {
    setModal(true);
  };

  return (
    <PageWrapper>
      <Modal active={modal} setActive={setModal}>
        <UploadPanel setModal={setModal} />
      </Modal>
      <HeaderWrapper>
        <HeaderPage title="Communities" />
        <UploadCVSButton onClick={toggleModal}>
          <IconSvg type="upload" width="24" height="24" />
          Upload
        </UploadCVSButton>
      </HeaderWrapper>
      {children}
    </PageWrapper>
  );
};
