import gql from 'graphql-tag';
import {CommunityFragment} from './communities';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    objectId
    username
    firstName
    lastName
    email
    Avatar {
      file {
        url
      }
    }
    Communities {
      edges {
        node {
          ...CommunityFragment
        }
      }
    }
    Location {
      name
      Country {
        name
      }
      State {
        name
      }
    }
  }
  ${CommunityFragment}
`;

export const MemberFragment = gql`
  fragment MemberFragment on User {
    id
    objectId
    createdAt
    firstName
    lastName
    email
    Total: Orders {
      count
    }
    # Active:Orders(where: {OR: $statuses}) {
    #   count
    # }
    Lent: Orders(where: {Lister: {exists: true}}) {
      count
    }
    Borrowed: Orders(where: {Requester: {exists: true}}) {
      count
    }
    Communities {
      edges {
        node {
          Admins {
            count
          }
          Residents {
            count
          }
          ...CommunityFragment
        }
      }
    }
    spent
    earned
    status
  }
  ${CommunityFragment}
`;

export const ViewMemberFragment = gql`
  fragment ViewMemberFragment on User {
    objectId
    firstName
    lastName
    Avatar {
      file {
        url
      }
    }
    emailVerified
    Reviews {
      count
    }
    createdAt
    bio
    study
    work
    languages
    Location {
      City {
        name
      }
      State {
        name
      }
    }
    Communities {
      edges {
        node {
          ...CommunityFragment
        }
      }
    }
  }
  ${CommunityFragment}
`;
