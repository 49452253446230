import styled from 'styled-components';
import {LinkButtonWithArrow} from '../Button/LinkButtonWithArrow';
import {Notices} from '../Notifications';
import {Media} from '../theme';
import {Text} from '../Typography/Text';

export const CardWrapper = styled.div<{marginBottom?: number}>`
  width: 300px;
  height: 72px;
  background: ${({theme}) => theme.palette.grayscale._0};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  padding: 9px 13px 10px 19px;
  justify-content: space-between;
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${Media.down.s} {
    width: 100%;
  }
`;

export const Title = styled(Text).attrs({variant: 'quaternary', weight: 'semi-bold', color: 'septenary'})``;

export const Value = styled(Text).attrs({color: 'octonary', size: 20, weight: 'medium'})`
  display: block;
  padding-top: 2px;
  text-overflow: ellipsis;
`;

export const LinkWithArrow = styled(LinkButtonWithArrow)`
  ${({theme}) => theme.font.primary.regular}
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 10px;
  line-height: 15px;
  background: ${({theme}) => theme.palette.grayscale._3};
  display: flex;
  align-items: center;
  padding: 19px 42px 18px 15px;
`;

export const Notification = styled(Notices)`
  color: ${({theme}) => theme.palette.text.primary};
`;

export const ContentWrapper = styled.div<{$haveLink?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({$haveLink}) => $haveLink && 'width: calc(100% - 126px)'}
`;

export const InvoiceCardWrapper = styled.div<{marginTop?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 15px;
  background: ${({theme}) => theme.palette.grayscale._0};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  height: 42px;
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  &::before {
    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    left: 94px;
    border-radius: 100%;
    background-color: ${({theme}) => theme.palette.grayscale._5};
  }
  &::after {
    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    left: 175px;
    border-radius: 100%;
    background-color: ${({theme}) => theme.palette.grayscale._5};
  }
`;

export const Date = styled(Text).attrs({size: 10})`
  display: inline-block;
  width: 96px;
`;

export const Content = styled(Text).attrs({color: 'octonary'})`
  display: inline-block;
  width: 84px;
`;

export const Price = styled(Text).attrs({color: 'octonary'})`
  display: inline-block;
`;

export const ViewLink = styled(Text).attrs({weight: 'semi-bold', size: 10, color: 'octonary'})`
  text-decoration: underline;
`;
