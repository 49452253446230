import React from 'react';
import {TabAll} from './Tabs/TabAll';
import {Tabs} from '../../ui-kit/Tabs/Tabs';
import {Tab} from '../../ui-kit/Tabs/Tab';
import {Members} from '../../components/Members';
import {TabAdmin} from './Tabs/TabAdmin';

const MembersContainer = () => {
  return (
    <Members>
      <Tabs variant="primary">
        <Tab label="All" tabPaddingX="10px">
          <TabAll />
        </Tab>
        <Tab label="Admin" tabPaddingX="10px">
          <TabAdmin />
        </Tab>
        {/*<Tab label="Lenders" tabPaddingX="10px">*/}
        {/*  <TabLenders />*/}
        {/*</Tab>*/}
        {/*<Tab label="Borrowers" tabPaddingX="10px">*/}
        {/*  <TabBorrowers />*/}
        {/*</Tab>*/}
      </Tabs>
    </Members>
  );
};

export default MembersContainer;
