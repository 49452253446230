import gql from 'graphql-tag';

export const DashboardFragment = gql`
  fragment DashboardFragment on AdminDashboardPayload {
    countCommunities
    countManagers
    countMembers
    countListingMembers
    countRequestedListingMembers
    countSuccessfulTransactions
    countListings
    countActiveListings
    countInactiveListings
    countRentalListings
    countSellingListings
    countServiceListings
    countFreeListings
    countPaidListings
    averageListingPrice
    countReviewsAndRatings
    countRevenue
    averageListingRating
    averageMemberRating
    countSubscrRevenue
    countListingsRevenue
  }
`;

export const residentDashboardFragment = gql`
  fragment residentDashboardFragment on ResidentDashboardPayload {
    countListings
    countItemValue
    countOwnTransactions
    countOthersTransactions
    countEarned
    countSpent
    averageRatingReceived
    averageRatingGiven
    countInteractions
    countListingViews
  }
`;

export const managerDashboardFragment = gql`
  fragment managerDashboardFragment on ManagerDashboardPayload {
    countListings
    countMembers
    countTransactions
    countTransactionValue
    averageTransactionRating
    countListingValue
    countLoopPosts
    countLoopPostLikes
  }
`;
