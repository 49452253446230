import styled, {css} from 'styled-components';
import {color} from './types';

export const colors: Record<color, string> = {
  cream: '_3',
  grey: '_11',
};

export const TableWrapper = styled.table`
  border-spacing: 0;
`;

const Tr = styled.tr`
  &::before {
    content: '';
    position: absolute;
    background-color: ${({theme}) => theme.palette.common.gray};
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
  }
`;

export const TableHeaderRow = styled(Tr)<{color?: color}>`
  ${({color, theme}) => color && `background-color: ${theme.palette.grayscale[colors[color]]};`}
  ${({theme}) => theme.font.quaternary.semiBold}
  position: relative;
  height: 45px;
  font-size: 12px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.grayscale._6};
  text-transform: uppercase;
`;

export const TitleWrapper = styled.td<{width?: string; align?: string; padLeft?: string; padRight?: string}>`
  width: ${({width}) => width && width};
  text-align: ${({align}) => align || 'left'};
  padding-left: ${({padLeft}) => padLeft && padLeft};
  padding-right: ${({padRight}) => padRight && padRight};
`;

const inactiveItem = css`
  background-color: ${({theme}) => theme.palette.common.redLight + '66'};
`;

export const TableItem = styled(Tr)<{height?: string; $isInactiveItem?: boolean}>`
  position: relative;
  height: ${({height}) => height && height};
  color: ${({theme}) => theme.palette.common.blueGrayDark};
  font-size: 14px;
  line-height: 17px;
  ${({$isInactiveItem}) => $isInactiveItem && inactiveItem}
  &:hover {
    background-color: ${({theme}) => theme.palette.common.blueLight};
  }
  & > td {
    position: relative;
  }
`;

export const Date = styled.div`
  ${({theme}) => theme.font.quaternary.regular}
  font-size: 12px;
  position: absolute;
  top: 15px;
  left: 17px;
`;
