import styled from 'styled-components';
import ReactSelect from 'react-dropdown-select';
import {Media} from '../../../ui-kit/theme';

export const InnerTypePanel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const SelectType = styled(ReactSelect)`
  &.react-dropdown-select {
    min-width: 110px;
    min-height: 28px;

    border: 0;
    border-radius: 4px;

    padding-left: 10px;

    background-color: ${({theme}) => theme.palette.background.septenary};
  }
`;

export const ContextMenuWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 16px;

  width: 30px;
  height: 30px;
`;

export const DateLine = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  width: 100%;
  ${Media.down.m} {
    font-size: 12px;
  }
  color: ${({theme}) => theme.palette.grayscale._13};
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
`;

export const UiMessageBoxWrapper = styled.div`
  word-break: break-word;
`;
