import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {WrapperProfileCard, InnerProfileCard, InnerLabel} from '../../../../ui-kit/Chat/PersonInfo/styles';

export const SkeletonProfileCard = () => {
  return (
    <WrapperProfileCard style={{width: '100%'}}>
      <Skeleton circle={true} width={'36px'} height={'36px'} />
      <InnerProfileCard>
        <Skeleton width={'100%'} />
        <InnerLabel>
          <Skeleton width={'80%'} />
        </InnerLabel>
        <InnerLabel>
          <Skeleton width={'60%'} />
        </InnerLabel>
      </InnerProfileCard>
    </WrapperProfileCard>
  );
};
