import {planOptions, PlanOptionsValues} from '../../constants/plan';

export type PlanOptionsEntry = {__typename?: string; value: string};

export const toOptionNames = (planOptions: PlanOptionsEntry[]) => {
  return planOptions.map((o) => o.value);
};

export const mergePlanOptions = (planOptions: string[], allOptions: string[]): [string, boolean][] => {
  const merged: [string, boolean][] = [];

  for (const planOption of allOptions) {
    merged.push([planOption, planOptions.includes(planOption)]);
  }

  return merged;
};

export const diff = (planOptions: string[], allOptions: string[]): string[] => {
  return allOptions.filter((o) => !planOptions.includes(o));
};

export const toOptionValues = (currentOptions: string[], allOptions?: string[]) => {
  if (allOptions) {
    return diff(currentOptions, allOptions).map((o) => planOptions[o as PlanOptionsValues]);
  } else {
    return currentOptions.map((o) => planOptions[o as PlanOptionsValues]);
  }
};

export const toParsePlanOptions = (options: [string, boolean][]): string[] =>
  options.filter((opt) => opt[1]).map((opt) => opt[0]);
