import {ViewCommunityType} from '../../../types/communities';
import {PaymentPlan} from '../../../types/payments';

export const getCustomPlanName = (community: ViewCommunityType | undefined) =>
  `${community?.alias}_${community?.Location?.name}_${community?.Location?.Country?.name}`;

export const getNewPlan = (current: Partial<PaymentPlan>, name: string) => ({
  name,
  options: current.options?.map((o) => o.value),
  descr: current.descr,
  communityFee: current.communityFee,
  extraListing: current.extraListing,
  extraResident: current.extraResident,
  listingCap: current.listingCap,
  priceMonth: current.priceMonth,
  priceYear: current.priceYear,
  rentFees: current.rentFees,
  residentCap: current.residentCap,
  salesFees: current.salesFees,
  serviceFees: current.serviceFees,
  isPublic: false,
  Location: {
    link: current.Location?.id,
  },
  Currency: {
    link: current.Currency?.id,
  },
});
