import React, {useState} from 'react';
import {InnerSelect, LabelSelect, StyledSelect, WrapperItem} from './styles';
import {CSSProperties} from 'styled-components';
import {SelectItemRenderer} from 'react-dropdown-select';

export interface PropsSelect {
  label?: string;
  dropdownHandle?: boolean;
  className?: string;
  style?: CSSProperties;
  values: any;
  options: any;
  multi?: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
  name: string;
  width?: string;
  height?: string;
  $isError?: boolean;
  searchable?: boolean;
}
// eslint-disable-next-line
export const Select = <T extends any>({
  className,
  name,
  values,
  onChange,
  options,
  label,
  width,
  height,
  $isError,
  searchable,
  ...props
}: PropsSelect) => {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };
  const ItemRenderer = <T,>({item, props, methods}: SelectItemRenderer<T>) => {
    const addItem = () => methods.addItem(item);
    return (
      <WrapperItem
        isChecked={methods.isSelected(item)}
        key={(item as any)[props.valueField as string]}
        onClick={addItem}>
        {(item as any)[props.labelField as string]}
      </WrapperItem>
    );
  };

  const handleChange = (value: any) => {
    onChange(value);
  };

  return (
    <InnerSelect
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
      $isError={$isError}
      height={height}
      width={width}>
      {label && (
        <LabelSelect $isActive={isActive || values.length} $isError={$isError}>
          {label}
        </LabelSelect>
      )}
      <StyledSelect
        name={name}
        placeholder={''}
        className={'select-filter ' + className}
        values={values}
        onChange={handleChange}
        options={options}
        itemRenderer={ItemRenderer}
        searchable={searchable}
        {...props}
      />
    </InnerSelect>
  );
};
