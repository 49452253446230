import styled from 'styled-components';

import {mode, navOffsetsT, variant, viewMode} from './types';
import {Avatar as AvatarUI} from '../Avatar/Avatar';
import {Button} from '../Button/Button';
import {IconSvg} from '../Icon/Svg';
import {color} from '../Icon/Svg/types';
import {Notices as UINotices} from '../Notifications';
import {Media} from '../theme';
import {Link} from '../Typography/Link';
import {Text} from '../Typography/Text';
import {TextColor} from '../Typography/types';
import {isFromApp} from './mobile/styles';

export const colors: Record<variant, TextColor> = {
  admin: 'primary',
  web: 'octonary',
};

export const backgroundColors: Record<variant, string> = {
  admin: 'senary',
  web: 'primary',
};

const strokeColorLight: Record<variant, color> = {
  admin: 'lightGray',
  web: 'darkgray',
};

const strokeColorDark: Partial<Record<variant, color>> = {
  web: 'lightGray',
};

export const getNavStrokeColor = (variant: variant, mode?: mode) => {
  switch (mode) {
    case 'light':
      return strokeColorLight[variant];
    case 'dark':
      return strokeColorDark[variant];
    default:
      return strokeColorLight[variant];
  }
};

const strokeColor: Record<mode, color> = {
  light: 'darkgray',
  dark: 'lightGray',
};

export const getStrokeColor = (mode?: mode) => {
  return mode ? strokeColor[mode] : strokeColor.light;
};
const appTopOffset = isFromApp() ? '60px' : '68px';
export const WrapperContent = styled.div<{
  $isOpenNav: boolean;
  viewMode?: viewMode;
  $hideNav?: boolean;
  navOffsets?: navOffsetsT;
}>`
  margin-top: 60px;
  margin-left: ${({$isOpenNav}) => ($isOpenNav ? '255px' : '73px')};
  background-color: ${({theme}) => theme.palette.background.nonary};
  min-height: calc(100vh - 60px);
  ${Media.down.l} {
    min-height: ${({viewMode}) =>
      viewMode === 'hideNavOnMobile' ? 'calc(100vh - 16px)' : isFromApp() ? '85vh' : 'calc(100vh - 172px)'};
    margin: ${({viewMode, navOffsets}) =>
      navOffsets?.remove ? '0px' : viewMode === 'hideNavOnMobile' ? '16px 0 0 0' : `${appTopOffset} 0 78px`};
  }
  ${Media.down.m} {
    margin: ${({viewMode, $hideNav}) =>
      viewMode === 'hideNavOnMobile' ? '16px 0 0 0' : $hideNav ? '0px 0 0px' : `${appTopOffset} 0 78px`};
    ${({navOffsets}) => navOffsets?.remove && `min-height: 100vh; overflow: hidden;`}
    ${({navOffsets}) => navOffsets?.mOffset && `margin: ${navOffsets?.mOffset};`}
    ${({navOffsets}) => navOffsets?.pOffset && `padding: ${navOffsets?.pOffset};`}
  }
  box-sizing: border-box;
`;

export const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const MenuRow = styled.div<{$isActive?: boolean}>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  & > div {
    z-index: 2;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  &::after {
    content: '';
    width: 100%;
    height: 42px;
    background-color: ${({$isActive, theme}) => ($isActive ? theme.palette.grayscale._20 : 'transparent')};
    display: block;
    top: -9px;
    left: -12px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    transition: 0.1s all;
  }
  svg {
    overflow: visible;
  }
`;

export const MenuIconSvg = styled(IconSvg).attrs({width: '24px', height: '24px'})<{
  marginRight?: string;
}>`
  margin-right: ${({marginRight}) => marginRight || '10px'};
  width: 24px;
  height: 24px;
`;

export const MenuHeaderText = styled.span`
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuHeaderDropdownIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: -4px;
  &:hover {
    opacity: 0.5;
  }
`;

const ColoredDiv = styled.div<{variant: variant; $isOpenNav: boolean}>`
  background-color: ${({theme, variant}) => theme.palette.background[backgroundColors[variant]]};
  ${Media.down.l} {
    visibility: hidden;
    display: none;
  }
`;

export const Bar = styled(ColoredDiv)`
  width: ${({$isOpenNav}) => ($isOpenNav ? '255px' : '73px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  box-sizing: border-box;
  top: 61px;
  left: 0;
  bottom: 0;
  ${({theme, variant}) => variant === 'web' && `border-right: 1px solid ${theme.palette.background.secondary};`}
  z-index: ${({theme}) => theme.zIndex._5};
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  ul {
    list-style-type: none;
    padding-left: 25px;
    li {
      margin-bottom: 14px;
      cursor: pointer;
      position: relative;
    }
  }
`;

export const Footer = styled.div`
  padding: 14px 18px 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
`;

export const HeaderWrapper = styled.div<{viewMode?: viewMode; $isHidden?: boolean; $scrolled?: boolean}>`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  min-height: 61px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  background-color: ${({theme}) => theme.palette.background.primary};
  z-index: ${({theme}) => theme.zIndex._3};
  transition: 0.25s all;
  ${Media.down.s} {
    border-bottom: 1px solid transparent;
    ${({$scrolled, theme}) =>
      $scrolled &&
      `
      border-bottom: 1px solid ${theme.palette.background.secondary};
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    `}
    ${({viewMode}) => viewMode === 'hideNavOnMobile' && 'display: none;'}
    ${({$isHidden}) => $isHidden && ` display: none;`};
  }
`;

export const HeaderMenu = styled(ColoredDiv)`
  box-sizing: border-box;
  width: 255px;
  padding: 18px 25px;
  ${({theme, variant, $isOpenNav}) =>
    variant === 'web' && $isOpenNav && `border-right: 1px solid ${theme.palette.background.secondary};`};
`;

export const Header = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-left: 25px;
  z-index: ${({theme}) => theme.zIndex._5};
  box-sizing: border-box;
  ${Media.up.l} {
    padding-right: 8px;
  }
`;

export const HeaderRightSide = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-start;
  padding-left: 25px;
  z-index: ${({theme}) => theme.zIndex._5};
`;

export const WrapperHeaderWeb = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${Media.down.l} {
    justify-content: space-between;
    padding: 15px 17px 5px 20px;
    width: 100%;
    ${isFromApp() ? ` padding-top: 0px;` : ''}
  }
`;

export const SmallText = styled(Text).attrs({size: 10})`
  position: relative;
  left: -6px;
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
`;

export const MenuItemText = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const MenuContentText = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Notices = styled(UINotices)`
  top: initial;
  left: calc(100% + 4px);
  color: ${({theme}) => theme.palette.text.primary};
`;

export const GoBackWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
`;
export const GoBackText = styled.div`
  font-size: 14px;
  ${({theme}) => theme.font.primary.semiBold}
  color: ${({theme}) => theme.palette.text.octonary};
  margin-left: 6px;
`;

export const TopContent = styled.div<{$scrolled?: boolean; $isUser?: boolean}>`
  margin-right: 9px;
  ${({theme}) => theme.font.primary.semiBold}
  color: ${({theme}) => theme.palette.grayscale._19};
  font-size: 12px;
  ${Media.down.l} {
    display: flex;
    align-items: end;
    white-space: nowrap;
    ${({theme}) => theme.font.primary.bold};
    font-size: ${({$isUser}) => ($isUser ? '18px' : '23px')};
    order: -1;
    flex-grow: 1;
    transition: 0.15s all;
  }
  ${Media.down.m} {
    max-width: 220px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: clip;
    svg {
      margin-left: 6px;
    }
  }
`;

export const CommNameLabel = styled.div`
  ${({theme}) => theme.font.primary.regular};
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const InnerDataUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogOutBtn = styled(Button).attrs({width: '64px', height: '19px', ghost: true, variant: 'secondary'})`
  ${({theme}) => theme.font.primary.regular}
  color: ${({theme}) => theme.palette.text.quindecimal};
  font-size: 10px;
  padding: 0;
  margin: 0 17px;
  border-width: 1.5px;
  & .button-value {
    margin: 0;
  }
  ${Media.down.l} {
    display: none;
  }
`;

export const IsNoticesLbl = styled.div<{top?: string}>`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${({theme}) => theme.palette.common.red};
  right: -1px;
  border-radius: 100%;
  ${({top}) => top && `top: ${top};`}
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
`;

export const NoticesWrapper = styled.div`
  cursor: pointer;
`;

export const NoticesIconWrapper = styled.div`
  position: relative;
  max-height: 24px;
  max-width: 24px;
  flex-grow: 1;
  ${Media.down.l} {
    flex-grow: 0;
  }
`;

export const DashboardIconWrapper = styled.div`
  visibility: hidden;
  ${Media.down.l} {
    visibility: visible;
    max-height: 24px;
    flex-grow: 0;
    padding: 0 15px;
  }
`;

export const Avatar = styled(AvatarUI)`
  width: 24px;
  height: 24px;
  ${Media.down.l} {
    padding: 2px;
    border: solid 1.5px ${({theme}) => theme.palette.text.octonary};
  }
`;

export const Hidden = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const HiddenTopBlock = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const StyledLink = styled(Link).attrs({as: 'span'})`
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
`;

export const PageOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
`;

export const PopupContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const AddButtonWrapper = styled.div`
  ${Media.down.s} {
    display: none;
  }
`;

export const CheckListButtonWrapper = styled.div`
  width: 250px;
  background: ${({theme}) => theme.palette.background.primary};
  border: none;
  cursor: pointer;
  padding: 6px 39px 6px 36px;
  position: relative;
  ${Media.down.s} {
    display: none;
  }
`;

export const DropDownBtnForCheck = styled.button<{$isOpened?: boolean}>`
  display: flex;
  background: transparent;
  outline: none;
  border: none;
  width: 30px;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 3px;
  cursor: pointer;
  transition: 0.2s all;
  transform: rotate(0deg);
  ${({$isOpened}) => $isOpened && 'transform: rotate(180deg);'};
`;

export const ChecklistStepsCont = styled.div`
  position: absolute;
  left: -12px;
  top: -3px;
  ${Media.down.m} {
    top: -1px;
    left: -7px;
  }
  & span {
    position: relative;
    top: 10px;
    left: 6px;
    ${Media.down.m} {
      top: 8px;
      left: 6px;
    }
  }
  & div:last-child {
    background: white;
  }
`;

export const SettingsButton = styled.div`
  cursor: pointer;
`;
