import styled, {css} from 'styled-components';

export const sizeOptions = <const>['xl', 'lg', 'md', 'sm', 'xs', 'xxs'];
export type size = typeof sizeOptions[number];

const sizes: Record<size, string> = {
  xxs: '30px',
  xs: '31px',
  sm: '36px',
  md: '53px',
  lg: '75px',
  xl: '126px',
};

const OnlineDotStyles = css`
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 7%;
  bottom: 10%;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid ${({theme}) => theme.palette.grayscale._0};
  background: ${({theme}) => theme.palette.common.aqua};
`;

const dotSmall = css`
  right: 2px;
  bottom: 0;
  width: 12px;
  height: 12px;
`;

const dotMedium = css`
  right: 2px;
  bottom: 2px;
  width: 12px;
  height: 12px;
`;

export const Wrapper = styled.div<{size?: size; $isOnline?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({size}) => (size ? sizes[size] : '100%')};
  height: ${({size}) => (size ? sizes[size] : '100%')};
  position: relative;
  box-sizing: border-box;
  border-radius: 100%;
  flex-shrink: 0;

  &:after {
    ${(props) => props.$isOnline && OnlineDotStyles};
    ${({size}) => size === 'sm' && dotSmall};
    ${({size}) => size === 'md' && dotMedium};
  }
`;

const cssLoading = css`
  width: 17px;
  height: 17px;
  animation: 2s linear 0s normal none infinite rotate;

  @keyframes rotate {
    0% {
      transform: rotateZ(360deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
`;

export const Image = styled.img<{$visibility: boolean; $loading?: boolean}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  border-radius: inherit;
  box-sizing: border-box;
  ${({$visibility}) => !$visibility && 'visibility: hidden'};
  ${({$loading}) => $loading && cssLoading};
`;
