import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  & > div > div > div > div > span:last-of-type {
    max-width: 190px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProfileIntroWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 41px 46px 36px 38px;
  margin-bottom: 26px;
`;

export const CustomPlanWrapper = styled.div`
  & a {
    background-color: transparent;
    width: max-content;
    border: none;
    cursor: pointer;
    padding: 0;

    &::after {
      cursor: pointer;
      pointer-events: all;
    }
  }
`;
