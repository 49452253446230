import {USER_TYPE} from '../types/user';

interface IGetUserName {
  (params: {type?: USER_TYPE | null; first?: string; last?: string; showFull?: boolean}): string;
}

export const getUserName: IGetUserName = ({type, first, last, showFull}) => {
  const base = `${first} ${last}`;
  if (type === USER_TYPE.communityAdmin || type === USER_TYPE.manager || showFull) return base;

  const lastName = last ? last?.[0] + '.' : '';
  return `${first} ${lastName}`;
};
