import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {Button} from '../Button/Button';
import {LinkButton} from '../Button/LinkButton';
import {Media} from '../theme';

export const MenuWrapper = styled.td`
  position: relative;
  width: 65px;
  padding: 0;
`;

export const MenuItemsWrapper = styled.div`
  padding: 5px;
  position: absolute;
  right: 2px;
  top: 20%;
  border-radius: 12px;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({theme}) => theme.palette.grayscale.white};
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-right: 19px;
`;

export const IconClose = styled.span<{right?: boolean}>`
  width: 20px;
  height: 20px;
  background-color: ${({theme}) => theme.palette.grayscale.white};
  border-radius: 20px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  ${({right}) => (right ? 'right: -8px;' : 'left: -8px;')};
  z-index: 1;
  box-shadow: 0 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
`;

export const MoreButtonLink = styled(LinkButton).attrs({
  align: 'left',
  color: 'white',
  width: '144px',
  height: '27px',
})``;

export const MoreButton = styled(Button).attrs({align: 'left', color: 'white', width: '144px', height: '27px'})``;

export const Line = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  margin: 5px 0;
`;

type textColor = 'red' | 'green';

const colors: Record<textColor, string> = {
  red: 'redDark',
  green: 'green',
};

export const BoldText = styled.span<{color?: textColor}>`
  position: relative;
  color: ${({
    color,
    theme: {
      palette: {common},
    },
  }) => (color && common[colors[color]]) || common.purpleDark};
  ${({theme}) => theme.font.quaternary.regular}
  letter-spacing: 5%;
  font-size: 14px;
`;

export const DisabledWrapper = styled.div`
  pointer-events: none;
`;

// mobile menu

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
  padding: min(50px, 8vw);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  ${Media.down.l} {
    justify-content: flex-end;
    align-items: flex-end;
  }
  pointer-events: all;
`;

export const MobileMenuWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 6px;
`;

const menuEntryStyles = css<{type?: 'regular' | 'danger'}>`
  width: 100%;
  padding: 15px min(77px, 10vw);
  position: relative;
  box-sizing: border-box;
  color: ${({theme, type}) => (type === 'danger' ? theme.palette.common.red : theme.palette.text.enneadecimal)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  ${({theme}) => theme.font.primary.regular};
  cursor: pointer;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: ${({theme}) => theme.palette.background.tertdecimal};
  }
`;

export const MobileMenuEntry = styled.div<{type?: 'regular' | 'danger'}>`
  ${menuEntryStyles};
`;

export const MobileMenuEntryLink = styled(Link)<{type?: 'regular' | 'danger'}>`
  ${menuEntryStyles};
`;
