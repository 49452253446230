import {gql} from 'apollo-boost';
import {MemberFragment, UserFragment, ViewMemberFragment} from './fragments/user';
import {User} from '../types/user';

export const getMembers = gql`
  query getMembers($first: Int, $skip: Int, $where: UserWhereInput) {
    users(first: $first, skip: $skip, where: $where) {
      count
      edges {
        node {
          ...MemberFragment
        }
      }
    }
  }
  ${MemberFragment}
`;

export const getUsersByRole = gql`
  query getUsers($first: Int, $skip: Int, $role: String) {
    roles(where: {name: {equalTo: $role}}) {
      edges {
        node {
          users(first: $first, skip: $skip) {
            count
            edges {
              node {
                ...MemberFragment
              }
            }
          }
        }
      }
    }
  }
  ${MemberFragment}
`;

export const getLenders = gql`
  query getLenders($first: Int, $skip: Int) {
    orders(first: $first, skip: $skip, where: {Lister: {exists: true}}) {
      count
      edges {
        node {
          Lister {
            ...MemberFragment
          }
        }
      }
    }
  }
  ${MemberFragment}
`;

export const getBorrowers = gql`
  query getBorrowers($first: Int, $skip: Int) {
    orders(first: $first, skip: $skip, where: {Requester: {exists: true}}) {
      count
      edges {
        node {
          Requester {
            ...MemberFragment
          }
        }
      }
    }
  }
  ${MemberFragment}
`;

export const getMember = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      ...ViewMemberFragment
    }
  }
  ${ViewMemberFragment}
`;

export const updateMember = gql`
  mutation updateMember($id: ID!, $fields: UpdateUserFieldsInput) {
    updateUser(input: {id: $id, fields: $fields}) {
      user {
        ...MemberFragment
      }
    }
  }
  ${MemberFragment}
`;

export type TQueryGetUserResponse = {
  user: User;
};

export type TQueryGetUserRequest = {
  id: string;
};

export const QueryGetUser = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
