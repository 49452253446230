import React from 'react';
import {IconSvg} from '../../Icon/Svg';
import {MenuItemWrapper as MenuItemWrapperLink, MenuItemWrapperText, MobileMenuWrapper} from './styles';
import {MenuItemType, MobileNavMenuProps, mode} from '../types';
import {getNavStrokeColor, IsNoticesLbl, NoticesIconWrapper} from '../styles';
import {LinkProps} from '../../Typography/Link';

const MenuItemWrapper: React.FC<LinkProps> = ({to, ...props}) => {
  return to ? <MenuItemWrapperLink to={to || ''} {...props} /> : <MenuItemWrapperText {...props} />;
};

const MenuItem: React.FC<{item: MenuItemType; currentMenuName: string; mode?: mode}> = ({
  item,
  currentMenuName,
  mode,
}) => {
  const isActive = currentMenuName === item.path;

  if (!item.content) return null;
  return (
    <>
      <MenuItemWrapper to={item.to || ''} onClick={item.onClick}>
        <NoticesIconWrapper>
          <IconSvg
            viewBox="0 0 26 26"
            fill={isActive ? 'aqua-2' : undefined}
            stroke={isActive ? 'aqua' : getNavStrokeColor('web', mode)}
            type={item.type}
          />
          {item.notices && <IsNoticesLbl top="0" />}
        </NoticesIconWrapper>
        {item.content}
      </MenuItemWrapper>
      {item.children}
    </>
  );
};

export const MobileMenu: React.FC<MobileNavMenuProps> = ({menuItemsMobile, ...props}) => {
  return menuItemsMobile ? (
    <MobileMenuWrapper viewMode={props.viewMode} $isHidden={props.$isHidden}>
      {menuItemsMobile
        .filter((el) => !!el.type)
        .map((it, i) =>
          it.render ? (
            it.render(it, props.variant, props.mode, props.logout, props.logoutText)
          ) : (
            <MenuItem item={it} key={i} {...props} />
          ),
        )}
    </MobileMenuWrapper>
  ) : null;
};
