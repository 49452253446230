import {Location} from './communities';

export enum PaymentPlanName {
  free = 'Free',
  starterCAN = 'Starter (CAN)',
  basicCAN = 'Basic (CAN)',
  proCAN = 'Pro (CAN)',
  starterUSA = 'Starter (USA)',
  basicUSA = 'Basic (USA)',
  proUSA = 'Pro (USA)',
}

export interface Currency {
  name: string;
  code: string;
  sign: string;
  id?: string;
  __typename?: string;
}

export interface PaymentPlan {
  id: string;
  objectId: string;
  name: PaymentPlanName | string;
  descr: string;
  priceYear: number;
  priceMonth: number;
  options: Array<any>;
  listingCap: number;
  residentCap: number;
  extraListing: number;
  extraResident: number;
  communityFee: boolean;
  rentFees?: Fees;
  serviceFees?: Fees;
  salesFees?: Fees;
  isPublic?: boolean;
  Location?: Location;
  Currency?: Currency;
  serviceExternal?: boolean;
  rentalExternal?: boolean;
  salesExternal?: boolean;
  __typename?: string;
}

export interface CreatePaymentPlanType extends Partial<Omit<Omit<PaymentPlan, 'Currency'>, 'Location'>> {
  Location?: {
    link?: string;
  };
  Currency?: {
    link?: string;
  };
}

export interface UpdatePaymentPlan {
  id?: string;
  objectId: string;
  name?: string;
  descr?: string;
  status?: string;
  priceYear?: number;
  priceMonth?: number;
  listingCap?: number;
  residentCap?: number;
  extraListing?: number;
  extraResident?: number;
  communityFee?: boolean;
  rentFees?: Fees;
  serviceFees?: Fees;
  salesFees?: Fees;
  isPublic: boolean;
  options?: any[];
  serviceExternal?: boolean;
  rentalExternal?: boolean;
  salesExternal?: boolean;
}

export enum ExternalFields {
  serviceExternal = 'serviceExternal',
  rentalExternal = 'rentalExternal',
  salesExternal = 'salesExternal',
}

export interface Fees {
  hFee?: {
    hfListerPrc?: number;
    hfListerAbs?: number;
    hfRequesterPrc?: number;
    hfRequesterAbs?: number;
  };
  cFee?: {
    cfListerPrc?: number;
    cfListerAbs?: number;
    cfRequesterPrc?: number;
    cfRequesterAbs?: number;
  };
  Pp?: {
    ppListerPrc?: number;
    ppListerAbs?: number;
    ppRequesterPrc?: number;
    ppRequesterAbs?: number;
  };
}
