import gql from 'graphql-tag';
import {UserFragment} from './user';

export const ItemFragment = gql`
  fragment ItemFragment on Item {
    objectId
    createdAt
    name
    listingType
    rewardType
    price
    Lister {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
