import React, {PropsWithChildren} from 'react';

import {ButtonProps} from './types';
import {ButtonBaseRoot} from './styles';

// eslint-disable-next-line @typescript-eslint/ban-types
type $object = object;

type Override<T extends $object, O extends $object> = {[K in Exclude<keyof O, keyof T>]?: O[K]} &
  {[K in Extract<keyof T, keyof O>]?: O[K]};

type ButtonBaseProps<T extends $object> = {
  element: keyof JSX.IntrinsicElements | React.ComponentType<T>;
  className?: string;
} & Override<ButtonProps, T>;

export const ButtonBase = <T extends $object>({
  children,
  className,
  element,
  ...props
}: PropsWithChildren<ButtonBaseProps<T>>) => {
  return (
    <ButtonBaseRoot {...props} as={element as React.ComponentType<any>} className={className} spellCheck="false">
      {children}
    </ButtonBaseRoot>
  );
};
