import React from 'react';
import {IPersonInfo} from './types';
import {InfoCard, ProfileCard, Section} from './components';
import {InnerContent} from './styles';

export const PersonInfo: React.FC<IPersonInfo> = ({labels, person, insights, communities}) => {
  return (
    <InnerContent>
      <Section label={labels.person}>
        <ProfileCard
          name={person.name}
          label={person.label}
          labelVariant={person.labelVariant}
          avatar={person.avatar}
          listings={person.listings}
          link={person.link}
          arrow
        />
        <InfoCard label={labels.location} value={person.location} />
      </Section>

      <Section label={labels.insights}>
        {insights.map((el) => (
          <InfoCard label={el.label} value={el.value} key={el.label} short />
        ))}
      </Section>

      <Section label={labels.communities}>
        {communities.map((el) => (
          <ProfileCard name={el.name} avatar={el.avatar} members={el.members} link={el.link} key={el.name} />
        ))}
      </Section>
    </InnerContent>
  );
};
