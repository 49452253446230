import styled from 'styled-components';
import {Text} from '../Typography/Text';

export const SNotices = styled(Text).attrs({})<{className?: string}>`
  ${({theme}) => theme.font.tertiary.regular};
  position: absolute;
  right: -8px;
  min-width: ${({width}) => width || '16px'};
  max-width: 20px;
  height: ${({width}) => width || '16px'};
  max-height: 20px;
  display: flex;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.common.red};
  color: ${({theme}) => theme.palette.text.lightCream};
  font-size: 10px;
  align-items: center;
  justify-content: center;
  top: -9px;
  box-sizing: border-box;
  z-index: ${({theme}) => theme.zIndex._1};
`;
