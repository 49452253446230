import React from 'react';
import styled from 'styled-components';
import {Text} from '../../../ui-kit/Typography/Text';

const InnerNotification = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({theme}) => theme.palette.background.septenary};
  border-radius: 4px;

  height: 32px;
  padding: 0 16px;
`;

interface IProps {
  text: string;
}

export const Notification: React.FC<IProps> = ({text}) => {
  return (
    <InnerNotification>
      <Text size={12} color={'octonary'}>
        {text}
      </Text>
    </InnerNotification>
  );
};
