import {useMutation, useQuery} from '@apollo/react-hooks';
import {useSetRecoilState} from 'recoil';
import {GQLPaymentPlanWhereInput, GQLUpdatePaymentPlanInput} from '../graphql.schema';
import {PaymentPlanFragment} from '../queries/fragments/payments';
import {
  CreatePaymentPlan,
  CreatePaymentPlanRequest,
  CreatePaymentPlanResponse,
  CreateSubscr,
  CreateSubscrRequest,
  CreateSubscrResponse,
  paymentsPlan as paymentsPlanQuery,
  updatePaymentPlan as updatePaymentPlanQuery,
  UpdatePaymentPlanResponse,
} from '../queries/payments';
import {isLoading} from '../states/load';
import {Edge} from '../types/parse';
import {CreatePaymentPlanType, PaymentPlan, PaymentPlanName, UpdatePaymentPlan} from '../types/payments';

export const useGetPaymentPlan = (planName: PaymentPlanName | string) => {
  const setIsLoading = useSetRecoilState(isLoading);
  const {data, loading} = useQuery<{paymentPlans: Edge<PaymentPlan>}, {where: GQLPaymentPlanWhereInput}>(
    paymentsPlanQuery,
    {
      variables: {
        where: {name: {equalTo: planName}},
      },
    },
  );
  setIsLoading(loading);
  const plan = data?.paymentPlans.edges[0]?.node;
  return {data: plan && [plan]};
};

export const useFetchPaymentPlan = () => {
  const setIsLoading = useSetRecoilState(isLoading);
  const {refetch, loading} = useQuery<{paymentPlans: Edge<PaymentPlan>}, {where: GQLPaymentPlanWhereInput}>(
    paymentsPlanQuery,
    {
      skip: true,
      fetchPolicy: 'cache-and-network',
    },
  );
  setIsLoading(loading);

  return async (planName: PaymentPlanName | string) => {
    const response = await refetch({
      where: {name: {equalTo: planName}},
    });
    const plan = response?.data?.paymentPlans?.edges[0]?.node;
    return {
      data: plan,
      loading: response?.loading,
    };
  };
};

export const useUpdatePaymentPlan = () => {
  const setIsLoading = useSetRecoilState(isLoading);
  const [update, {loading, client}] = useMutation<UpdatePaymentPlanResponse, {input: GQLUpdatePaymentPlanInput}>(
    updatePaymentPlanQuery,
  );
  setIsLoading(loading);

  const updatePaymentPlan = async (paymentPlan: UpdatePaymentPlan) => {
    try {
      const {objectId, ...fields} = paymentPlan;
      const {data} = await update({variables: {input: {id: objectId, fields}}});
      data &&
        client.writeFragment({
          id: `PaymentPlan:${data.updatePaymentPlan.paymentPlan.id}`,
          fragment: PaymentPlanFragment,
          data: data.updatePaymentPlan.paymentPlan,
        });
    } catch (e) {
      console.log(e);
    }
  };

  return {updatePaymentPlan};
};

export const useCreatePaymentPlan = () => {
  const setIsLoading = useSetRecoilState(isLoading);
  const [create, {loading}] = useMutation<CreatePaymentPlanResponse, CreatePaymentPlanRequest>(CreatePaymentPlan);
  setIsLoading(loading);

  return async (fields: Partial<CreatePaymentPlanType>) => {
    const {data} = await create({
      variables: {
        input: {
          fields,
        },
      },
    });

    return data;
  };
};

export const useCreateSubscr = () => {
  const setIsLoading = useSetRecoilState(isLoading);
  const [create, {loading}] = useMutation<CreateSubscrResponse, {fields: CreateSubscrRequest}>(CreateSubscr);
  setIsLoading(loading);

  return async (fields: Partial<CreateSubscrRequest>) => {
    const {data} = await create({
      variables: {
        fields,
      },
    });

    return data;
  };
};
