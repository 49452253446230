import {FileInfo} from './file';

// eslint-disable-next-line @typescript-eslint/ban-types
type AnyFunction = Function;

export const isFunction = (value: unknown): value is AnyFunction => {
  return typeof value === 'function';
};

export const isServer = (): boolean => {
  return typeof window === 'undefined';
};

export const isFile = (value: unknown): value is File => {
  return value instanceof File;
};

export const isFileInfo = (value: unknown): value is FileInfo => {
  return isString((value as FileInfo)?.url) && isString((value as FileInfo)?.name);
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};
