import React from 'react';
import {useParams} from 'react-router-dom';
import {View} from '../../components/Members/View';
import {useGetMemberById} from '../../hooks/members';

export const Member = () => {
  const {id} = useParams<{id: string}>();
  const {user, dashboard} = useGetMemberById(id);

  if (!user || !dashboard) return null;
  return <View user={user} dashboard={dashboard} />;
};
