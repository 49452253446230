import React from 'react';
import {Header, TitlePage} from './styles';

export const HeaderPage: React.FC<{title: string}> = ({title}) => {
  return (
    <Header>
      <TitlePage>{title}</TitlePage>
    </Header>
  );
};
