import React, {SetStateAction} from 'react';
import {Header, Main, Wrapper} from './styles';
import {Pagination} from '../../ui-kit/Pagination';
import {SearchField} from './SearchField';

export interface BrowserDataProps<T> {
  count: number;
  initialItems?: T[];
  setItems?: React.Dispatch<React.SetStateAction<T[]>>;
  onSearch?: (match: string) => Promise<T[] | undefined>;
  children: JSX.Element | JSX.Element[];
  setIsSearchData?: React.Dispatch<SetStateAction<boolean>>;
}

export const BrowserData = <T,>({
  children,
  count,
  initialItems,
  setItems,
  onSearch,
  setIsSearchData,
}: BrowserDataProps<T>) => {
  return (
    <Wrapper>
      <Header>
        <SearchField<T>
          initialItems={initialItems}
          setItems={setItems}
          onSearch={onSearch}
          setIsSearchData={setIsSearchData}
        />
      </Header>
      <Main>{children}</Main>
      <Pagination count={count} />
    </Wrapper>
  );
};
