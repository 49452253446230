import {gql} from '@apollo/client';
import {GQLUpdateAppFileFieldsInput} from '../graphql.schema';
import {AppFileFragment} from './fragments/file';
import {FileInfo} from '../types/parse';
import {User} from '../types/user';

export const CreateAppFileQuery = gql`
  mutation createAppFile($fields: CreateAppFileFieldsInput) {
    createAppFile(input: {fields: $fields}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface CreateFileResponseType {
  createAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string};
  };
}

export const UpdateAppFileQuery = gql`
  mutation updateAppFile($id: ID!, $fields: UpdateAppFileFieldsInput) {
    updateAppFile(input: {id: $id, fields: $fields}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface UpdateFileResponseType {
  updateAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string};
  };
}

export interface UpdateFileRequestType {
  id: string;
  fields: GQLUpdateAppFileFieldsInput;
}

export const DeleteAppFileQuery = gql`
  mutation deleteAppFile($id: ID!) {
    deleteAppFile(input: {id: $id}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface DeleteFileResponseType {
  deleteAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string};
  };
}

export interface DeleteFileRequestType {
  id: string;
}
