export const textsMessages = {
  messaging: 'Messages',
  messages: 'messages',
  requests: 'Requests',
  requestInfo: 'Request info',
  transactions: 'Transactions',
  column: {
    bookings: 'Bookings',
    bookingInfo: 'Booking info',
  },
  emptyState: {
    messaging: {
      text1: 'Hmm... No messages yet?',
      text2: 'This is where your messages will show up',
    },
    chat: {
      text1: 'Select a person to see messages',
      message: 'Write your first message',
      text2: 'This is the beginning of your conversation with ',
    },
    requests: {
      text1: 'No bookings found',
      text2: 'You don’t have any booking requests with ',
    },
  },
  status: {
    seen: 'Seen',
    delivered: 'Delivered',
    internalNote: 'Internal note',
  },
  placeholderMsgInput: 'Type a message...',
};
