import React from 'react';
import {MenuItemsWrapper, IconClose, MoreButtonLink, Line, BoldText, DisabledWrapper, MoreButton} from './styles';
import {IconSvg} from '../Icon/Svg';
import {MenuType, MenuItemType, renderMenuItemType} from './types';

const getButton = (isBtn: boolean) => {
  return isBtn ? MoreButton : MoreButtonLink;
};

const primaryButton = (el: MenuItemType, id: string) => {
  const Button = getButton(!!el.onClick);
  const fn = () => el.onClick && el.onClick(id);
  const attrs = {to: (el.to && el.to(id)) || '', onClick: fn};
  return !el.to && !el.onClick ? (
    <DisabledWrapper key={id}>
      <Button {...attrs} key={el.title}>
        <BoldText>{el.title}</BoldText>
      </Button>
    </DisabledWrapper>
  ) : (
    <Button {...attrs} key={el.title}>
      <BoldText>{el.title}</BoldText>
    </Button>
  );
};

const dangerButton = (el: MenuItemType, id: string) => {
  const Button = getButton(!!el.onClick);
  const fn = () => el.onClick && el.onClick(id);
  const attrs = {to: (el.to && el.to(id)) || '', onClick: fn};
  return !el.to && !el.onClick ? (
    <DisabledWrapper key={id}>
      <Button {...attrs} key={el.title}>
        <BoldText color={'red'}>{el.title}</BoldText>
      </Button>
    </DisabledWrapper>
  ) : (
    <Button {...attrs} key={el.title}>
      <BoldText color={'red'}>{el.title}</BoldText>
    </Button>
  );
};

const successfulButton = (el: MenuItemType, id: string) => {
  const Button = getButton(!!el.onClick);
  const fn = () => el.onClick && el.onClick(id);
  const attrs = {to: (el.to && el.to(id)) || '', onClick: fn};
  return !el.to && !el.onClick ? (
    <DisabledWrapper key={id}>
      <Button {...attrs} key={el.title}>
        <BoldText color={'green'}>{el.title}</BoldText>
      </Button>
    </DisabledWrapper>
  ) : (
    <Button {...attrs} key={el.title}>
      <BoldText color={'green'}>{el.title}</BoldText>
    </Button>
  );
};

const line = () => <Line key={'unique'} />;

const renderFunctions: renderMenuItemType = {
  danger: dangerButton,
  line: line,
  successful: successfulButton,
};

const getRender = (item: MenuItemType, id: string) => {
  const {render} = item;
  if (!render) return primaryButton(item, id);
  if (render === 'danger') return renderFunctions[render](item, id);
  if (render === 'successful') return renderFunctions[render](item, id);
  if (render === 'line') return renderFunctions[render]();
};

export const Menu: React.FC<MenuType> = ({id, options, menuRef, prevent, right = true, showX}) => {
  return (
    <MenuItemsWrapper
      className={'menu__items'}
      ref={menuRef}
      onClick={(e: React.MouseEvent) => prevent && e.preventDefault()}>
      {showX && (
        <IconClose right={right}>
          <IconSvg type="close-2" width="7px" height="7px" viewBox="0 0 7 7" stroke="none" />
        </IconClose>
      )}
      {options.map((el) => !el.isHidden && getRender(el, id))}
    </MenuItemsWrapper>
  );
};
