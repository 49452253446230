import React from 'react';
import {BrowserData} from '../../../components/BrowserData';
import {Table} from '../../../components/Members/table';
import {useGetMembersByRole, useUpdateMember} from '../../../hooks/members';

export const TabAdmin = () => {
  const {data, count} = useGetMembersByRole('admin');
  const {blockUser, activeUser} = useUpdateMember();

  return (
    <BrowserData count={count}>
      <Table items={data} blockUser={blockUser} activeUser={activeUser} />
    </BrowserData>
  );
};
