import {usePagination} from './pagination';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {
  DeleteItemQuery,
  DeleteItemRequestType,
  DeleteItemResponseType,
  getListings,
  queryGetCountListing,
  SearchListings,
  TGetCountListingRequest,
  TGetCountListingResponse,
} from '../queries/listings';
import {GQLItemWhereInput} from '../graphql.schema';
import {useEffect, useMemo, useState} from 'react';
import {Item} from '../types/item';
import {Edge} from '../types/parse';
import {useSetRecoilState} from 'recoil';
import {isLoading} from '../states/load';

type queryType = 'all' | 'rent' | 'sell' | 'free' | 'coffee' | 'service' | 'blocked' | 'paid';

const whereParams: Partial<Record<queryType, GQLItemWhereInput>> = {
  rent: {listingType: {equalTo: 'rent'}},
  sell: {listingType: {equalTo: 'sell'}},
  service: {listingType: {equalTo: 'service'}},
  free: {rewardType: {equalTo: 'free'}},
  coffee: {rewardType: {equalTo: 'coffee'}},
};

export const useGetListings = (type: queryType) => {
  const [listings, setData] = useState<Item[] | undefined>();
  const {page, first_skip} = usePagination();
  const setIsLoading = useSetRecoilState(isLoading);
  const {data, fetchMore, loading, ...otherData} = useQuery<{items: Edge<Item>}>(getListings, {
    variables: {
      ...first_skip,
      where: whereParams[type],
    },
  });

  setIsLoading(loading);

  const FetchMore = async () => {
    try {
      await fetchMore({
        variables: {
          variables: {...first_skip, where: whereParams[type]},
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    FetchMore();
  }, [page]);

  const items = useMemo(() => data?.items.edges.map((el) => el.node), [data]);
  useEffect(() => {
    setData(items);
  }, [data]);

  return {...otherData, data: listings, count: data?.items.count || 0};
};

export const useSearchListing = (constraints?: Record<string, any>) => {
  const {refetch} = useQuery<{items: Edge<Item>}>(SearchListings, {
    skip: true,
    fetchPolicy: 'cache-and-network',
  });

  return async (name: string) => {
    const response = await refetch({
      where: {
        name: {
          matchesRegex: name,
          options: 'i',
        },
        ...(constraints ?? {}),
      },
    });

    return response?.data?.items?.edges?.map((e) => e.node);
  };
};

export const useDeleteItems = (options: {onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [DeleteItemRequest, {loading}] = useMutation<DeleteItemResponseType, DeleteItemRequestType>(DeleteItemQuery);
  const {onSuccess} = options;

  const onDelete = async (id?: string): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('error:id');
      }

      const response = await DeleteItemRequest({
        variables: {id},
      });

      const data = response?.data?.deleteItem?.item;
      if (!data) throw new Error();

      setSuccess('success:deleteItem');
      setError(null);
      onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    onDelete,
    success,
    loading,
    error,
  };
};

export const useGetCountUserListings = (userId?: string) => {
  const {data} = useQuery<TGetCountListingResponse, TGetCountListingRequest>(queryGetCountListing, {
    skip: !userId,
    variables: {
      where: {
        Lister: {
          have: {
            objectId: {
              equalTo: userId,
            },
          },
        },
      },
    },
  });

  return {data: data?.items.count};
};
