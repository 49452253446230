import React from 'react';
import {LoginType} from '../../types/auth';
import {Form} from './Form';
import {LogoSvgSignIn, SignInBox, Title, Wrapper, ErrorMessage} from './styles';

interface Props {
  loading?: boolean;
  login: () => void;
  errors?: {
    [username: string]: Array<string>;
  };
  clearErrors?: (name: string) => void;
  values: Partial<LoginType>;
  onChange: (field: {key: keyof LoginType; value: string}) => void;
}

export const SignIn: React.FC<Props> = ({login, onChange, values, errors}) => {
  return (
    <Wrapper>
      <SignInBox>
        <LogoSvgSignIn type="hangeh_logo-aqua-2" width="230px" height="96px" />
        <Title>Admin Portal</Title>
        <Form onSubmit={login} onChange={onChange} values={values} />
        <ErrorMessage open={errors && Object.keys(errors).length !== 0}>Access denied</ErrorMessage>
      </SignInBox>
    </Wrapper>
  );
};
