import {
  AppFileFields,
  ChatFields,
  EChatStatus,
  ItemFields,
  MessageFields,
  MESSAGES_NOTIFICATIONS,
  OrderFields,
  TChat,
  TContact,
  TMessage,
  TMessageData,
  TUserChat,
  TUserInsightsFields,
  TypeMsg,
  UserFields,
} from '../types/mesages';
import {toPointer, toPOJO} from './parse';
import {AppFile} from '../types/parse';
import {TOrder, TOrderItem} from '../types/order';
import {differenceInSeconds, parseISO} from 'date-fns';
import {TUserInsights, User, USER_TYPE} from '../types/user';
import {getResize} from './file';
import {getUserName} from './user';
import {TCommunityItem, TInsightItem, TPersonData} from '../ui-kit/Chat/PersonInfo/types';
import {route} from '../constants/routes';
import {Community} from '../types/communities';

export const isSeen = (message: TMessage, objectId?: string): boolean => {
  return (message?.Seen || []).findIndex((el) => el?.objectId === objectId) >= 0;
};

export const getActualNotifMessages = (messages?: TMessage[] | null) => {
  if (!messages) return {actualMessages: [], old: []};
  const notifications = messages.filter((el) => el?.type === TypeMsg.notification);
  const ordersParis: Record<string, TMessage[]> = {};
  notifications.forEach((el) => {
    const id = el?.Order?.objectId;
    if (id) {
      ordersParis[id] ? ordersParis[id]?.push(el) : (ordersParis[id] = [el]);
    }
  });
  const excludedIds = Object.keys(ordersParis)
    .filter((el) => Number(ordersParis[el]?.length) > 1)
    .map((el) => ordersParis[el]?.pop() && ordersParis[el]?.map((el) => el.objectId))
    .flat();
  return {actualMessages: messages?.filter((el) => !excludedIds.includes(el.objectId)), old: excludedIds};
};

export const getMesssages = (messages: TMessage[], contactObjectId?: string) => {
  if (contactObjectId) {
    return messages?.filter(
      (el) =>
        el.Author?.objectId === contactObjectId || el.ShowTo?.filter((it) => it?.objectId === contactObjectId)?.length,
    );
  }
};

export const getCountUnreadMsgs = (messages: TMessage[], objectId?: string) => {
  const data = messages?.reduce((acc, el) => {
    if (
      !isSeen(el, objectId) &&
      el?.Author?.objectId !== objectId &&
      el.ShowTo?.find((it) => it?.objectId === objectId)
    ) {
      acc['count'] = (acc['count'] || 0) + 1;
    }
    return acc;
  }, {} as any);

  return data?.count;
};

export const mapAppFileParseObject = (object: Parse.Object): AppFile => {
  return toPOJO<AppFile>(Object.values(AppFileFields), object, {
    file: (item: Parse.File | undefined): AppFile['file'] => {
      if (!item) return {name: '', url: ''};
      return {name: item.name(), url: item.url()};
    },
  });
};

export const mapUsersParseObject = (object: Parse.Object): TUserChat => {
  return toPOJO<TUserChat>(Object.values(UserFields), object, {
    Avatar: (item: Parse.Object | undefined): TUserChat['Avatar'] => {
      if (!item) return;
      return mapAppFileParseObject(item);
    },
  });
};

export const mapItemParseObject = (object: Parse.Object): TOrderItem => {
  return toPOJO<TOrderItem>(Object.values(ItemFields), object);
};

export const mapChatParseObject = (object: Parse.Object): TChat => {
  return toPOJO<TChat>(Object.values(ChatFields), object);
};

export const mapMessageParseObject = (object: Parse.Object): TMessage => {
  return toPOJO<TMessage>(Object.values(MessageFields), object, {
    Author: (item: Parse.Object | undefined): TMessage['Author'] => {
      if (!item) return;
      return mapUsersParseObject(item);
    },
    ShowTo: (items: Parse.Object[]): TMessage['ShowTo'] => {
      return items?.map(mapUsersParseObject);
    },
    Seen: (items: Parse.Object[]): TMessage['Seen'] => {
      return items?.map(mapUsersParseObject);
    },
    Chat: (item: Parse.Object): TMessage['Chat'] => {
      if (!item) return;
      return mapChatParseObject(item);
    },
    Order: (item: Parse.Object | undefined): TMessage['Order'] => {
      if (!item) return;
      return toPOJO<TOrder>(Object.values(OrderFields), item, {
        Lister: (item: Parse.Object | undefined): TOrder['Lister'] => {
          if (!item) return;
          return mapUsersParseObject(item);
        },
        Requester: (item: Parse.Object | undefined): TOrder['Lister'] => {
          if (!item) return;
          return mapUsersParseObject(item);
        },
        Item: (item: Parse.Object | undefined): TOrder['Item'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
        Event: (item: Parse.Object | undefined): TOrder['Event'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
        Amenity: (item: Parse.Object | undefined): TOrder['Amenity'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
      });
    },
  });
};

export const toPointerMessage = (
  msg: Omit<TMessageData, 'Attachments'> & {
    Attachments?: (Partial<AppFile> | undefined)[];
  },
) => {
  return {
    ...msg,
    ShowTo: [toPointer({__typename: '_User', objectId: msg?.ShowTo as string})],
    Author: {link: msg?.Author},
    Order: msg?.Order ? {link: msg?.Order} : undefined,
    Community: msg?.Community ? {link: msg?.Community} : undefined,
  };
};

export const sortDescByDate = <T>(array?: T[] | null, field?: string) => {
  return (array ? [...array] : [])?.sort((a, b) => {
    const dateA = (a as any)[field || ''] as Date;
    const dateB = (b as any)[field || ''] as Date;

    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
};

export const isOnline = (date?: Date | string) => {
  if (!date) return;
  const correctDate = typeof date === 'string' ? parseISO(date) : date;
  return differenceInSeconds(new Date(), correctDate) < 10;
};

export const getDataLastMessage = (
  contactId?: string,
  msgs?: TMessage[],
  viewerId?: string,
): {
  text: string;
  orderStatus: string;
  date?: Date | null | undefined;
  haveUnseenMsg?: boolean | undefined;
  isClosed: boolean;
  isArchived: boolean;
  chatId?: string;
} => {
  const msgsContact = (msgs ? [...msgs] : [])
    ?.sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime())
    ?.filter((el) => el.type !== TypeMsg.internalNote);

  const msg = msgsContact.find((msg) => {
    return msg?.ShowTo?.find((it) => it?.objectId === viewerId) && msg?.ShowTo?.find((it) => it?.objectId === viewerId);
  });
  const unread = msgsContact.find((msg) => {
    return msg?.Author?.objectId === contactId && msg?.ShowTo?.find((it) => it?.objectId === viewerId);
  });

  return {
    text: msg?.text || '',
    orderStatus: msg?.Order?.status || '',
    date: new Date(msg?.createdAt as string),
    haveUnseenMsg: unread
      ? !unread?.Seen?.find((c) => c?.objectId === viewerId) && unread?.Author?.objectId !== viewerId
      : false,
    isClosed: msg?.Chat?.status === EChatStatus.close,
    isArchived: Boolean(msg?.Chat?.isArchived),
    chatId: msg?.Chat?.objectId,
  };
};

export const getContactsList = (
  contacts?: TUserChat[],
  viewerId?: string,
  msgs?: TMessage[],
  typeUser?: USER_TYPE | null,
) => {
  const data = contacts
    ?.reduce((acc, c) => {
      if (c?.objectId !== viewerId) {
        const {chatId, isArchived, isClosed, ...dataMsg} = getDataLastMessage(c?.objectId, msgs, viewerId);
        acc.push({
          objectId: c?.objectId || '',
          avatar: getResize(c?.Avatar?.file?.url, 'lg') || '',
          onlineStatus: isOnline(c?.onlineDate),
          name: getUserName({
            first: c?.firstName,
            last: c?.lastName,
            type: typeUser,
          }),
          dataMsg,
          chatId,
          isArchived,
          isClosed,
        });
      }
      return acc;
    }, [] as TContact[])
    .sort((a, b) => {
      const dateA = a.dataMsg.date || 0;
      const dateB = b.dataMsg.date || 0;

      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });

  return data;
};

export const getDataWithAvatars = (newData?: Array<TUserChat>, oldData?: Array<TContact>) => {
  const data = [];

  for (let i = 0; i < Math.max(newData?.length || 0, oldData?.length || 0); i++) {
    const currentItem = oldData?.[i];
    const newCurrentItem = newData?.find((el) => el.objectId === currentItem?.objectId);

    const avatar = newCurrentItem?.Avatar?.file.url;
    data.push({
      ...currentItem,
      avatar: avatar || currentItem?.avatar,
    });
  }

  return data;
};

enum TInsight {
  money = 'money',
}

const insights: Array<{dataKey: TUserInsightsFields; type?: TInsight}> = [
  {dataKey: 'totalSessions'},
  {dataKey: 'listingsPosted'},
  {dataKey: 'totalPosts'},
  {dataKey: 'listingsValueSum', type: TInsight.money},
  {dataKey: 'totalLikes'},
  {dataKey: 'listingsTransactions'},
  {dataKey: 'totalMessages'},
  {dataKey: 'moneySpent'},
  {dataKey: 'eventsAttended'},
  {dataKey: 'amenitiesBooked'},
];

const labels: Record<TUserInsightsFields, string> = {
  totalSessions: 'Sessions',
  listingsPosted: 'Listings posted',
  totalPosts: 'Posts',
  listingsValueSum: 'Value of listings',
  totalLikes: 'Likes',
  listingsTransactions: 'Transactions',
  totalMessages: 'Messages',
  moneySpent: 'Money spent',
  eventsAttended: 'Events attended',
  amenitiesBooked: 'Amenities booked',
};

export const prepareData = {
  insights: (params: {insightsData?: TUserInsights | null; currency?: string}): Array<TInsightItem> | undefined => {
    const {insightsData, currency} = params;
    if (!insightsData) return;

    return insights.map((el) => ({
      label: labels[el.dataKey],
      value: el.type ? renderInsight[el.type](insightsData[el.dataKey], currency ?? '$') : insightsData[el.dataKey],
    }));
  },
  user: (params: {user?: User | null; countListings?: number; isManager: boolean}): TPersonData | undefined => {
    const {user, isManager, countListings} = params;

    if (!user) return;

    const {firstName, lastName, Avatar, Location, objectId} = user;

    return {
      name: getUserName({
        first: firstName,
        last: lastName,
      }),
      avatar: Avatar?.file.url,
      label: isManager ? 'Admin' : 'Resident',
      labelVariant: isManager ? 'quaternary' : 'septenary',
      location: [Location?.name, Location?.State.name].filter(Boolean).join(', '),
      link: route.member.get({id: objectId}),
      listings: `${countListings || 0} Listings`,
    };
  },
  communities: (communities?: Array<Community>): Array<TCommunityItem> | undefined => {
    if (!communities) return;

    return communities.map((el) => {
      return {
        name: el.name,
        avatar: el.Avatar?.file?.url,
        members: `${el.countMembers || 0} Members`,
      };
    });
  },
};

export const isManager = (params: {communities?: Array<Community>; userId?: string}): boolean => {
  const {communities, userId} = params;

  return communities?.some((el) => el.Owner?.objectId === userId) || false;
};

const renderInsight = {
  [TInsight.money]: (value: number, currency: string) => currency + value / 100,
};

export const getChatsDataForContacts = (contacts?: Array<TContact>, chatsMap?: Record<string, TChat>) => {
  return contacts?.map((el) => ({
    ...el,
    isClosed: chatsMap?.[el.chatId as string]?.status === EChatStatus.close,
    isArchived: chatsMap?.[el.chatId as string]?.isArchived,
  }));
};

export const getNotificationMessage = (message: TMessage): string => {
  switch (message.text as MESSAGES_NOTIFICATIONS) {
    case 'closed':
      return `Ticket closed by ${getUserName({
        showFull: true,
        first: message.Author?.firstName,
        last: message.Author?.lastName,
      })}.`;
    default:
      return '';
  }
};

export const checkIsEmptyMessage = (message?: string) => {
  const woTags = message?.replace(/(<([^>]+)>)/gi, '');
  const formatted = woTags?.replaceAll(' ', '')?.replaceAll('\n', '');
  return !formatted?.length;
};

export const checkIsDifferentDay = (created1?: Date | string, created2?: Date | string) => {
  if (!created1 || !created2) return;
  const date1 = new Date(created1);
  const date2 = new Date(created2);
  return !(
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDay() === date2.getDay()
  );
};
