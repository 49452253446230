import React from 'react';
import {LinkProps} from 'react-router-dom';
import {ButtonProps} from './types';
import {ArrowLinkBtn} from './styles';
import {ButtonBase} from './ButtonBase';
import {Notices} from '../Notifications';

export const LinkButtonWithArrow: React.FC<ButtonProps & LinkProps> = ({children, ...props}) => {
  return (
    <ButtonBase element={ArrowLinkBtn} {...props}>
      {children}
      {props?.notices && <Notices value={props?.notices} width={'16px'} />}
    </ButtonBase>
  );
};
