import React, {ReactNode} from 'react';
import {ViewCommunityType} from '../../../types/communities';
import {ProfileIntro} from '../../../ui-kit/ProfileIntro';
import {Card} from '../../../ui-kit/Cards';
import {ViewHeader} from '../../HeaderViewPage';
import {CardsWrapper, CustomPlanWrapper, PageWrapper, ProfileIntroWrapper} from './styles';
import {DisabledWrapper} from '../../Layout/styles';
import {useHistory, useParams} from 'react-router-dom';

const getValue = (value?: ViewCommunityType[keyof ViewCommunityType]): string | number => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value;
  return 0;
};

type render = (item: ViewCommunityType, el: Field) => ReactNode;

interface Field {
  title: string;
  dataIndex?: keyof ViewCommunityType | ((item: ViewCommunityType) => string | number | undefined);
  render?: render;
  link?: {
    url: string;
    title: string;
    dataIndex?: string;
  };
}

const MoneyCard: render = (data, el) => {
  const sign = data.Subscr?.PaymentPlan?.Currency?.sign || '';
  return (
    typeof el.dataIndex === 'string' && (
      <Card {...el} value={`${sign}${(data[el.dataIndex] as number) / 100 || 0}`} key={el.title} />
    )
  );
};

const StarCard: render = (data, el) => {
  const {dataIndex, title} = el;
  if (!dataIndex) return null;
  return (
    <Card
      // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={`${data[dataIndex]?.toFixed(1) || 0} stars`}
      title={title}
      link={el?.link && {url: el.link.url, title: el.link.title}}
    />
  );
};

const CustomerValueCard: render = (data, el) => {
  const sign = data.Subscr?.PaymentPlan?.Currency?.sign || '';
  const value =
    data?.Subscr?.SubscrPayment?.edges?.reduce((sum, it) => (it.node?.amount ? sum + it.node?.amount : sum), 0) || 0;

  return <Card {...el} value={`${sign}${value}`} key={el.title} />;
};

const CustomPlanCard: render = (data, el) => {
  const {id} = useParams<{id: string}>();
  const {push} = useHistory();

  return (
    <CustomPlanWrapper onClick={() => push(`/plan/${id}`)}>
      <Card {...el} isCustom value={`Pricing & Features`} key={el.title} />
    </CustomPlanWrapper>
  );
};

const fields: Field[] = [
  {
    title: 'Payment plan',
    dataIndex: (item) => item.Subscr?.PaymentPlan?.name,
    // link: {
    //   url: '/',
    //   title: 'Post your first item',
    // },
  },
  {
    title: 'Customer Value',
    render: CustomerValueCard,
  },
  {
    title: 'Total listings',
    dataIndex: 'countListings',
    // link: {
    //   url: '/',
    //   title: 'Reivew new listings',
    // },
  },
  {
    title: 'Total members',
    dataIndex: 'countMembers',
    // link: {
    //   url: '/',
    //   title: 'Review new members',
    // },
  },
  {
    title: 'Total transactions',
    dataIndex: 'countTransactions',
  },
  {
    title: 'Total transactions value',
    dataIndex: 'countTransactionValue',
    render: MoneyCard,
  },
  {
    title: 'Average transaction rating',
    dataIndex: 'averageTransactionRating',
    render: StarCard,
  },
  {
    title: 'Total value of listings',
    dataIndex: 'countListingValue',
    render: MoneyCard,
  },
  {
    title: 'Total loop posts by members',
    dataIndex: 'countLoopPosts',
  },
  {
    title: 'Total loop post likes by members',
    dataIndex: 'countLoopPostLikes',
  },
  {
    title: 'Custom',
    dataIndex: () => 'Pricing & Features',
    render: CustomPlanCard,
    link: {
      url: '/',
      title: '',
    },
  },
];

const getRender = (data: ViewCommunityType, el: Field) => {
  const {dataIndex, render} = el;
  if (typeof render === 'function') return render(data, el);
  const value = typeof dataIndex === 'function' ? dataIndex(data) : getValue(dataIndex && data[dataIndex]);
  return <Card {...el} value={value} key={el.title} />;
};

export const View: React.FC<{data: ViewCommunityType}> = ({data}) => {
  const getAddress = (city?: string, state?: string): string | undefined => {
    return city && state ? `${city}, ${state}` : city || state;
  };

  return (
    <PageWrapper>
      <ViewHeader title="Back to communities" redirectTo={'/communities'} />
      <ProfileIntroWrapper>
        <ProfileIntro
          title={data.name}
          description={data.descr}
          address={getAddress(data.Location?.City?.name, data.Location?.State?.name)}
          countMembers={data.countMembers || 0}
          avatar={data.Avatar?.file?.url}
          interestsTitle={'Interests'}
          recommendsTitle={'Recommendations'}
        />
      </ProfileIntroWrapper>
      <CardsWrapper>
        {fields.map((el, idx) => (
          <DisabledWrapper key={idx}>{getRender(data, el)}</DisabledWrapper>
        ))}
      </CardsWrapper>
    </PageWrapper>
  );
};
