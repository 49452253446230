import {useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {getDashboard as getDashboardQuery} from '../queries/dashboard';
import {DashboardFragment} from '../queries/fragments/dashboard';
import {isLoading} from '../states/load';
import {Dashboard} from '../types/dashboard';

export const useGetDashboard = () => {
  const [getDashboard, {data, loading, client}] = useMutation<{adminDashboard?: Dashboard}>(getDashboardQuery);
  const setIsLoading = useSetRecoilState(isLoading);

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    if (!data) return;
    client.writeFragment({id: 'adminDashboard', fragment: DashboardFragment, data: data.adminDashboard});
  }, [data]);

  const cachedData = client.readFragment({id: 'adminDashboard', fragment: DashboardFragment});

  const Data = data?.adminDashboard || cachedData || null;

  (!Data || !loading) && setIsLoading(loading);

  return {data: Data};
};
