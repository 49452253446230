import React from 'react';
import {CheckboxContainer, HiddenCheckbox, StyledCheckbox} from './styles';
import {CheckboxProps} from './types';
import {IconSvg} from '../Icon/Svg';

export const Checkbox: React.FC<CheckboxProps> = ({$isChecked, onChange, ...props}) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={$isChecked} onChange={onChange} />
      <StyledCheckbox {...props} $isChecked={$isChecked}>
        <IconSvg width={'20'} height={'18'} type="checked-mark" />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
