import React, {useState} from 'react';
import {Table} from '../../../../ui-kit/Table';
import {Columns, ItemType} from '../../../../ui-kit/Table/types';
import {FeaturesContent, TableWrapperFixed} from '../styles';
import {TableWrapper} from '../TableWrapper';
import {ConfigurePlan} from '../../ConfigurePlan/ConfigurePlan';
import {CreatePaymentPlanType, PaymentPlan, UpdatePaymentPlan} from '../../../../types/payments';
import {mergePlanOptions, toOptionNames, toOptionValues} from '../../helpers';
import {PlanOptionsValues} from '../../../../constants/plan';
import {CreatePaymentPlanResponse} from '../../../../queries/payments';

interface FeaturesProps {
  paymentPlan?: PaymentPlan;
  updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>;
  createPlan?: (fields: Partial<CreatePaymentPlanType>) => Promise<CreatePaymentPlanResponse | null | undefined>;
}

interface PlanItems {
  active: string[];
  inactive: string[];
}

interface TableCellProps {
  items: string[];
  active: boolean;
}

const TableCell: React.FC<TableCellProps> = ({items, active}) => {
  return (
    <FeaturesContent active={active}>
      {items.length} Features: {items.join(', ')}
    </FeaturesContent>
  );
};

export const Features: React.FC<FeaturesProps> = ({paymentPlan, updatePaymentPlan}) => {
  const currentOptions = toOptionNames(paymentPlan?.options ?? []);
  const allOptions = Object.keys(PlanOptionsValues);
  const [edit, setEdit] = useState<boolean>(false);
  const options = mergePlanOptions(currentOptions, allOptions);

  const cancel = () => setEdit(false);
  const submit = () => setEdit(false);

  const handleSubmit = (options: string[]) => {
    const updatedPlan: UpdatePaymentPlan = {
      options,
      objectId: paymentPlan?.objectId ?? '',
      isPublic: paymentPlan?.isPublic ?? false,
    };
    updatePaymentPlan(updatedPlan).then(cancel);
  };

  const columns: Columns<PlanItems> = [
    {
      title: 'Active',
      padLeft: '26px',
      // eslint-disable-next-line react/display-name
      render: (item) => <TableCell items={item.active} active={true} />,
    },
    {
      title: 'Inactive',
      padLeft: '26px',
      // eslint-disable-next-line react/display-name
      render: (item) => <TableCell items={item.inactive} active={false} />,
    },
  ];

  const items: ItemType<PlanItems>[] = [
    {
      objectId: 'a',
      active: toOptionValues(currentOptions),
      inactive: toOptionValues(currentOptions, allOptions),
    },
  ];

  return (
    <TableWrapper setIsEdit={setEdit} isEdit={edit} title={'Features'} cancel={cancel} onSubmit={submit}>
      <TableWrapperFixed>
        <Table<PlanItems> variant={'secondary'} items={items} columns={columns} />
      </TableWrapperFixed>
      <ConfigurePlan
        title={paymentPlan?.name ?? ''}
        active={edit}
        setActive={setEdit}
        planOptions={options}
        onSubmit={handleSubmit}
      />
    </TableWrapper>
  );
};
