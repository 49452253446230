import {Location} from './location';
import {AppFile, Edge} from './parse';
import {Community} from './communities';

export interface User {
  id?: string;
  objectId: string;
  createdAt?: string;
  updatedAt?: string;
  username?: string;
  email?: string;
  emailVerified?: boolean;
  status?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  bio?: string;
  study?: string;
  work?: string;
  stripeId?: string;
  gender?: string;
  birthDate?: string;
  howDidYouHear?: string;
  aptSuite?: string;
  zip?: string;
  isVerified?: boolean;
  msgIncoming?: string;
  identityData?: string;
  homepage?: string;
  onlineDate?: Date;
  Country?: Location;
  Reviews?: Edge<Review>;
  languages?: string;
  Location?: Location;
  address?: string;
  Avatar?: AppFile;
  Communities?: Edge<Community>;
  spent?: number;
  earned?: number;
  Total?: {
    count: number;
  };
  Active?: {
    count: number;
  };
  Lent?: {
    count: number;
  };
  Borrowed?: {
    count: number;
  };
}

export interface Review {
  objectId: string;
  User: User;
}

export enum USER_TYPE {
  admin = 'admin',
  support = 'support',
  manager = 'manager',
  resident = 'resident',
  communityAdmin = 'communityAdmin',
}

export interface TUserInsights {
  totalSessions: number;
  totalPosts: number;
  totalMessages: number;
  eventsAttended: number;
  amenitiesBooked: number;
  listingsPosted: number;
  totalLikes: number;
  listingsTransactions: number;
  moneySpent: number;
  listingsValueSum: number;
}
