import {gql} from '@apollo/client';

export const AppFileFragment = gql`
  fragment AppFileFragment on AppFile {
    id
    objectId
    createdAt
    updatedAt
    name
    file {
      url
      name
    }
    mime
  }
`;
