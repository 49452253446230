import React from 'react';
import {IconSvg} from '../../Icon/Svg';
import {getStrokeColor, IsNoticesLbl, NoticesIconWrapper, NoticesWrapper} from '../styles';
import {mode} from '../types';

interface TProps {
  onClickNotifications?: () => void;
  isNotices?: boolean;
  mode?: mode;
}

export const Notification: React.FC<TProps> = ({isNotices, mode, onClickNotifications}) => {
  return (
    <NoticesWrapper>
      <NoticesIconWrapper onClick={onClickNotifications}>
        <IconSvg type="bell-2" stroke={getStrokeColor(mode)} />
        {isNotices && <IsNoticesLbl top="0" />}
      </NoticesIconWrapper>
    </NoticesWrapper>
  );
};
