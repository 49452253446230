import {anyObject, ArrayOfKeys, FileInfo} from './types';

export function keys<T extends anyObject>(obj: T): ArrayOfKeys<T> {
  return Object.keys(obj) as ArrayOfKeys<T>;
}

export function isClient(): boolean {
  return typeof window !== 'undefined';
}
export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isServer = (): boolean => {
  return typeof window === 'undefined';
};

export const isFileInfo = (value: unknown): value is FileInfo => {
  return isString((value as FileInfo)?.url) && isString((value as FileInfo)?.name);
};

export const isFile = (value: unknown): value is File => {
  return value instanceof File;
};

export const getSourceUrl = (value?: File | FileInfo | string): string | undefined => {
  if (!value) return;

  if (isString(value)) return value;

  if (isFileInfo(value)) return value.url;

  if (isFile(value) && !isServer()) {
    return (window.URL || window.webkitURL)?.createObjectURL(value);
  }
};

export const currency = 'C$';
