import React from 'react';
import {Avatar, Hidden, InnerDataUser, TopContent} from '../styles';
import {UserInfoType, variant} from '../types';
import {Link} from '../../Typography/Link';
import {Media} from '../../theme';

const DataUserMemo: React.FC<{name?: string; avatar?: string}> = ({name, avatar}) => (
  <InnerDataUser>
    <Hidden mq={Media.down.l}>
      <TopContent>{name}</TopContent>
    </Hidden>
    <Avatar src={avatar} size="xxs" />
  </InnerDataUser>
);

const DataUser = React.memo(DataUserMemo);

export const UserInfo: React.FC<
  UserInfoType & {
    logout?: () => void;
    logoutText?: string;
    variant: variant;
    homePathUser?: string;
    onClick?: () => void;
  }
> = ({avatar, name, variant, homePathUser, onClick}) => {
  return (
    <>
      {variant === 'web' && homePathUser ? (
        <>
          <Hidden mq={Media.up.l}>
            <Link to={homePathUser}>
              <DataUser name={name} avatar={avatar} />
            </Link>
          </Hidden>
          <Hidden mq={Media.down.l}>
            <Link to={'#'} onClick={() => onClick?.()}>
              <DataUser name={name} avatar={avatar} />
            </Link>
          </Hidden>
        </>
      ) : (
        <DataUser name={name} avatar={avatar} />
      )}
    </>
  );
};
