import React, {ReactNode} from 'react';
import {Dashboard as DashboardType} from '../../types/dashboard';
import {Card} from '../../ui-kit/Cards';
import {HeaderPage} from '../HeaderPage';
import {DashBoardWrapper, PageWrapper} from './styles';
import {DisabledWrapper} from '../Layout/styles';
import {fromCents} from '../../helpers/calculate';

type render = (el: DashboardElement, data: DashboardType) => ReactNode;

interface DashboardElement {
  title: string;
  dataIndex?: keyof DashboardType;
  render?: render;
  link?: {
    url: string;
    title: string;
    dataIndex?: keyof DashboardType;
  };
}

const fixedValue: render = (el, data) => {
  const value = el.dataIndex && data?.[el.dataIndex];
  return <Card {...el} value={value && value.toFixed(2)} marginBottom={15} />;
};

const averagePrice: render = (el, data) => {
  const value = el.dataIndex && data?.[el.dataIndex];
  return <Card {...el} value={value && fromCents(value).toFixed(2)} marginBottom={15} />;
};

const priceFromCents: render = (el, data) => {
  const value = el.dataIndex && data?.[el.dataIndex];
  return <Card {...el} value={value && fromCents(value)} marginBottom={15} />;
};

const fields: DashboardElement[] = [
  {
    title: 'Total communities',
    dataIndex: 'countCommunities',
    // link: {
    //   url: '',
    //   title: 'Post your first item',
    // },
  },
  {
    title: 'Total managers',
    dataIndex: 'countManagers',
  },
  {
    title: 'Total members',
    dataIndex: 'countMembers',
  },
  {
    title: 'Total members who have listings',
    dataIndex: 'countListingMembers',
  },
  {
    title: 'Total members who requested listings',
    dataIndex: 'countRequestedListingMembers',
  },
  {
    title: 'Total successful transactions',
    dataIndex: 'countSuccessfulTransactions',
  },
  {
    title: 'Total listings',
    dataIndex: 'countListings',
  },
  {
    title: 'Total active listings (available)',
    dataIndex: 'countActiveListings',
  },
  {
    title: 'Total inactive listings (unavailable)',
    dataIndex: 'countInactiveListings',
  },
  {
    title: 'Total rental listings',
    dataIndex: 'countRentalListings',
  },
  {
    title: 'Total selling listings',
    dataIndex: 'countSellingListings',
  },
  {
    title: 'Total Service listings',
    dataIndex: 'countServiceListings',
  },
  {
    title: 'Total free listings',
    dataIndex: 'countFreeListings',
  },
  {
    title: 'Total paid listings',
    dataIndex: 'countPaidListings',
  },
  {
    title: 'Average listing price',
    dataIndex: 'averageListingPrice',
    render: averagePrice,
  },
  {
    title: 'Total reviews and ratings',
    dataIndex: 'countReviewsAndRatings',
  },
  {
    title: 'Average listing rating',
    dataIndex: 'averageListingRating',
    render: fixedValue,
  },
  {
    title: 'Average member rating',
    dataIndex: 'averageMemberRating',
    render: fixedValue,
  },
  {
    title: 'Total revenue',
    dataIndex: 'countRevenue',
    render: priceFromCents,
  },
  {
    title: 'Total revenue from subscriptions',
    dataIndex: 'countSubscrRevenue',
    render: priceFromCents,
  },
  {
    title: 'Total revenue from listings',
    dataIndex: 'countListingsRevenue',
    render: priceFromCents,
  },
];

export const Dashboard: React.FC<{data: DashboardType}> = ({data}) => {
  return (
    <PageWrapper>
      <HeaderPage title="Dashboard" />
      <DashBoardWrapper>
        {fields.map((el: DashboardElement) => (
          <DisabledWrapper key={el.title}>
            {el.render ? (
              el.render(el, data)
            ) : (
              <Card {...el} value={el.dataIndex && data?.[el.dataIndex]} marginBottom={15} />
            )}
          </DisabledWrapper>
        ))}
      </DashBoardWrapper>
    </PageWrapper>
  );
};
