import React, {useState} from 'react';
import {Navigation} from '../../ui-kit/Navigation';
import {Route, Switch, Redirect} from 'react-router-dom';
import Dashboard from '../../containers/Dashboard';
import Communities from '../../containers/Communities';
import Members from '../../containers/Members';
import {Community} from '../../containers/Communities/View';
import {User} from '../../types/user';
import {Member} from '../../containers/Members/View';
import {ListingsContainer} from '../../containers/Listings';
import {PageLoader} from '../../ui-kit/Loader/PageLoader';
import {useRecoilValue} from 'recoil';
import {isLoading as isLoadingState} from '../../states/load';
import {ConfigrationsContainer} from '../../containers/Configrations';
import {CustomPlanContainer} from '../../containers/Communities/CustomPlan/CustomPlanContainer';
import {MenuItemType} from '../../ui-kit/Navigation/types';
import {MessagesContainer} from '../../containers/Messages/MessagesContainer';
import {route} from '../../constants/routes';

const Loader = () => {
  const isLoading = useRecoilValue(isLoadingState);
  return <PageLoader loaderType={'spinner'} $isVisible={isLoading} />;
};

export interface ILayout {
  logout?: () => Promise<void>;
  user: User;
  menuItemsTop: Array<MenuItemType>;
  menuItemsBottom: Array<MenuItemType>;
  isSupport: boolean;
}

export const Layout: React.FC<ILayout> = ({logout, user, menuItemsBottom, menuItemsTop, isSupport}) => {
  const [isOpenNav, setStateNav] = useState(true);

  const headContent: MenuItemType = isSupport
    ? {content: 'My page', type: 'home-alt', to: '/', path: 'messages'}
    : {content: 'Admin', type: 'home-alt', to: '/', path: 'dashboard'};

  return (
    <>
      <Loader />
      <Navigation
        $isOpenNav={isOpenNav}
        setStateNav={setStateNav}
        logout={logout}
        variant="admin"
        menuItemsTop={menuItemsTop}
        menuItemsBottom={menuItemsBottom}
        userinfo={{name: `${user.firstName} ${user.lastName}`, avatar: user.Avatar?.file?.url}}
        head={headContent}
        checklistButtonName={''}>
        <Switch>
          <Route path={route.dashboard.path}>
            <Dashboard />
          </Route>
          <Route path={route.communities.path}>
            <Communities />
          </Route>
          <Route path={route.community.path}>
            <Community />
          </Route>
          <Route path={route.plan.path}>
            <CustomPlanContainer />
          </Route>
          <Route path={route.members.path}>
            <Members />
          </Route>
          <Route path={route.member.path}>
            <Member />
          </Route>
          <Route path={route.listings.path}>
            <ListingsContainer />
          </Route>
          <Route path={route.configurations.path}>
            <ConfigrationsContainer />
          </Route>
          <Route path={route.messages.path}>
            <MessagesContainer />
          </Route>
          <Redirect from="/" to={isSupport ? route.messages.get() : route.dashboard.get()} />
        </Switch>
      </Navigation>
    </>
  );
};
