import React, {useMemo, useState} from 'react';
import {PrimaryTabMenu} from './TabMenuPrimary';
import {SecondaryTabMenu} from './TabMenuSecondary';
import {TertiaryTabMenu} from './TabMenuTertiary';
import {TabMenuProps, TabsProps, TabType, variant} from './types';

const TabBars: Record<variant, React.FC<TabMenuProps>> = {
  primary: PrimaryTabMenu,
  secondary: SecondaryTabMenu,
  tertiary: TertiaryTabMenu,
};

export const Tabs: React.FC<TabsProps> = ({children, variant, defaultActiveTab, lineLocationPx}) => {
  const tabs = useMemo(
    () => React.Children.map(children, (child) => React.isValidElement<TabType>(child) && child.props) || [],
    [children],
  );
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs?.[0]?.label);

  const TabBar = TabBars[variant];

  return (
    <>
      <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} lineLocationPx={lineLocationPx} />
      {
        React.Children.map(children, (child) => {
          if (child === null) return;
          if (React.isValidElement<TabType>(child) && child.props.label === activeTab) return child;
        })?.[0]
      }
    </>
  );
};
