import React from 'react';
import {LoaderChildrenType} from './types';
import {SpinnerLoader} from './Loaders';

export const LoaderChildren: React.FC<LoaderChildrenType> = ({type}) => {
  if (type === 'spinner') {
    return <SpinnerLoader />;
  }

  return null;
};
