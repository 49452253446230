import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {theme} from '../theme';
import {mixins} from '../theme/mixins';
import {Fade} from '../Reveal/Fade';

type MessageVariant = 'success' | 'warning' | 'error';

const messageColor: Record<MessageVariant, string> = {
  success: theme.palette.common.greenLight,
  warning: theme.palette.common.yellow,
  error: theme.palette.common.red,
};

type MessageProps = {variant?: MessageVariant; centred?: boolean};
type Open = {open?: boolean};
const backgroundColor = (props: MessageProps) => {
  if (!props.variant) return 'transparent';
  return messageColor[props?.variant];
};

const StyledMessage = styled.div<MessageProps>`
  background-color: ${backgroundColor};
  ${mixins.font.secondary.regular};
  color: ${theme.palette.grayscale.white};
  padding: 0 10px;
  font-size: 12px;
  border-radius: 12px;
  min-height: 32px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${({centred}) => centred && 'justify-content: center;'};
`;

export const Message: React.FC<MessageProps & Open> = ({children, open: $open = true, ...props}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen($open);
  }, [$open]);

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <StyledMessage {...props}>{children}</StyledMessage>
    </Fade>
  );
};
