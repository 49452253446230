import React, {PropsWithChildren} from 'react';
import {IconSvg} from '../../Icon/Svg';
import {ImageLoaderInput, RemoveImg} from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type $any = any;

export type ImageLoaderProps<T extends PropsWithChildren<Record<string, $any>> = $any> = {
  handleChange?: (next: any) => void;
  name?: string;
  label: React.FC<T>;
  labelProps?: T | PropsWithChildren<$any>;
  dataId?: string;
  multiple?: boolean;
  $isDisable?: boolean;
  accept?: string;
  handleRemove?: (id: number) => void;
  numberId?: number;
};

export const FileInput = <T extends Record<string, $any> = $any>({
  name,
  handleChange,
  label: Label,
  labelProps,
  dataId,
  multiple,
  $isDisable,
  accept,
  handleRemove,
  numberId,
  ...props
}: ImageLoaderProps<T>) => {
  const onRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemove?.(Number(numberId));
  };
  return (
    <Label {...labelProps} {...props}>
      <ImageLoaderInput
        data-id={dataId}
        accept={accept ?? 'image/*'}
        type={'file'}
        name={name}
        onChange={handleChange}
        multiple={multiple}
        disabled={$isDisable}
      />
      {handleRemove && (
        <RemoveImg onClick={onRemove}>
          <IconSvg type="close" width="16px" height="16px" stroke="white" />
        </RemoveImg>
      )}
    </Label>
  );
};
