import styled, {css} from 'styled-components';
import {Button} from '../../ui-kit/Button/Button';

const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const UploadStepButton = styled(Button).attrs({rounded: false, ghost: false})`
  margin: 26px 0 5px 0;
  justify-content: center;
  width: 240px;
  padding: 0 18px;
  color: ${({theme}) => theme.palette.text.secondary};

  & .button-value {
    ${fontPrimaryReg};
    line-height: 150%;
    font-weight: 500;
    margin: 0;
  }
`;

export const UploadStepButtonAlert = styled(UploadStepButton)`
  float: right;
  background-color: ${({theme}) => theme.palette.button.danger};
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  border-radius: 8px;
  padding: 24px 26px;
  background-color: ${({theme}) => theme.palette.background.primary};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .loading {
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ErrorText = styled.span`
  color: ${({theme}) => theme.palette.common.redDark};
`;

export const CheckListWrapper = styled.ul`
  height: 370px;
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  div {
    margin: 2px 2px;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export const CheckListWrapperSingle = styled(CheckListWrapper)`
  font-size: 14px;
  height: revert;
  display: flex;
  flex-direction: row;
`;

export const UploadHeader = styled.span`
  ${fontPrimaryReg};
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.palette.common.gray};
`;

export const CardMainText = styled.span`
  ${fontPrimaryReg};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const BolderText = styled.span`
  ${fontPrimaryReg};
  font-weight: 700;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 25px;
`;
