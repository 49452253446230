import styled from 'styled-components';
import {Media} from '../../theme';
import {Link} from '../../Typography/Link';
import {TextColor} from '../../Typography/types';
import {viewMode} from '../types';

// eslint-disable-next-line
// @ts-ignore
export const isFromApp = () => typeof window !== 'undefined' && window?.isFromApp;

export const MobileMenuWrapper = styled.div<{offsetBottom?: number; viewMode?: viewMode; $isHidden?: boolean}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 86px;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  z-index: ${({theme}) => theme.zIndex._302};
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
  padding: 18px 35px ${({offsetBottom}) => `calc(21px + ${offsetBottom ?? 0}px)`};
  transition: padding 0.2s ease;
  padding-top: 13px;
  ${({viewMode}) => viewMode === 'hideNavOnMobile' && 'display: none;'}

  ${Media.up.l} {
    visibility: hidden;
  }
  ${Media.down.m} {
    ${({$isHidden}) => $isHidden && ` display: none;`};
    ${isFromApp()
      ? `
      padding-bottom: 0px;
      min-height: 57px;
    `
      : ''}
  }
`;

export const MenuItemWrapper = styled(Link).attrs({size: 10 as number, color: 'octonary' as TextColor})`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    margin-bottom: 4px;
  }
`;

export const MenuItemWrapperText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.octonary};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  & > div {
    margin-bottom: 4px;
  }
`;
