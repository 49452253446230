import React from 'react';
import {CreatePaymentPlanType, ExternalFields, PaymentPlan, UpdatePaymentPlan} from '../../../types/payments';
import {FeesTable} from '../../../components/Configrations/PricingAndFeatures/Fees';
import {SubscriptionTable} from '../../../components/Configrations/PricingAndFeatures/Subscription';
import {Features} from '../../../components/Configrations/PricingAndFeatures/Features';
import {CreatePaymentPlanResponse} from '../../../queries/payments';

interface Props {
  payments: PaymentPlan[];
  updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>;
  createPlan: (fields: Partial<CreatePaymentPlanType>) => Promise<CreatePaymentPlanResponse | null | undefined>;
  isCustomPlan?: boolean;
  externalPayments: {
    rent?: boolean;
    sale?: boolean;
    service?: boolean;
    toggleRent: (r?: boolean) => void;
    toggleSale: (r?: boolean) => void;
    toggleService: (r?: boolean) => void;
  };
  planId?: string;
}

export const CustomPricingAndFeatures: React.FC<Props> = ({
  payments,
  updatePaymentPlan,
  externalPayments,
  isCustomPlan,
  planId,
}) => {
  return (
    <>
      <br />
      <SubscriptionTable payments={payments} updatePaymentPlan={updatePaymentPlan} />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'rentFees'}
        title={'Rent Listings'}
        externalPayment={externalPayments.rent}
        isCustomPlan={isCustomPlan}
        toggle={externalPayments.toggleRent}
        externalField={ExternalFields.rentalExternal}
        planId={planId}
        key={'rentFees' + planId}
      />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'salesFees'}
        title={'Sale Listings'}
        externalPayment={externalPayments.sale}
        isCustomPlan={isCustomPlan}
        toggle={externalPayments.toggleSale}
        externalField={ExternalFields.salesExternal}
        planId={planId}
        key={'salesFees' + planId}
      />
      <FeesTable
        payments={payments}
        updatePaymentPlan={updatePaymentPlan}
        typeFees={'serviceFees'}
        title={'Service Listings'}
        externalPayment={externalPayments.service}
        isCustomPlan={isCustomPlan}
        toggle={externalPayments.toggleService}
        externalField={ExternalFields.serviceExternal}
        planId={planId}
        key={'serviceFees' + planId}
      />
      <Features paymentPlan={payments[0]} updatePaymentPlan={updatePaymentPlan} />
    </>
  );
};
