import React from 'react';
import {LoaderContainer, LoaderOverlay, LoaderItemWrapper} from './styles';
import {PageLoaderProps} from './types';
import {LoaderChildren} from './Children';

export const PageLoader: React.FC<PageLoaderProps> = ({zIndex, loaderType, $isVisible, className}) => {
  return (
    <LoaderContainer zIndex={zIndex} $isVisible={$isVisible} className={className}>
      <LoaderOverlay className={'page-loader__overlay'} />
      <LoaderItemWrapper>
        <LoaderChildren type={loaderType} />
      </LoaderItemWrapper>
    </LoaderContainer>
  );
};
