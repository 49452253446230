import React, {useEffect, useMemo} from 'react';
import {ExternalFields, Fees, PaymentPlan, UpdatePaymentPlan} from '../../../../types/payments';
import {Table} from '../../../../ui-kit/Table';
import {Columns} from '../../../../ui-kit/Table/types';
import {useEditPaymentPlans} from '../hooks';
import {TableWrapper} from '../TableWrapper';
import {getColumns} from './columns';

export type typeFees = 'rentFees' | 'serviceFees' | 'salesFees';
export type breakdown = 'hFee' | 'cFee' | 'Pp';

const acceptedKeys: Array<breakdown> = ['Pp', 'hFee', 'cFee'];

export interface FeeRow {
  objectId: string;
  typeFees: typeFees;
  breakdown: breakdown;
  item: Record<string, number>;
}

interface Props {
  payments: PaymentPlan[];
  updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>;
  typeFees: typeFees;
  title?: string;
  externalPayment?: boolean;
  isCustomPlan?: boolean;
  toggle?: (r?: boolean) => void;
  externalField?: ExternalFields;
  planId?: string;
}

export const FeesTable: React.FC<Props> = ({
  payments,
  updatePaymentPlan,
  typeFees,
  title,
  isCustomPlan,
  externalPayment,
  toggle,
  externalField,
  planId,
}) => {
  const {
    changeValue: ChangeValue,
    isEditMode,
    setIsEdit,
    fields,
    cancel,
    onSubmit,
  } = useEditPaymentPlans(updatePaymentPlan);

  const {items, defaultValues} = useMemo(() => {
    const currentTypeFeesValues = payments.map((el) => {
      return Object.entries(el[typeFees] || {})
        .map((item) => {
          if (!acceptedKeys.includes(item?.[0] as breakdown)) return;

          return {
            objectId: el.objectId,
            breakdown: item[0],
            item: item[1],
            typeFees: typeFees,
          };
        })
        .filter((el) => !!el);
    });

    const defaultValues = payments.map((el) => ({
      [el.objectId]: el[typeFees],
    }));

    return {
      items: Array.prototype.concat(...currentTypeFeesValues),
      defaultValues: Object.assign({}, ...defaultValues),
    };
  }, [payments]);

  const changeValue = (id: string, value: Fees) => {
    ChangeValue<Fees>(id, typeFees, {...defaultValues[id], ...value});
  };

  const currSign = payments?.[0]?.Currency?.sign;

  const {Lister, Requester, Breakdown, Net} = getColumns(
    changeValue,
    fields,
    isEditMode,
    defaultValues,
    currSign || '',
  );

  const columns: Columns<FeeRow> = [
    {
      title: 'Breakdown',
      width: '200px',
      padLeft: '26px',
      render: Breakdown,
    },
    {
      titles: [`Lister ${currSign}`, 'Lister %'],
      width: '250px',
      render: Lister,
    },
    {
      titles: [`Requester ${currSign}`, 'Requester %'],
      width: '250px',
      render: Requester,
    },
    {
      titles: [`Net ${currSign}`, 'Net %'],
      width: '260px',
      marked: true,
      render: Net,
    },
  ];
  const label = externalPayment
    ? {text: 'App Payments Deactive', active: false}
    : {text: 'App Payments Activate', active: true};
  const onCancel = () => {
    cancel();
    toggle?.(true);
  };
  useEffect(() => {
    externalField && planId && ChangeValue(planId, externalField, !!externalPayment);
  }, [externalPayment]);

  return (
    <TableWrapper
      isEdit={isEditMode}
      setIsEdit={setIsEdit}
      title={title}
      cancel={onCancel}
      onSubmit={onSubmit}
      label={label}
      isCustomPlan={isCustomPlan}
      toggle={toggle}>
      <Table<FeeRow> variant={'secondary'} items={items} columns={columns} height={'44px'} />
    </TableWrapper>
  );
};
