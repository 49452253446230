import React from 'react';
import {useParams} from 'react-router-dom';
import {View} from '../../components/Communities/View';
import {useGetCommunity} from '../../hooks/communities';

export const Community = () => {
  const {id} = useParams<{id: string}>();
  const {data} = useGetCommunity(id);
  if (!data) return null;
  return <View data={data} />;
};
