import React from 'react';
import {
  Header,
  HeaderLabelsWrapper,
  Hidden,
  LabelsWrapper,
  ProfileIntroButton,
  TitleText,
  TitleTextWrapper,
  TitleWrapper,
  ButtonsWrapper,
  WrapperButton,
  IconButtonWrapper,
  ButtonText,
} from './styles';
import {IsVerified, Joined, ReviewsProfile, Status} from './labels';
import {Media} from '../theme';
import {ProfileIntroProps} from './types';
import {IconSvg} from '../Icon/Svg';

export const HeaderCard: React.FC<ProfileIntroProps> = ({
  title,
  isVerified,
  countReviews,
  rating,
  buttonOnClick,
  buttonText,
  secondButtonText,
  secondButtonOnClick,
  status,
  joined,
  labels,
  isVerifiedClick,
  isOwner,
  isWaved,
  onScrollToReviews,
}) => {
  return (
    <>
      <Hidden mq={Media.up.xs}>
        <Header>
          <TitleWrapper>
            <TitleTextWrapper>
              <TitleText>{title}</TitleText>
              {isVerified !== undefined && (
                <Hidden mq={Media.up.m}>
                  <IsVerified
                    isVerified={isVerified}
                    isVerifiedClick={isVerifiedClick}
                    labelTruthy={''}
                    labelFalsy={''}
                  />
                </Hidden>
              )}
            </TitleTextWrapper>
            <HeaderLabelsWrapper>
              {/* {joined && <Joined joined={joined} label={labels?.joined} />} */}
              {isVerified !== undefined && (
                <Hidden mq={Media.down.s}>
                  <IsVerified
                    isVerified={isVerified}
                    isVerifiedClick={isVerifiedClick}
                    labelFalsy={labels?.unverified}
                    labelTruthy={labels?.verified}
                  />
                </Hidden>
              )}
              <ReviewsProfile
                onScrollToReviews={onScrollToReviews}
                rating={rating}
                totalReceived={countReviews}
                label={labels?.reviewsProfile}
              />
            </HeaderLabelsWrapper>
          </TitleWrapper>
        </Header>
      </Hidden>
      <Hidden mq={Media.down.xs}>
        <Header>
          <TitleWrapper>
            <LabelsWrapper>
              <TitleText>{title}</TitleText>
              <HeaderLabelsWrapper>
                {isVerified !== undefined && (
                  <IsVerified
                    isVerified={isVerified}
                    isVerifiedClick={isVerifiedClick}
                    labelFalsy={labels?.unverified}
                    labelTruthy={labels?.verified}
                  />
                )}
              </HeaderLabelsWrapper>
            </LabelsWrapper>
            <Hidden mq={Media.down.s}>
              <ButtonsWrapper>
                {secondButtonText && (
                  <WrapperButton>
                    <ProfileIntroButton onClick={secondButtonOnClick} disabled={isWaved}>
                      <IconButtonWrapper>
                        <ButtonText>{secondButtonText}</ButtonText>
                        <IconSvg
                          type={'wave'}
                          fill={isWaved ? 'gray-2' : 'none'}
                          width={'14'}
                          height={'14'}
                          viewBox={'0 0 20 20'}
                        />
                      </IconButtonWrapper>
                    </ProfileIntroButton>
                  </WrapperButton>
                )}
                {buttonText && (
                  <WrapperButton>
                    <ProfileIntroButton onClick={buttonOnClick}>
                      <IconButtonWrapper>
                        <ButtonText>{buttonText}</ButtonText>
                        <IconSvg
                          type={isOwner ? 'edit' : 'combined-shape'}
                          width={'14'}
                          height={'14'}
                          viewBox={'0 0 14 14'}
                        />
                      </IconButtonWrapper>
                    </ProfileIntroButton>
                  </WrapperButton>
                )}
              </ButtonsWrapper>
            </Hidden>
          </TitleWrapper>
          <LabelsWrapper>
            {status && <Status status={status.statusType} name={status.statusName} />}
            {joined && <Joined joined={joined} label={labels?.joined} />}
          </LabelsWrapper>
          <ReviewsProfile
            onScrollToReviews={onScrollToReviews}
            rating={rating}
            totalReceived={countReviews}
            label={labels?.reviewsProfile}
          />
        </Header>
      </Hidden>
    </>
  );
};
