import React, {FC} from 'react';
import {IconSvg} from '../../Icon/Svg';
import {CheckListButtonWrapper, ChecklistStepsCont, DropDownBtnForCheck} from '../styles';
import {Text} from '../../Typography/Text';

type CheckListButtonPropsType = {
  openChecklistMenu?: () => void;
  activeStepsComp?: React.ReactElement;
  checklistMenuOpen?: boolean;
  name?: string;
};

export const CheckListButton: FC<CheckListButtonPropsType> = ({
  openChecklistMenu,
  checklistMenuOpen,
  activeStepsComp,
  name,
}) => {
  return (
    <CheckListButtonWrapper onClick={openChecklistMenu}>
      <ChecklistStepsCont>{activeStepsComp}</ChecklistStepsCont>
      <Text weight={'secondary'} size={14}>
        {name}
      </Text>
      <DropDownBtnForCheck $isOpened={checklistMenuOpen}>
        <IconSvg type={'chevrone-down'} />
      </DropDownBtnForCheck>
    </CheckListButtonWrapper>
  );
};
