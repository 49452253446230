import {useMutation} from '@apollo/react-hooks';
import {getCard as getCardById} from '../queries/cards';

interface CardResponse {
  paymentGetUserCards?: {
    defaultCardId?: string;
    cards?: {
      last4?: string;
      brand?: string;
      id?: string;
    }[];
  };
}

export const useGetCard = () => {
  const [getCard] = useMutation<CardResponse>(getCardById);

  return getCard;
};
