import {QueryResult, useMutation, useQuery} from '@apollo/react-hooks';
import {
  getBorrowers,
  getLenders,
  getMember,
  getMembers,
  getUsersByRole,
  QueryGetUser,
  updateMember as updateMemberQuery,
} from '../queries/members';
import {User} from '../types/user';
import {useEffect} from 'react';
import {Edge} from '../types/parse';
import {usePagination} from './pagination';
import {residentDashboard, ResidentDashboardResponceType} from '../queries/dashboard';
import {useSetRecoilState} from 'recoil';
import {isLoading} from '../states/load';
import {GQLUpdateUserFieldsInput} from '../graphql.schema';
import {MemberFragment} from '../queries/fragments/user';

interface MembersResponce {
  users: {
    count?: number;
    edges?: {
      node: User;
    }[];
  };
}

export const useGetMembers = () => {
  const {page, first_skip} = usePagination();

  const {data, fetchMore, loading}: QueryResult<MembersResponce> = useQuery(getMembers, {
    variables: first_skip,
  });

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const fetchMoreMembers = async () => {
    try {
      await fetchMore({
        variables: first_skip,
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreMembers();
  }, [page, first_skip.skip, first_skip.first]);

  return {data: data?.users.edges?.map((el) => el.node), count: data?.users.count || 0};
};

interface MembersByRoleResponce {
  roles: Edge<{users: Edge<User>}>;
}

type Role = 'admin' | 'user';

export const useGetMembersByRole = (role: Role) => {
  const {page, first_skip} = usePagination();

  const {data, fetchMore, loading}: QueryResult<MembersByRoleResponce> = useQuery(getUsersByRole, {
    variables: {
      role: role,
      ...first_skip,
    },
  });
  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const fetchMoreMembers = async () => {
    try {
      await fetchMore({
        variables: {
          variables: {
            role: role,
            ...first_skip,
          },
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreMembers();
  }, [page]);

  const users = data?.roles.edges[0]?.node.users.edges.map((el) => el.node);

  const count = data?.roles.edges[0]?.node.users.count || 0;

  return {
    data: users,
    count,
  };
};

interface BorrowersResponse {
  orders: {
    count: number;
    edges: {
      Requester: User;
    }[];
  };
}

export const useGetBorrowers = () => {
  const {page, first_skip} = usePagination();

  const {data, fetchMore, loading}: QueryResult<BorrowersResponse> = useQuery(getBorrowers, {
    variables: first_skip,
  });

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const fetchMoreMembers = async () => {
    try {
      await fetchMore({
        variables: {
          variables: first_skip,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreMembers();
  }, [page]);

  return {data: data?.orders.edges.map((el) => el.Requester), count: data?.orders.count || 0};
};

interface LendersResponse {
  orders: {
    count: number;
    edges: {
      Lister: User;
    }[];
  };
}

export const useGetLenders = () => {
  const {page, first_skip} = usePagination();

  const {data, fetchMore, loading}: QueryResult<LendersResponse> = useQuery(getLenders, {
    variables: first_skip,
  });

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const fetchMoreMembers = async () => {
    try {
      await fetchMore({
        variables: {
          variables: first_skip,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreMembers();
  }, [page]);

  return {
    data: data?.orders.edges.map((el) => el.Lister),
    count: data?.orders.count || 0,
  };
};

export const useGetMemberById = (id: string) => {
  const setIsLoading = useSetRecoilState(isLoading);
  const {data, loading: loadUser} = useQuery<{user: User}>(getMember, {variables: {id}});
  const [getDashboard, {data: dashboard, loading: loadDashboard}] =
    useMutation<ResidentDashboardResponceType>(residentDashboard);

  useEffect(() => {
    if (!data?.user?.objectId) return;
    getDashboard({variables: {id: data?.user?.objectId}});
  }, [data?.user?.objectId]);

  setIsLoading(loadUser || loadDashboard || !dashboard);

  return {user: data?.user, dashboard: dashboard?.residentDashboard};
};

export const useUpdateMember = () => {
  const [updateMember, {loading, client}] = useMutation<{user: User}>(updateMemberQuery);

  const setIsLoading = useSetRecoilState(isLoading);

  setIsLoading(loading);

  const UpdateMember = async (user: User, fields?: GQLUpdateUserFieldsInput) => {
    const {data} = await updateMember({variables: {id: user.objectId, fields: fields}});
    data &&
      client.writeFragment({
        id: `User:${user.id}`,
        fragment: MemberFragment,
        data: data.user,
      });
  };

  const blockUser = (user: User) => UpdateMember(user, {status: 'block'});
  const activeUser = (user: User) => UpdateMember(user, {status: 'active'});

  return {updateMember: UpdateMember, blockUser, activeUser};
};

export const useSearchMembers = (constraints?: Record<string, any>) => {
  const {refetch} = useQuery<MembersResponce>(getMembers, {
    skip: true,
  });

  return async (match: string) => {
    const parts = match.split(' ');
    const partOne = parts?.[0] ?? '';
    const partTwo = parts?.[1] ?? '';

    const response = await refetch({
      where: {
        OR: [
          {
            firstName: {
              matchesRegex: partOne,
              options: 'i',
            },
          },
          {
            lastName: {
              matchesRegex: partOne,
              options: 'i',
            },
          },
          ...(partTwo
            ? [
                {
                  firstName: {
                    matchesRegex: partTwo,
                    options: 'i',
                  },
                },
                {
                  lastName: {
                    matchesRegex: partTwo,
                    options: 'i',
                  },
                },
              ]
            : []),
        ],
      },
      ...(constraints ?? {}),
    });

    return response?.data?.users?.edges?.map((u) => u.node);
  };
};

export const useGetUser = (id?: string) => {
  const {data, ...otherData} = useQuery<{user: User}>(QueryGetUser, {variables: {id}, skip: !id});

  return {data: data?.user, ...otherData};
};
