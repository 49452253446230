import styled, {css, StyledProps} from 'styled-components';
import {Media, theme} from '../../theme';

type Rows = {rows: number};
type Error = {$isError?: boolean};

type StyledInputProps = {
  $isError?: boolean;
  resize?: boolean;
  rows: number;
};

const resize = (props: StyledInputProps) => {
  return props.resize ? 'vertical' : 'none';
};

const styles = css<StyledProps<StyledInputProps>>`
  ${theme.font.tertiary.regular};
  background-color: transparent;
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  outline: none;
  padding: 16px 50px 16px 16px;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 16px;
  color: ${theme.palette.text.quinary};
  resize: ${resize};
  ${Media.down.m} {
    padding: 16px 50px 16px 16px;
    font-size: 16px;
  }
  &:disabled {
    color: #848c9d;
  }
`;

export const StyledTextArea = styled.textarea<StyledInputProps>`
  ${styles};
`;

const height = (props: Rows) => {
  const initialHeight = 38;
  const rowHeight = 12;
  return (initialHeight + props.rows * rowHeight).toString() + 'px';
};

export const Field = styled.div<{width?: string} & Rows & Error>`
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  background: ${theme.palette.background.primary};
  border: ${({$isError, theme}) =>
    $isError ? `2px solid ${theme.palette.common.red}` : `1px solid ${theme.palette.common.gray}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: ${({width}) => width || '100%'};
  height: ${height};
`;

export const Label = styled.label<Error>`
  position: absolute;
  background-color: ${({theme}) => theme.palette.background.primary};
  top: -10px;
  left: 7px;
  ${theme.font.tertiary.regular};
  color: ${({$isError, theme}) => ($isError ? theme.palette.common.red : 'rgba(0, 0, 0, 0.5)')};
  font-size: 12px;
  white-space: nowrap;
  padding: 3px 4px;
  box-sizing: border-box;
  ${Media.down.xs} {
    font-size: 14px;
  }
`;
