import React, {useEffect, useState} from 'react';
import {BrowserData} from '../../../components/BrowserData';
import {Table} from '../../../components/Members/table';
import {useGetMembers, useSearchMembers, useUpdateMember} from '../../../hooks/members';
import {User} from '../../../types/user';
import {arraysEqual} from './helpers';

export const TabAll = () => {
  const {data, count} = useGetMembers();
  const {blockUser, activeUser} = useUpdateMember();
  const [items, setItems] = useState<User[]>(data ?? []);
  const search = useSearchMembers();
  const [isSearchData, setIsSearchData] = useState(false);

  useEffect(() => {
    if (data && !arraysEqual(data, items)) {
      if (isSearchData) return;
      setItems(data);
    }
  }, [data]);

  return (
    <BrowserData
      count={count}
      initialItems={data}
      setItems={setItems}
      onSearch={search}
      setIsSearchData={setIsSearchData}>
      <Table items={items} blockUser={blockUser} activeUser={activeUser} />
    </BrowserData>
  );
};
