import React, {useState} from 'react';
import {
  CheckboxArea,
  ConfigContent,
  ConfigWrapper,
  DarkBackground,
  HeaderWrapper,
  PlanOptionWrapper,
  SubmitWrapper,
} from './styles';
import {Text} from '../../../ui-kit/Typography/Text';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Checkbox} from '../../../ui-kit/Checkbox/Checkbox';
import {planOptions, PlanOptionsValues} from '../../../constants/plan';
import {Button} from '../../../ui-kit/Button/Button';
import {toParsePlanOptions} from '../helpers';

type Options = [string, boolean];

interface ConfigurePlanProps {
  title: string;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  planOptions: Options[];
  onSubmit: (options: string[]) => void;
}

interface PlanOptionProps {
  option: Options;
  onCheck: (checked: boolean) => void;
}

export const PlanOption: React.FC<PlanOptionProps> = ({option, onCheck}) => {
  const [name, checked] = option;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onCheck(e.target.checked);

  return (
    <PlanOptionWrapper>
      <Checkbox $isChecked={checked} onChange={handleChange} />
      <Text variant={'quaternary'} color={'octonary'} weight={'500'} size={14}>
        {planOptions[name as PlanOptionsValues]}
      </Text>
      <IconSvg type={'info'} stroke={'gray'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />
    </PlanOptionWrapper>
  );
};

export const ConfigurePlan: React.FC<ConfigurePlanProps> = ({title, active, setActive, planOptions, onSubmit}) => {
  const [options, setOptions] = useState<Options[]>(planOptions);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  if (!active) return null;

  const close = () => setActive(false);
  const stopEvent = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleChecked = (key: string) => (next: boolean) =>
    setOptions(options.map((opt) => (opt[0] === key ? [key, next] : opt)));

  const handleSelectAll = ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setOptions(options.map((opt) => [opt[0], true]));
    }
    setSelectAll(checked);
  };

  const handleSubmit = () => onSubmit(toParsePlanOptions(options));

  return (
    <DarkBackground onClick={close}>
      <ConfigWrapper onClick={stopEvent}>
        <HeaderWrapper>
          <Text variant={'primary'} color={'octonary'} weight={'500'} size={20}>
            {title}
          </Text>
          <div onClick={close}>
            <IconSvg type={'close'} width={'24'} height={'24'} />
          </div>
        </HeaderWrapper>
        <ConfigContent>
          <Text variant={'primary'} color={'octonary'} size={16}>
            Community Account Features
          </Text>
          <CheckboxArea>
            {options.map((opt) => (
              <PlanOption option={opt} key={opt[0]} onCheck={handleChecked(opt[0])} />
            ))}
          </CheckboxArea>
        </ConfigContent>
        <SubmitWrapper>
          <PlanOptionWrapper>
            <Checkbox $isChecked={selectAll} onChange={handleSelectAll} />
            <Text variant={'quaternary'} color={'octonary'} weight={'500'} size={14}>
              Select all
            </Text>
          </PlanOptionWrapper>
          <Button onClick={handleSubmit}>Submit</Button>
        </SubmitWrapper>
      </ConfigWrapper>
    </DarkBackground>
  );
};
