import React from 'react';
import {ButtonProps} from './types';
import {ButtonBase} from './ButtonBase';
import {IconSvg} from '../Icon/Svg';
import {LeftAdornment, PrimaryButton, RoundedButton, SpinnerWrapper, Value} from './styles';

export const Button: React.FC<ButtonProps> = ({children, leftAdornment, rounded, type, loading, ...props}) => {
  const Component = rounded ? RoundedButton : PrimaryButton;

  return (
    <ButtonBase element={Component} loading={loading} leftAdornment={leftAdornment} type={type || 'button'} {...props}>
      <SpinnerWrapper variant={props.variant} ghost={props.ghost} $active={loading}>
        <IconSvg type={'loading'} width={'1.5em'} height={'1.5em'} viewBox={'-3 -3 19 19'} />
      </SpinnerWrapper>
      <Value leftAdornment={leftAdornment} {...props}>
        {children}
        {leftAdornment && <LeftAdornment>{leftAdornment}</LeftAdornment>}
      </Value>
    </ButtonBase>
  );
};
