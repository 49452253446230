import React, {useCallback, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {Media} from '../theme';

export const Popup: React.FC<{isOpen?: boolean; onClose?: () => void; mobileCentered?: boolean; centered?: boolean}> =
  ({children, isOpen = false, onClose, mobileCentered, centered}) => {
    const modal = useRef<HTMLDivElement>(null);
    const onClick = useCallback(
      (e: Event) => {
        if (e.target !== modal.current) return;
        onClose?.();
      },
      [onClose],
    );

    useEffect(() => {
      if (!onClose) return;
      if (isOpen) {
        addEventListener('click', onClick);
        return;
      }
      if (!isOpen) {
        addEventListener('click', onClick);
        return;
      }
    }, [isOpen, onClose]);

    if (!isOpen) return null;
    if (typeof window !== 'undefined')
      return ReactDOM.createPortal(
        <PopupContainer ref={modal} $mobileCentered={mobileCentered} $centered={centered}>
          {children}
        </PopupContainer>,
        document?.body,
      );
    return null;
  };

export type BasePortalProps = {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  width?: string;
  height?: string;
  zIndex?: number;
};

export const BasePortal: React.FC<BasePortalProps> = ({children, ...props}) => {
  if (typeof window !== 'undefined')
    return ReactDOM.createPortal(<BasePortalWrapper {...props}>{children}</BasePortalWrapper>, document.body);
  return null;
};

const BasePortalWrapper = styled.div<BasePortalProps>`
  position: fixed;
  top: ${({top}) => (top ? top : 'unset')};
  right: ${({right}) => (right ? right : 'unset')};
  left: ${({left}) => (left ? left : 'unset')};
  bottom: ${({bottom}) => (bottom ? bottom : 'unset')};
  height: ${({height}) => (height ? height : 'unset')};
  width: ${({width}) => (width ? width : 'unset')};
  z-index: ${({zIndex}) => (zIndex ? zIndex : 1001)};
  cursor: initial;
`;

const PopupContainer = styled.div<{$mobileCentered?: boolean; $centered?: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  z-index: 1001;
  cursor: initial;
  align-items: ${({$centered}) => ($centered ? 'center' : 'flext-start')};
  ${Media.down.s} {
    align-items: flex-end;
  }
`;
