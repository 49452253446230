import React from 'react';
import {
  IconSvg,
  JoinedLabelText,
  LabelText,
  LabelProfileText,
  LabelWrapper,
  LabelProfileWrapper,
  LinkLabelWrapper,
  TransparentButton,
  RatingWrapper,
} from './styles';
import {variant} from '../Labels/types';
import {Label} from '../Labels';
import {status} from './types';

const statuses: Record<status, variant> = {
  primary: 'senary',
  secondary: 'quinary',
  tertiary: 'quaternary',
};

type TLabel = {label?: string};

type LinkLabel = {data?: string | {text?: string; to?: string}};

const LabelContainer: React.FC<{to?: string}> = ({to, children}) => {
  return to ? <LinkLabelWrapper to={to}>{children}</LinkLabelWrapper> : <LabelWrapper>{children}</LabelWrapper>;
};

export const Members: React.FC<{count: number} & LinkLabel> = ({count, data}) => {
  const text = (typeof data === 'string' ? data : data?.text) || 'Members';
  const to = typeof data === 'object' ? data.to : undefined;

  return (
    <LabelContainer to={to}>
      <IconSvg viewBox="0 0 26 26" type="people" strokeWidth={1.5} />
      <LabelText>
        {count} {text}
      </LabelText>
    </LabelContainer>
  );
};

const AddressMemo: React.FC<{address: string}> = ({address}) => {
  return (
    <LabelWrapper>
      <IconSvg type="sun-2" />
      <LabelText>{address}</LabelText>
    </LabelWrapper>
  );
};

const Address = React.memo(AddressMemo);
export {Address};

const VerifiedMemo: React.FC<TLabel> = ({label = 'Verified'}) => {
  return (
    <LabelWrapper>
      <IconSvg type="verified-2" stroke="green" />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
  );
};

const Verified = React.memo(VerifiedMemo);
export {Verified};

const UnverifiedMemo: React.FC<TLabel> = ({label = 'Unverified'}) => {
  return (
    <LabelWrapper>
      <IconSvg type="spam-2" stroke="red" />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
  );
};

const Unverified = React.memo(UnverifiedMemo);
export {Unverified};

export const IsVerified: React.FC<{
  isVerified: boolean;
  labelTruthy?: string;
  labelFalsy?: string;
  isVerifiedClick?: () => void;
}> = ({isVerified, labelTruthy, labelFalsy, isVerifiedClick}) => {
  return isVerifiedClick ? (
    <TransparentButton onClick={isVerifiedClick}>
      {isVerified ? <Verified label={labelTruthy} /> : <Unverified label={labelFalsy} />}
    </TransparentButton>
  ) : isVerified ? (
    <Verified label={labelTruthy} />
  ) : (
    <Unverified label={labelFalsy} />
  );
};

const ReviewsMemo: React.FC<{count: number} & TLabel> = ({count, label}) => {
  return (
    <LabelWrapper>
      <IconSvg type="star" stroke="yellow" />
      <LabelText>
        {count} {label}
      </LabelText>
    </LabelWrapper>
  );
};
const Reviews = React.memo(ReviewsMemo);
export {Reviews};

const ReviewsProfileMemo: React.FC<{
  rating?: number;
  totalReceived?: number;
  label?: (string | undefined)[];
  onScrollToReviews?: () => void;
}> = ({rating, totalReceived, label, onScrollToReviews}) => {
  return (
    <LabelProfileWrapper>
      {rating && totalReceived && (
        <RatingWrapper onClick={onScrollToReviews}>
          <IconSvg type="star-3" stroke="yellow" fill="yellow" viewBox="0 0 18 18" />
          <LabelProfileText>
            {rating}({totalReceived})
          </LabelProfileText>
        </RatingWrapper>
      )}
      <LabelProfileText>{label?.map((el, id) => el && `${id !== 0 ? ', ' : ' '}${el}`)}</LabelProfileText>
    </LabelProfileWrapper>
  );
};
const ReviewsProfile = React.memo(ReviewsProfileMemo);
export {ReviewsProfile};

const ListingsMemo: React.FC<{count: number} & TLabel> = ({count, label}) => {
  return (
    <LabelWrapper>
      {count > 0 && <IconSvg type="Box" stroke="aqua" />}
      <LabelText>
        {count} {label}
      </LabelText>
    </LabelWrapper>
  );
};

const Listings = React.memo(ListingsMemo);
export {Listings};

const EducationMemo: React.FC<{education: string} & TLabel> = ({education, label = 'Studied at'}) => {
  return (
    <LabelWrapper>
      <IconSvg type="news-2" />
      <LabelText>
        {label} {education}
      </LabelText>
    </LabelWrapper>
  );
};

const Education = React.memo(EducationMemo);
export {Education};

const WorkMemo: React.FC<{work: string} & TLabel> = ({work, label = 'Works at'}) => {
  return (
    <LabelWrapper>
      <IconSvg type="walking-2" />
      <LabelText>
        {label} {work}
      </LabelText>
    </LabelWrapper>
  );
};

const Work = React.memo(WorkMemo);
export {Work};

const LanguagesMemo: React.FC<{languages: string} & TLabel> = ({languages, label = 'Speaks'}) => {
  return (
    <LabelWrapper>
      <IconSvg type="speak" />
      <LabelText>
        {label} {languages}
      </LabelText>
    </LabelWrapper>
  );
};

const Languages = React.memo(LanguagesMemo);
export {Languages};

const StatusMemo: React.FC<{status: status; name: string}> = ({status, name}) => {
  return (
    <Label variant={statuses[status]} squared>
      {name}
    </Label>
  );
};

const Status = React.memo(StatusMemo);
export {Status};

const JoinedMemo: React.FC<{joined: string} & TLabel> = ({joined, label = 'Joined in'}) => {
  return (
    <JoinedLabelText>
      {label} {new Date(joined).getFullYear()}
    </JoinedLabelText>
  );
};

const Joined = React.memo(JoinedMemo);
export {Joined};
