import {gql} from '@apollo/client';
import {MessageFragment} from './fragments/message';
import {AppFile, PointerInput, Pointer, Connection} from '../types/parse';
import {TChat, TMessage} from '../types/mesages';
import {InsightsDataFragment} from './fragments/insightsData';
import {TUserInsights} from '../types/user';
import {ChatFragment} from './fragments/chat';
import {GQLChatWhereInput, GQLCreateMessageFieldsInput, GQLUpdateChatFieldsInput} from '../graphql.schema';

export interface CreateMessageResponseType {
  createMessage: {
    message: TMessage;
  };
}

export interface CreateMessageRequestType {
  fields: {
    Order?: Partial<PointerInput>;
    Author: Partial<PointerInput>;
    ShowTo: Pointer[];
    text: string;
    type?: string;
    Attachments?: (Partial<AppFile> | undefined)[];
    Chat?: GQLCreateMessageFieldsInput['Chat'];
  };
}

export const CreateMessageQuery = gql`
  mutation createMessage($fields: CreateMessageFieldsInput) {
    createMessage(input: {fields: $fields}) {
      message {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export interface UpdateMessageResponseType {
  updateMessage: {
    message: TMessage;
  };
}

export interface UpdateMessageRequestType {
  fields: TMessage;
  id: string;
}

export const UpdateMessageQuery = gql`
  mutation updateMessage($id: ID!, $fields: UpdateMessageFieldsInput) {
    updateMessage(input: {id: $id, fields: $fields}) {
      message {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export interface SetSeenMsgResponseType {
  messageSetSeen: boolean;
}

export interface SetSeenMsgRequestType {
  messageIds: string[];
}

export const SetSeenMessageQuery = gql`
  mutation messageSetSeen($messageIds: [String]!) {
    messageSetSeen(input: {messageIds: $messageIds})
  }
`;

export interface GetMessagesWithUserResponse {
  messages: Connection<{
    objectId?: string;
    ShowTo?: {objectId?: string}[];
  }>;
}

export const getMessagesWithUserQuery = gql`
  query getMessages($where: MessageWhereInput) {
    messages(where: $where) {
      edges {
        node {
          objectId
          ShowTo {
            ... on User {
              objectId
            }
          }
        }
      }
    }
  }
`;

export interface TUserInsightsResponse {
  getUserInsightsData: TUserInsights;
}

export interface TUserInsightsRequest {
  id: string;
}

export const userInsightsQuery = gql`
  mutation userInsights($id: String!) {
    getUserInsightsData(input: {residentId: $id}) {
      ...InsightsDataFragment
    }
  }
  ${InsightsDataFragment}
`;

export interface TGetChatResponse {
  chat: TChat;
}

export interface TGetChatRequest {
  id: string;
}

export const getChatQuery = gql`
  query getChat($id: ID!) {
    chat(id: $id) {
      ...ChatFragment
    }
  }
  ${ChatFragment}
`;

export interface TGetChatsResponse {
  chats: Connection<TChat>;
}

export interface TGetChatsRequest {
  where: GQLChatWhereInput;
}

export const getChatsQuery = gql`
  query getChats($where: ChatWhereInput) {
    chats(where: $where, first: 20000) {
      edges {
        node {
          ...ChatFragment
        }
      }
    }
  }
  ${ChatFragment}
`;

export interface TUpdateChatResponse {
  updateChat: {
    chat: TChat;
  };
}

export interface TUpdateChatRequest {
  id: string;
  fields: GQLUpdateChatFieldsInput;
}

export const updateChatQuery = gql`
  mutation updateChat($id: ID!, $fields: UpdateChatFieldsInput) {
    updateChat(input: {id: $id, fields: $fields}) {
      chat {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`;
