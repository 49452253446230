import React, {useEffect, useState} from 'react';
import {StyledInput} from './styles';

interface Props {
  value?: string;
  disabled: boolean;
  isEditMode: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  currency: 'abs' | 'prc' | string;
}

export const Input: React.FC<Props> = ({value, disabled, onChange, isEditMode, currency}) => {
  const [inputValue, setValue] = useState(value);
  const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setValue(value);
    if (onChange && /^[0-9]+(.[0-9]+)?$/.test(value)) onChange(e);
  };
  const getValue = () => {
    if (isEditMode) return inputValue;
    if (currency === 'abs') return `$${inputValue}`;
    if (currency === 'prc') return `${inputValue}%`;
    return `${currency}${inputValue}`;
  };
  useEffect(() => setValue(value), [value]);

  return <StyledInput value={getValue()} disabled={disabled} onChange={handleChangeInput} $underline={isEditMode} />;
};
