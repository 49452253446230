import React from 'react';
import {format} from 'date-fns';

import {
  MessagePreviewCardWrapper,
  MessageUserName,
  PreviewMessageCardBody,
  PreviewMessageDate,
  PreviewMessageStatusLine,
  PreviewMessageText,
  //StatusText,
} from './styles';
import {MessagePreviewProps} from './types';
import {Avatar} from '../../Avatar/Avatar';

import {isValidDate} from '../../utils/Date';

export const MessagePreviewCard: React.FC<MessagePreviewProps> = ({
  onlineStatus,
  userAvatar,
  userName,
  messageText,
  //requestStatus,
  messageDate,
  $isHighlighted,
  $isNewRequest,
  bordered,
  fullWidth,
  $isInternal,
  unSeenedMsg,
  isActive,
  linkifyHtml,
}) => {
  const html = messageText;
  return (
    <MessagePreviewCardWrapper
      $isActive={isActive}
      $unSeenedMsg={unSeenedMsg}
      $isHighlighted={$isHighlighted}
      $isNewRequest={$isNewRequest}
      bordered={bordered}
      fullWidth={fullWidth}
      $isInternal={$isInternal}>
      <Avatar src={userAvatar} size={'md'} $isOnline={onlineStatus} className={'preview-message_avatar'} />
      <PreviewMessageCardBody fullWidth={fullWidth}>
        <MessageUserName>{userName}</MessageUserName>
        <PreviewMessageStatusLine>{/* <StatusText>{requestStatus}</StatusText> */}</PreviewMessageStatusLine>
        <PreviewMessageText
          dangerouslySetInnerHTML={{
            __html: linkifyHtml ? linkifyHtml(html || '', {target: '_blank'}) : '',
          }}></PreviewMessageText>
        {isValidDate(messageDate) && (
          <PreviewMessageDate>{format(new Date(messageDate as Date), 'LLL d, y')}</PreviewMessageDate>
        )}
      </PreviewMessageCardBody>
    </MessagePreviewCardWrapper>
  );
};
