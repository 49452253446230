import {gradient, palette} from './palette';
import {zIndex} from './zIndex';
import * as helpers from './helpers';
import {mixins} from './mixins';
import {KeyMode} from './constans';

const lightTheme = {
  zIndex,
  shadow: {
    popUp: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  gradient,
  helpers,
  font: mixins.font,
  mixins,
  key: KeyMode.light,
  palette: palette[KeyMode.light],
};

const darkTheme = {
  ...lightTheme,
  key: KeyMode.dark,
  palette: palette[KeyMode.dark],
};

export const themeVariants: {[key: string]: any} = {
  default: lightTheme,
  [KeyMode.light]: lightTheme,
  [KeyMode.dark]: darkTheme,
};

export const theme = themeVariants.default;
