import gql from 'graphql-tag';
import {PaymentPlanFragment} from './payments';

export const CommunitiesFragment = gql`
  fragment CommunitiesFragment on Community {
    id
    objectId
    createdAt
    name
    alias
    accessType
    Owner {
      objectId
    }
    Residents {
      count
    }
    isVerified
    status
    countMembers
    countItems
    Subscr {
      netPaid
      paymentPeriod
      status
      PaymentPlan {
        ...PaymentPlanFragment
      }
    }
  }
  ${PaymentPlanFragment}
`;

export const CommunityFragment = gql`
  fragment CommunityFragment on Community {
    id
    createdAt
    name
    objectId
    createdAt
    updatedAt
    name
    descr
    alias
    address
    zip
    accessType
    listingApproval
    invitation
    isVerified
    identityData
    Location {
      City {
        name
      }
      State {
        name
      }
      Country {
        name
      }
      name
      id
    }
    Subscr {
      paymentPeriod
      status
      PaymentPlan {
        ...PaymentPlanFragment
      }
      SubscrPayments {
        edges {
          node {
            amount
          }
        }
      }
    }
    Avatar {
      file {
        url
      }
    }
    countItems
    countMembers
  }

  ${PaymentPlanFragment}
`;
