import React from 'react';
import {Tab} from '../../../ui-kit/Tabs/Tab';
import {Tabs} from '../../../ui-kit/Tabs/Tabs';
import {CustomPlan} from './CustomPlan';
import {CustomPlanHeader} from '../../../components/Communities/CustomPlan/CustomPlanHeader';
import {useGetCommunity} from '../../../hooks/communities';
import {useParams} from 'react-router-dom';

export const CustomPlanContainer = () => {
  const {id} = useParams<{id: string}>();
  const community = useGetCommunity(id);

  return (
    <CustomPlanHeader community={community.data}>
      <Tabs variant={'primary'}>
        <Tab label={'Pricing & Features'}>
          <CustomPlan community={community} />
        </Tab>
      </Tabs>
    </CustomPlanHeader>
  );
};
