import {TNavMenuItems} from '../../hooks/navigation';
import {USER_TYPE} from '../../types/user';

const menuItemsBottom: TNavMenuItems[] = [
  // {
  //   content: 'Settings',
  //   type: 'settings',
  //   to: '/',
  //   render: renderDisabledItem,
  // },
  // {
  //   content: 'TBD',
  //   type: 'settings',
  //   to: '/',
  //   render: renderDisabledItem,
  // },
];

export const getMenuItems = (params?: {countMessages?: number}) => {
  const {countMessages = 0} = params || {};

  const menuItemsTop: TNavMenuItems[] = [
    {
      content: 'Messages',
      type: 'chat',
      to: '/messages',
      path: 'messages',
      roles: [USER_TYPE.support],
      notices: countMessages,
    },
    {
      content: 'Communities',
      type: 'Box',
      to: '/communities',
      path: 'communities',
    },
    {
      content: 'Members',
      type: 'person',
      to: '/members',
      path: 'members',
      roles: [USER_TYPE.support, USER_TYPE.admin],
    },
    {
      content: 'Listings',
      type: 'feed',
      to: '/listings',
      path: 'listings',
    },
    // {
    //   content: 'Requests',
    //   type: 'circle-arrow-down',
    //   to: '/',
    //   render: renderDisabledItem,
    // },
    // {
    //   content: 'Transactions',
    //   type: 'credit-card-2',
    //   to: '/',
    //   render: renderDisabledItem,
    // },
    // {
    //   content: 'Promotions',
    //   type: 'tag-alt',
    //   to: '/',
    //   render: renderDisabledItem,
    // },
    {
      content: 'Configurations',
      type: 'settings',
      to: '/configurations',
      path: 'configurations',
    },
    // {
    //   content: 'Help Centre',
    //   to: '/',
    //   type: 'message-warning',
    //   render: renderDisabledItem,
    // },
  ];

  return {
    menuItemsTop,
    menuItemsBottom,
  };
};
