import React, {useMemo, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {
  useActionsOnChat,
  useActiveContact,
  useContacts,
  useCreateMessage,
  useFilterContacts,
  useGetChats,
  useGetContactsForChat,
  useGetMessages,
  useGetPersonInfo,
  useSetSeenMessage,
} from '../../hooks/messages';
import {Messages} from '../../components/Messages';
import {getCountUnreadMsgs, getMesssages} from '../../helpers/messages';
import {getUserName} from '../../helpers/user';
import {userState} from '../../states';
import {EChatStatus, TMessage} from '../../types/mesages';
import {fromConnection, toMap} from '../../helpers/parse';
import {IMessages} from '../../components/Messages/Messages';
import {LIMIT_CONTACTS_PAGINATION} from '../../constants/common';

export const MessagesContainer: React.FC = () => {
  const viewer = useRecoilValue(userState);
  const {contactId} = useParams<{contactId?: string}>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pagination, setPagination] = useState<number>(LIMIT_CONTACTS_PAGINATION);
  const {data: msgs, loading: LoadingMsg, refetch} = useGetMessages();
  const {data: viewerChats, refetch: refetchChats} = useGetChats();
  const mapChats = toMap(fromConnection(viewerChats));
  const {contacts: users, contactsIds} = useContacts(msgs, viewer?.objectId, contactId);
  const {contacts: unfiltredContacts} = useGetContactsForChat({
    initUsers: users,
    msgs,
    loading: LoadingMsg,
    contactsIds,
    chats: viewerChats,
    mapChats,
  });
  const [activeContact, setActiveContact] = useActiveContact({contactId, contacts: unfiltredContacts});
  const personInfoData = useGetPersonInfo(contactId);

  const {setSeenMessage: updateSeenMsg, loading: LoadingUpdMsg} = useSetSeenMessage({onSuccess: refetch});
  const {contacts, handleSetFilter, currentFilter} = useFilterContacts(unfiltredContacts || [], {
    activeContact,
    setActiveContact,
  });

  const chatActions = useActionsOnChat(activeContact?.chatId, refetchChats);

  const {
    values,
    onChange,
    deleteImage,
    onSubmit,
    loading: LoadingCreateMsg,
  } = useCreateMessage({
    idActiveContact: contactId,
    idActiveChat: activeContact?.chatId,
    initialState: {
      Author: viewer?.objectId,
      text: '',
    },
    onSuccess: refetch,
  });

  const loadingMsg = useMemo(
    () => LoadingMsg || LoadingCreateMsg || LoadingUpdMsg,
    [LoadingMsg, LoadingCreateMsg, LoadingUpdMsg],
  );
  const messages = getMesssages(msgs, activeContact?.objectId);
  const countUnReadMsg = getCountUnreadMsgs(msgs, viewer?.objectId);

  const dialogData = activeContact?.objectId && {
    isOpenDialog: (mapChats?.[activeContact?.chatId as string]?.status !== EChatStatus.close) as boolean,
    isArchivedDialog: mapChats?.[activeContact?.chatId as string]?.isArchived as boolean,
    openDialog: chatActions.open,
    archiveDialog: chatActions.archive,
    closeDialog: chatActions.close,
    unarchiveDialog: chatActions.unarchive,
  };

  const fetchMore = () => {
    setPagination((prev) => prev + LIMIT_CONTACTS_PAGINATION);
  };

  useEffect(() => {
    if (contacts?.length && contacts.length < LIMIT_CONTACTS_PAGINATION && hasMore) {
      setHasMore(false);
    }

    if (contacts?.length && contacts.length > LIMIT_CONTACTS_PAGINATION && !hasMore) {
      setHasMore(true);
    }
  }, [pagination]);

  return (
    <>
      <Messages
        dialogData={dialogData as IMessages['dialogData']}
        filterContacts={handleSetFilter}
        currentFilter={currentFilter}
        personInfoData={personInfoData}
        msgs={messages}
        countUnReadMsg={countUnReadMsg}
        contactsLoading={LoadingMsg}
        contactsList={contacts.slice(0, pagination)}
        activeContact={activeContact}
        setActiveContact={setActiveContact}
        viewerId={viewer?.objectId}
        hasMore={hasMore}
        fetchMore={fetchMore}
        viewerData={{
          name: getUserName({
            first: viewer?.firstName,
            last: viewer?.lastName,
          }),
          avatar: viewer?.Avatar?.file?.url,
        }}
        dataMessage={{
          values: values as TMessage,
          onChange,
          onSubmit,
          deleteImage,
          loading: loadingMsg,
          LoadingCreateMsg,
          updateSeenMsg,
        }}
      />
    </>
  );
};
