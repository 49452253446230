export enum PlanOptionsValues {
  privateCommunityAccount = 'privateCommunityAccount',
  publicCommunityAccount = 'publicCommunityAccount',
  listingsFeed = 'listingsFeed',
  peoplesFeed = 'peoplesFeed',
  communityFeed = 'communityFeed',
  reportingDashboard = 'reportingDashboard',
  membersApproval = 'membersApproval',
  // inAppMessaging = 'inAppMessaging',
  listingsApproval = 'listingsApproval',
  // brandedEmails = 'brandedEmails',
  // analyticsDashboard = 'analyticsDashboard',
  multipleAdmins = 'multipleAdmins',
  featureListings = 'featureListings',
  pinCommunityPosts = 'pinCommunityPosts',
  priorityCustomerSupport = 'priorityCustomerSupport',
  communityDocuments = 'communityDocuments',
  amenities = 'amenities',
  events = 'events',
  helpDesk = 'helpDesk',
  groups = 'groups',
  company = 'company',
}

export const planOptions = {
  [PlanOptionsValues.privateCommunityAccount]: 'Private community account',
  [PlanOptionsValues.publicCommunityAccount]: 'Public community account',
  [PlanOptionsValues.listingsFeed]: 'Listings feed',
  [PlanOptionsValues.peoplesFeed]: 'Peoples feed',
  [PlanOptionsValues.communityFeed]: 'Community feed',
  [PlanOptionsValues.reportingDashboard]: 'Reporting dashboard',
  [PlanOptionsValues.membersApproval]: 'Members approval',
  // [PlanOptionsValues.inAppMessaging]: 'In app messaging',
  [PlanOptionsValues.listingsApproval]: 'Listings approval',
  // [PlanOptionsValues.brandedEmails]: 'Branded emails',
  // [PlanOptionsValues.analyticsDashboard]: 'Analytics dashboard',
  [PlanOptionsValues.multipleAdmins]: 'Multiple admins',
  [PlanOptionsValues.featureListings]: 'Feature listings',
  [PlanOptionsValues.pinCommunityPosts]: 'Pin community posts',
  [PlanOptionsValues.priorityCustomerSupport]: 'Priority customer support',
  [PlanOptionsValues.communityDocuments]: 'Documents',
  [PlanOptionsValues.amenities]: 'Amenities',
  [PlanOptionsValues.events]: 'Events',
  [PlanOptionsValues.helpDesk]: 'Help Desk',
  [PlanOptionsValues.groups]: 'Groups',
  [PlanOptionsValues.company]: 'Local Biz',
};
