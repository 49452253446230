import {useApolloClient, useMutation} from '@apollo/react-hooks';
import {useBoolean} from '@umijs/hooks';
import {ExecutionResult, GraphQLError} from 'graphql';
import {useCallback, useEffect, useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {Current, CurrentDataType, Logout, QueryLogin, TLoginRequest, TLoginResponse} from '../queries/auth';
import {GetRolesByIdUser} from '../queries/roles';
import {roleState} from '../states/role';
import {session as sessionState, SessionStateType} from '../states/session';
import {ItemRole, LoginType} from '../types/auth';
import {USER_TYPE} from '../types/user';
import {useHistory} from 'react-router-dom';
import {route} from '../constants/routes';
import {userState} from '../states';
import Parse from 'parse';

interface Error {
  [username: string]: Array<string>;
}

interface FetchSessionType {
  appLoad: boolean;
  session: SessionStateType;
}

interface AuthType {
  login: () => Promise<void>;
  loading: boolean;
  errors: Error;
  clearErrors: (name: string) => void;
  values: Partial<LoginType>;
  onChange: (field: {key: keyof LoginType; value: string}) => void;
}

export const useAuth = (): AuthType => {
  const [sendLogin, {loading}] = useMutation<TLoginResponse, TLoginRequest>(QueryLogin, {fetchPolicy: 'no-cache'});
  const [doLogout] = useMutation(Logout);
  const setSession = useSetRecoilState(sessionState);
  const setRole = useSetRecoilState(roleState);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState<Partial<LoginType>>({});
  const {push} = useHistory();

  const onChange = (field: {key: keyof LoginType; value: string}) => {
    setValues((prev) => ({...prev, [field.key]: field.value}));
  };

  const client = useApolloClient();

  const login = async () => {
    try {
      const {data}: ExecutionResult<TLoginResponse> = await sendLogin({variables: values as LoginType});
      if (!data) throw new Error("Login Data hasn't been received!");

      localStorage.setItem('token', data?.logIn?.viewer.sessionToken);
      const {id} = data?.logIn.viewer.user;

      const result = await client.query({query: GetRolesByIdUser, variables: {id}});
      const someRole = result?.data;

      if (!someRole) throw new Error("During Login The Role hasn't been received!");

      const isAdmin = someRole.roles.edges.find((item: ItemRole) => {
        return item.node.name === USER_TYPE.admin;
      });
      const isSupport = someRole.roles.edges.find((item: ItemRole) => {
        return item.node.name === USER_TYPE.support;
      });

      if (!(isAdmin || isSupport)) {
        await doLogout();
        localStorage.removeItem('token');
        return alert('Only admin can login!');
      }
      setSession(data?.logIn?.viewer);
      await Parse.User.become(data?.logIn?.viewer?.sessionToken);
      setRole(isSupport ? USER_TYPE.support : USER_TYPE.admin);
      push(route.main.get());
    } catch (error) {
      localStorage.removeItem('token');
      setErrors({username: [error.message]});
    }
  };

  function clearErrors(name: string) {
    setErrors({...errors, [name]: []});
  }

  return {login, loading, errors, clearErrors, values, onChange};
};

const isSessionError = (errors: ReadonlyArray<GraphQLError>) =>
  errors.find((error) => error && error.extensions && error.extensions.code === 209);

export function useFetchSession(): FetchSessionType {
  const [session, setSession] = useRecoilState(sessionState);
  const setRole = useSetRecoilState(roleState);
  const {state: loading, setTrue: setTrueLoading, setFalse: setFalseLoading} = useBoolean(false);

  const client = useApolloClient();

  async function _fetchData() {
    setTrueLoading();
    try {
      const sessionData = await client.query<CurrentDataType>({query: Current, fetchPolicy: 'no-cache'});
      const {id} = sessionData.data?.viewer.user;

      const {data: rolesData} = await client.query({query: GetRolesByIdUser, variables: {id}});

      const isSupport = rolesData.roles.edges.find((item: ItemRole) => {
        return item.node.name === USER_TYPE.support;
      });
      if (sessionData?.data && !session) {
        setSession(sessionData.data.viewer);
        setRole(isSupport ? USER_TYPE.support : USER_TYPE.admin);
      }
    } catch (error) {
      localStorage.removeItem('token');
      if (
        error &&
        !isSessionError(error.graphQLErrors) &&
        error.networkError?.message !== 'Response not successful: Received status code 403'
      )
        console.log(error.message);
    }
    setFalseLoading();
  }

  const fetchData = useCallback(_fetchData, [setFalseLoading, client, setTrueLoading, session, setSession]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {session, appLoad: !loading};
}

export const useViewer = () => {
  const user = useRecoilState(userState);

  return user[0];
};

export const useViewerId = (field?: 'id' | 'objectId'): string | undefined => {
  return useViewer()?.[field || 'id'];
};

export const useLogout = (): {logout: () => Promise<void>} => {
  const setSession = useSetRecoilState(sessionState);
  const setRole = useSetRecoilState(roleState);
  const [doLogout] = useMutation(Logout);

  const clearSession = () => setSession(null);
  const clearRole = () => setRole(null);

  async function logout() {
    try {
      await doLogout();
      localStorage.removeItem('token');
      clearSession();
      clearRole();
    } catch (e) {
      console.log(e.message);
    }
  }

  return {logout};
};
