import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const Icon = styled.svg`
  stroke-width: 1px;
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div<{$isChecked: boolean; size?: number}>`
  width: ${({size}) => (size ? size + 'px' : '20px')};
  height: ${({size}) => (size ? size + 'px' : '20px')};
  border: 1px solid ${({theme}) => theme.palette.grayscale._12};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    visibility: ${(props) => (props.$isChecked ? `visible` : `hidden`)};
  }
`;
