import React from 'react';
import {useRecoilValue} from 'recoil';
import {User, USER_TYPE} from '../../types/user';
import {getMenuItems} from './navigationItems';
import {useLogout} from '../../hooks/auth';
import {useGetNavItems} from '../../hooks/navigation';
import {ILayout, Layout} from '../../components/Layout';
import {roleState} from '../../states';
import {useUnreadMessages} from '../../hooks/messages';

const LayoutBasic: React.FC<Omit<ILayout, 'menuItemsTop' | 'menuItemsBottom'>> = (props) => {
  const {menuItemsBottom, menuItemsTop} = getMenuItems();
  const {bottom, top} = useGetNavItems(menuItemsTop, menuItemsBottom);

  return <Layout {...props} menuItemsBottom={bottom} menuItemsTop={top} />;
};

const LayoutWithMessages: React.FC<Omit<ILayout, 'menuItemsTop' | 'menuItemsBottom'>> = (props) => {
  const {count: countMessages} = useUnreadMessages();
  const {menuItemsBottom, menuItemsTop} = getMenuItems({countMessages});
  const {bottom, top} = useGetNavItems(menuItemsTop, menuItemsBottom);

  return <Layout {...props} menuItemsBottom={bottom} menuItemsTop={top} />;
};

const LayoutWrap: React.FC<{user: User}> = ({user}) => {
  const {logout} = useLogout();
  const typeUser = useRecoilValue(roleState);

  const isSupport = typeUser === USER_TYPE.support;
  const LayoutComponent = isSupport ? LayoutWithMessages : LayoutBasic;

  return <LayoutComponent user={user} logout={logout} isSupport={typeUser === USER_TYPE.support} />;
};

export default LayoutWrap;
