import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  padding-bottom: 90px;
`;

export const AlignContentRight = styled.div`
  text-align: right;
`;
