import styled, {StyledProps} from 'styled-components';
import {Media} from '../theme';
import {variant} from './types';

interface LabelProps {
  width?: string;
  variant?: variant;
  squared?: boolean;
}

const colors = ({variant, theme}: StyledProps<LabelProps>) => {
  switch (variant) {
    case 'primary':
      return theme.palette.common.redDark;
    case 'secondary':
      return theme.palette.common.greenDark;
    case 'tertiary':
      return theme.palette.common.blueDark;
    case 'septenary':
      return theme.palette.text.tertiary;
    default:
      return theme.palette.grayscale._6;
  }
};

const backgroundsColors = ({variant, theme}: StyledProps<LabelProps>) => {
  switch (variant) {
    case 'primary':
      return theme.palette.common.redLight;
    case 'secondary':
      return theme.palette.common.greenLight2;
    case 'quaternary':
      return theme.palette.common.yellowLight;
    case 'quinary':
      return theme.palette.grayscale._3;
    case 'senary':
      return theme.palette.common.blueLight;
    case 'septenary':
      return theme.palette.grayscale._6;
    default:
      return theme.palette.common.blueGray;
  }
};

export const BaseLabel = styled.span<LabelProps>`
  ${({theme, squared}) => theme.font.quaternary[squared ? 'regular' : 'medium']}
  font-size: 12px;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 3px 8px 2px 8px;
  height: 19px;
  ${({width}) => width && `width: ${width};`}
  border-radius: ${({squared}) => (squared ? '6px' : '10px')};
  color: ${colors};
  background-color: ${backgroundsColors};
  line-height: 150%;
  font-size: 12px;
  text-transform: capitalize;
  ${Media.down.xs} {
    font-size: 12px;
  }
`;

export const LabelWithPoint = styled(BaseLabel)`
  padding: 3px 9px 2px 14px;
  &::before {
    content: '';
    position: absolute;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: ${colors};
  }
`;

export const LabelRow = styled.div`
  padding: 12px 10px;
  background: ${({theme}) => theme.palette.common.yellowLight};
  border-radius: 10px;
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    width: 60px;
  }
  & > span {
    width: auto;
  }
  & > button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
`;
