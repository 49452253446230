import React from 'react';
import {Table as BaseTable} from '../../ui-kit/Table';
import {Columns} from '../../ui-kit/Table/types';
import {Item} from '../../types/item';
import {Earned, Listing, Member, Price, Shared, Type, getMenu} from './columns';
import {actionsWithItems} from '../../containers/Listings/types';

export interface ListingTableProps {
  items: Item[];
  actions?: actionsWithItems;
}

export const Table: React.FC<ListingTableProps> = ({items, actions}) => {
  const columns: Columns<Item> = [
    {
      title: 'posted',
      width: '110px',
      padLeft: '17px',
      render: 'date',
      dataIndex: 'createdAt',
    },
    {
      title: 'listing',
      width: '200px',
      render: Listing,
    },
    {
      title: 'type',
      width: '80px',
      render: Type,
    },
    {
      title: 'price',
      width: '80px',
      padRight: '40px',
      render: Price,
    },
    {
      title: 'shared',
      width: '110px',
      padRight: '50px',
      render: Shared,
    },
    {
      title: 'member',
      width: '110px',
      render: Member,
    },
    {
      title: 'earned',
      width: '80px',
      align: 'right',
      render: Earned,
    },
    {
      width: '65px',
      render: getMenu(actions),
    },
  ];

  return <BaseTable<Item> variant="primary" items={items} columns={columns} height={'45px'} />;
};
