import React from 'react';
import {AddButtonWrapper} from '../styles';
import {Button} from '../../Button/Button';

type AddButtonPropsType = {
  openAddMenu?: () => void;
  plusIcon?: React.ReactElement;
};

export const AddButton: React.FC<AddButtonPropsType> = ({openAddMenu, plusIcon}) => {
  return (
    <AddButtonWrapper>
      <Button width={'72px'} height={'29px'} onClick={openAddMenu}>
        {plusIcon}
        <span>Add</span>
      </Button>
    </AddButtonWrapper>
  );
};
