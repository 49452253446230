import {AppFile, Connection, ParseObject} from './parse';
import {TOrder} from './order';
import {TUserInsights, User} from './user';
import {NOTIFICATIONS_TEXTS} from '../constants/messages';

export interface TMessage extends ParseObject {
  Author?: Partial<TUserChat>;
  Order?: TOrder;
  ShowTo?: Array<TUserChat | undefined>;
  text?: string;
  type?: TypeMsg;
  Attachments?: Array<AppFile | undefined>;
  Seen?: Array<TUserChat | undefined>;
  Chat?: TChat;
}

export interface TMessageData {
  Order?: string;
  Author?: string;
  ShowTo?: string;
  text: string;
  type?: string;
  Attachments?: (File | undefined)[] | Partial<AppFile>[];
  Seen?: Array<User | undefined>;
  Community?: string;
}

export enum MsgStatus {
  seen = 'seen',
  delivered = 'delivered',
}

export type TContact = {
  objectId: string;
  avatar: string;
  onlineStatus?: boolean;
  name: string;
  dataMsg: {text: string; orderStatus: string; date?: Date | null; haveUnseenMsg?: boolean};
  isClosed?: boolean;
  isArchived?: boolean;
  chatId?: string;
  isUnread?: boolean;
};

export interface TUserChat {
  objectId: string;
  Avatar?: AppFile;
  firstName: string;
  lastName: string;
  onlineDate: Date;
}

export enum TypeMsg {
  hangeh = 'hangeh',
  personal = 'personal',
  notification = 'notification',
  internalNote = 'internalNote',
}

export enum CreateMsgFields {
  text = 'text',
  Attachments = 'Attachments',
  type = 'type',
}

export interface IDataHookMessage {
  values: TMessage;
  onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
  deleteImage: (index: number) => void;
  onSubmit: () => void;
  loading: boolean;
  LoadingCreateMsg: boolean;
  updateSeenMsg: (params: {seen: string[]}) => void;
}

export enum MessageFields {
  objectId = 'objectId',
  Order = 'Order',
  Author = 'Author',
  ShowTo = 'ShowTo',
  text = 'text',
  type = 'type',
  Attachments = 'Attachments',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  Seen = 'Seen',
  Chat = 'Chat',
}

export enum ItemFields {
  name = 'name',
  objectId = 'objectId',
}

export enum ChatFields {
  objectId = 'objectId',
  status = 'status',
  isArchived = 'isArchived',
}

export enum UserFields {
  objectId = 'objectId',
  Avatar = 'Avatar',
  firstName = 'firstName',
  lastName = 'lastName',
  onlineDate = 'onlineDate',
}

export enum OrderFields {
  Lister = 'Lister',
  Requester = 'Requester',
  Item = 'Item',
  Amenity = 'Amenity',
  Event = 'Event',
  objectId = 'objectId',
  status = 'status',
  orderType = 'orderType',
  startTime = 'startTime',
}

export enum AppFileFields {
  objectId = 'objectId',
  file = 'file',
}

export type TUserInsightsFields = keyof Omit<TUserInsights, 'communities'>;

export interface TChat extends ParseObject {
  status: EChatStatus;
  isArchived: boolean;
  Messages: Connection<TMessage>;
  Members: Connection<User>;
}

export enum EChatStatus {
  open = 'open',
  close = 'closed',
}

export type MESSAGES_NOTIFICATIONS = typeof NOTIFICATIONS_TEXTS[number];
