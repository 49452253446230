import styled from 'styled-components';
import {colors} from '../styles';
import {color} from '../types';

export const ColumnTitles = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 38px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
  }
`;

export const ClmnTitle = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tr = styled.tr`
  td:not(:last-child) {
    border-right: solid 2px ${({theme}) => theme.palette.background.tertiary};
  }
  &::before {
    content: '';
    position: absolute;
    background-color: ${({theme}) => theme.palette.background.secondary};
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 1;
  }
`;

export const TableHeaderRow = styled(Tr)<{color?: color}>`
  ${({color, theme}) => color && `background-color: ${theme.palette.grayscale[colors[color]]};`}
  position: relative;
  height: 38px;
  ${({theme}) => theme.font.quaternary.bold}
  background-color: ${({theme}) => theme.palette.background.secondary};
  color: ${({theme}) => theme.palette.text.octonary};

  font-size: 14px;
  &::after {
    content: '';
    position: absolute;
    background-color: ${({theme}) => theme.palette.common.gray};
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
  }
`;

export const TitleWrapper = styled.td<{width?: string; align?: string; padLeft?: string; padRight?: string}>`
  width: ${({width}) => width && width};
  text-align: ${({align}) => align || 'left'};
  padding-left: ${({padLeft}) => padLeft && padLeft};
  padding-right: ${({padRight}) => padRight && padRight};
  ${({theme}) => theme.font.quaternary.bold}
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 14px;
`;

export const TableItem = styled(Tr)<{height?: string}>`
  position: relative;
  height: ${({height}) => height && height};
  color: ${({theme}) => theme.palette.common.blueGrayDark};
  font-size: 14px;
  line-height: 17px;
  &:hover {
    background-color: ${({theme}) => theme.palette.common.blueLight};
  }
  td {
    position: relative;
  }
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.background.tertiary};
  border-bottom-color: ${({theme}) => theme.palette.background.secondary};
`;

export const Td = styled.td<{marked?: boolean}>`
  ${({marked, theme}) => marked && `background-color: ${theme.palette.common.blueLight};`}
`;
