import styled from 'styled-components';
import {Input} from '../../ui-kit/Form/Input/Input';
import {Message} from '../../ui-kit/Form/Message';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {theme} from '../../ui-kit/theme';

export const InputSignIn = styled(Input)`
  margin-bottom: 23px;
  ${theme.font.tertiary.regular}
`;
export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.palette.common.blueDark2};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const SignInBox = styled.div`
  position: relative;
  background-color: white;
  padding: 30px;
  width: 620px;
  height: 360;
  border-radius: 4px;
`;

export const Title = styled.div`
  ${theme.font.secondary.regular};
  font-size: 50px;
  line-height: 50px;
  color: ${({theme}) => theme.palette.grayscale._6};
  margin-bottom: 53px;
`;

export const LogoSvgSignIn = styled(IconSvg)`
  position: absolute;
  left: -35px;
  top: -144px;
`;

export const ErrorMessage = styled(Message).attrs({variant: 'error'})`
  margin-top: 15px;
`;
