import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 54px 0 22px;
  height: 39px;
`;

export const TitlePage = styled.span`
  ${({theme}) => theme.font.primary.medium}
  font-size: 26px;
  line-height: 39px;
`;

export const ButtonGroupe = styled.div`
  width: 318px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  ${({theme}) => theme.font.primary.semiBold}
  font-size: 14px;
`;
