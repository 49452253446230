import React from 'react';
import {ThemeProvider} from 'styled-components';
import {Normalize} from 'styled-normalize';
import Layout from './containers/Layout';
import Auth from './containers/Auth';
import {useFetchSession} from './hooks/auth';
import {theme} from './ui-kit/theme';

const LayoutApp: React.FC = () => {
  const {session, appLoad} = useFetchSession();

  if (!appLoad) return null;

  if (session?.user && localStorage.token) return <Layout user={session.user} />;

  return <Auth />;
};

function App(): React.ReactElement {
  return (
    <>
      <ThemeProvider theme={theme}>
        <LayoutApp />
      </ThemeProvider>
      <Normalize />
    </>
  );
}

export default App;
