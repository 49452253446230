import React from 'react';
import {LoginType} from '../../types/auth';
import {Button} from '../../ui-kit/Button/Button';
import {InputSignIn} from './styles';

interface Props {
  onSubmit: () => void;
  onChange: (field: {key: keyof LoginType; value: string}) => void;
  values: Partial<LoginType>;
}

type SignInField = keyof LoginType;

export const Form: React.FC<Props> = ({onSubmit, onChange, values}) => {
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({key: e.target.name as SignInField, value: e.target.value});
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputSignIn
        value={values?.username}
        onChange={onChangeHandler}
        name="username"
        type="email"
        label="Email address"
        required
      />
      <InputSignIn
        value={values?.password}
        onChange={onChangeHandler}
        name="password"
        type="password"
        label="Password"
        required
      />
      <Button type="submit">Login</Button>
    </form>
  );
};
