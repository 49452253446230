import {gql} from '@apollo/client';

export const TaskFragment = gql`
  fragment TaskFragment on Task {
    id
    objectId
    createdAt
    updatedAt
    processedRow
    result
    error
  }
`;
