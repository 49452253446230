import {useState} from 'react';
import {UpdatePaymentPlan} from '../../../types/payments';

export type UpdatedFields = Record<string, UpdatePaymentPlan>;

export type changeValueFn = <T = string | number>(id: string, key: keyof UpdatePaymentPlan, value: T) => void;

export const useEditPaymentPlans = (updatePaymentPlan: (paymentPlan: UpdatePaymentPlan) => Promise<void>) => {
  const [isEditMode, setIsEdit] = useState(false);
  const [fields, setFields] = useState<UpdatedFields>({});
  const changeValue: changeValueFn = <T>(id: string, key: keyof UpdatePaymentPlan, value: T) => {
    setFields((prev) => ({...prev, [id]: {...prev[id], [key]: value} as UpdatePaymentPlan}));
  };
  const cancel = () => {
    setIsEdit(false);
    setFields({});
  };
  const onSubmit = () => {
    Promise.all(Object.entries(fields).map((el) => updatePaymentPlan({...el[1], objectId: el[0]}))).then(cancel);
  };
  return {isEditMode, setIsEdit, fields, setFields, cancel, onSubmit, changeValue};
};
